import { Box } from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import Stat from 'view/components/stat';
import React from 'react';

export default function SummaryTab({
  summary,
  accounts,
  groupUsers,
  groupUsersUltimate
}) {
  return (
    <>
      <Box mt={PADDINGS.M} flexDirection={'row'} width={'100%'}>
        <Stat label={'Active accounts'} value={accounts.length} />
        <Stat
          label={'Total user slots'}
          value={summary.billable_seats?.total}
        />
        <Stat
          label={'Used user slots'}
          value={summary.billable_seats?.allocated}
        />
        <Stat
          label={'Available user slots'}
          value={summary.billable_seats?.unallocated}
        />
        <Stat
          label={'Non-billable slots'}
          helpText={
            "Integration users, head office users, etc. who are whitelisted and don't take up user slots in an account"
          }
          value={summary.non_billable_seats?.total}
        />
      </Box>
      <Box mt={PADDINGS.M} flexDirection={'row'} width={'100%'}>
        <Stat label={'Group Users - All'} value={groupUsers.length} />
        <Stat
          label={'Group users - Standard '}
          value={groupUsers.length - groupUsersUltimate.length}
        />
        <Stat
          label={'Group users - Ultimate'}
          helpText={
            'Group App users that also exist as standard agency users in one or more accounts'
          }
          value={groupUsersUltimate.length}
        />
      </Box>
    </>
  );
}
