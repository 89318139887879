import React from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

export default ({ grey, blue, medium, bold, ...props }) => (
  <Text
    is='p'
    {...props}
    type={map({
      FILTER_TAG: true,
      GREY: grey,
      BLUE: blue,
      MEDIUM: medium,
      BOLD: bold
    })}
  />
);
