/* eslint-disable max-lines */

import React, { PureComponent } from 'react';
import { TextInput } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';
import Tooltip, { PLACEMENTS } from '@rexlabs/tooltip';
import { PrimaryButton } from 'shared/components/button';
import { Tag } from 'view/components/text';
import _ from 'lodash';
import types from 'prop-types';
import SearchIcon from 'assets/icons/search.svg';
import ChevronIcon from 'assets/icons/chevron.svg';
import CloseIcon from 'assets/icons/close.svg';

import { Select } from 'view/components/input/select';
import Label from 'view/components/text/label';
import TextButton from 'shared/components/button/text';
import ButtonBar from 'view/components/button-bar';
import SubHeading from 'view/components/text/sub-heading';

const defaultStyles = StyleSheet({
  container: {
    paddingTop: PADDINGS.L,
    paddingBottom: PADDINGS.XS
  },

  search: {
    width: '350px'
  },
  searchWithFilters: {
    width: '505px'
  },
  searchInput: {
    container: {
      height: 'fit-content'
    }
  },

  searchIcon: {
    color: COLORS.GREY_MEDIUM,
    marginLeft: '-4px',
    marginRight: '6px',
    height: '22px',
    width: '22px'
  },
  closeIcon: {
    color: COLORS.GREY_MEDIUM,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  },
  tagCloseIcon: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    pointerEvents: 'auto',
    marginLeft: 'auto'
  },
  searchTag: {
    backgroundColor: COLORS.BLUE,
    color: COLORS.WHITE,
    margin: '1px 2px',
    padding: '4px 8px',
    display: 'inline-block',
    borderRadius: '4px',
    pointerEvents: 'none',
    height: '22px'
  },
  filterTagText: {
    marginRight: PADDINGS.XXS
  }
});

@styled(defaultStyles)
@autobind
class ListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.inputStyleOverrides = {
      TextInput: StyleSheet({
        container: {
          height: 'fit-content'
        }
      })
    };
  }

  static defaultProps = {
    debounce: 300,
    placeholder: 'Search',
    filterValues: {}
  };

  state = {
    value: this.props.filterValues.searchTerm,
    tempFilters: this.props.filterValues
  };

  static propTypes = {
    placeholder: types.string,
    debounce: types.number
  };

  searchTimer = null;
  handleSearch(e) {
    const { onSearch, debounce } = this.props;
    e.persist();

    this.setState({
      value: e.target.value
    });

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      if (onSearch) {
        onSearch(e.target.value);
      }
    }, debounce);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen === true && this.state.isOpen === false) {
      this.setState({
        tempFilters: this.props.filterValues
      });
    }
  }

  renderClearButton() {
    const { onClear, styles: s } = this.props;

    if (onClear && this.state.value) {
      return (
        <CloseIcon
          onClick={() => this.setState({ value: '' }, onClear)}
          {...s('closeIcon')}
        />
      );
    }
  }

  renderFilterSelections(key) {
    const { filterValues } = this.props;

    const [first, second, ...rest] = filterValues[key];

    const firstLabel = first.label || '';
    const secondLabel = second ? `, ${second.label}` : '';
    const plusMore = rest.length > 0 ? ` + ${rest.length} more` : '';

    return `${firstLabel}${secondLabel}${plusMore}`;
  }

  removeFilterTab(key) {
    this.setState(
      {
        tempFilters: {
          [key]: []
        }
      },
      () => this.saveFiltersAndClose()
    );
  }

  renderFilterTab(filter) {
    const { styles: s } = this.props;
    return (
      <Box
        flex
        alignItems={'center'}
        padding={`${PADDINGS.XXS} ${PADDINGS.XS}`}
        {...s('searchTag')}
      >
        <Tag {...s('filterTagText')}>{`${
          filter.label
        }: ${this.renderFilterSelections(filter.id)}`}</Tag>
        <CloseIcon
          onClick={() => this.removeFilterTab(filter.id)}
          {...s('tagCloseIcon')}
        />
      </Box>
    );
  }

  renderSelectedFilters() {
    const { filters, filterValues } = this.props;

    // I need two sources for determining what to display when rendering the filter Tabs. "this.props.filters" and "this.props.filterValues".
    // "this.props.filters" contains an array with the different filter types as objects with their options, labels and identifier (e.g. "Office Groups", "User groups" etc...)
    // "this.props.filterValues" are the actual values that have been selected. Unfortunately, the label doesn't come with the filterValues. Only an object with the filter type as it's key and an array of selected values as it's value.
    // So my thought pattern with this was to loop through the original object and match the id with the key name of the filterValues and then map through the original filters array.
    // The last map which we return is actually an array of all the renderedFilterTabs collected in each loop through the filterValues object. If I returned inside the "for in" loop then only the first filter type that matached would be rendered.
    let filtersToRender = [];

    Object.keys(filterValues).map((key) => {
      if (filterValues[key].length > 0) {
        filtersToRender = [
          filtersToRender,
          ...filters.map((filter) => {
            if (filter.id === key) {
              return this.renderFilterTab(filter);
            }
          })
        ];
      }
    });

    return filtersToRender;
  }

  renderSuffixes() {
    const { filters, filterValues } = this.props;
    if (filters && filterValues) {
      return <Box flexWrap={'wrap'}>{this.renderSelectedFilters()}</Box>;
    }
    return this.renderClearButton();
  }

  resetFiltersAndClose() {
    const { resetFilter } = this.props;
    resetFilter(null);
    this.setState({
      isOpen: false
    });
  }

  saveFiltersAndClose() {
    const { onFilter } = this.props;
    const { tempFilters } = this.state;

    onFilter(tempFilters);
    this.setState({ isOpen: false });
  }

  handleSelect(e, filter) {
    const value = e.target.value;

    // TODO: Remove this MASSIVE hack to get around weird Select behavior
    const valuesToRemove = _.uniq(
      _.filter(value, (v) => _.filter(value, (v1) => v1 === v).length > 1)
    );

    this.setState({
      tempFilters: {
        ...this.state.tempFilters,
        [filter.id]: _.without(value, ...valuesToRemove).map((id) =>
          filter.options.find((opt) => opt.value === id)
        )
      }
    });
  }

  render() {
    const {
      styles: s,
      onSearch,

      placeholder,

      filters,
      ...props
    } = this.props;
    const { tempFilters, isOpen } = this.state;

    return (
      <Box
        {...s('container')}
        flexDirection='row'
        alignItems='center'
        {...props}
      >
        {!!onSearch && (
          <Box
            {...s('search', {
              searchWithFilters: filters
            })}
          >
            <StylesProvider components={this.inputStyleOverrides}>
              <TextInput
                {...s('searchInput')}
                onChange={this.handleSearch}
                placeholder={placeholder}
                prefix={<SearchIcon {...s('searchIcon')} />}
                value={this.state.value}
                suffix={this.renderSuffixes()}
                data-testid='search-input'
              />
            </StylesProvider>
          </Box>
        )}
        {!!filters && (
          <Box ml={5}>
            <Tooltip
              isOpen={isOpen}
              setOpen={(isOpen) => this.setState({ isOpen })}
              Content={() => (
                <Box p={15}>
                  <SubHeading>Filter</SubHeading>
                  {filters.map((filter) => (
                    <Box key={filter.id} width={250} mt={'10px'}>
                      <Label>{filter.label}</Label>
                      <Select
                        name='filter-select'
                        onChange={(option) => this.handleSelect(option, filter)}
                        multi={filter.multi}
                        selected={tempFilters[filter.id] || []}
                        options={filter.options}
                      />
                    </Box>
                  ))}
                  <ButtonBar>
                    <TextButton blue onClick={this.resetFiltersAndClose}>
                      Reset
                    </TextButton>
                    <PrimaryButton blue onClick={this.saveFiltersAndClose}>
                      Save
                    </PrimaryButton>
                  </ButtonBar>
                </Box>
              )}
              placement={PLACEMENTS.BOTTOM_END}
            >
              <PrimaryButton
                onClick={() => this.setState({ isOpen: !isOpen })}
                IconRight={ChevronIcon}
              >
                Filters
              </PrimaryButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  }
}

export default ListFilters;
