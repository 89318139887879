import React, { Fragment, PureComponent } from 'react';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';
import { styled, StyleSheet } from '@rexlabs/styling';

import { Body } from 'view/components/text';

const defaultStyles = StyleSheet({
  container: {
    paddingTop: '30px',
    paddingBottom: '30px',
    background: COLORS.NOTICE,
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '56px',
    maxWidth: '1032px',
    marginLeft: '65px'
  },
  spacer: {
    height: '100px'
  },
  secondaryText: {
    opacity: 0.6,
    fontSize: '12px',
    fontWeight: '600',
    marginTop: '15px'
  }
});

@styled(defaultStyles)
class NoticeBar extends PureComponent {
  render() {
    const {
      styles: s,
      mainText,
      secondaryText,
      heading,
      ...props
    } = this.props;
    return (
      <Fragment>
        <Box {...s('container')} {...props}>
          <div {...s('inner')}>
            <Box>
              <Body bold>{heading}</Body>
              <Body>{mainText}</Body>
              <div {...s('secondaryText')}>{secondaryText}</div>
            </Box>
          </div>
        </Box>
        <div {...s('spacer')} />
      </Fragment>
    );
  }
}

export default NoticeBar;
