import React, { PureComponent } from 'react';
import { Heading } from 'view/components/text';

import Box from '@rexlabs/box';
import { ListFilters } from 'view/components/list';

import SearchList from 'view/components/lists/search';
import Spinner from 'view/components/spinner';

import _ from 'lodash';
import { withModel } from '@rexlabs/model-generator';
import searchModel from 'data/models/custom/search';
import { PADDINGS } from 'theme';
import Screen from 'view/components/screen';

@withModel(searchModel)
class SearchScreen extends PureComponent {
  state = {
    searchTerm: ''
  };

  render() {
    const { search } = this.props;
    const { searchTerm } = this.state;
    return (
      <Screen title={'Search'}>
        <Heading>Search</Heading>
        <Box
          flexDirection='row'
          alignItems='center'
          sx={PADDINGS.XS}
          justifyContent='flex-start'
        >
          <ListFilters
            placeholder={'Search for listings, contacts or invoices'}
            onSearch={(searchTerm) => this.setState({ searchTerm })}
          />
          {_.get(search, 'status') === 'loading' && !!searchTerm && (
            <Box mt={PADDINGS.XS}>
              <Spinner size={'small'} />
            </Box>
          )}
        </Box>
        <SearchList searchTerm={this.state.searchTerm} />
      </Screen>
    );
  }
}

export default SearchScreen;
