import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS } from 'theme';
import { Body } from 'view/components/text';
import types from 'prop-types';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '80px',
    paddingBottom: '80px',
    textAlign: 'center'
  },
  small: {
    height: '100px',
    paddingBottom: 0,
    paddingTop: 0,
    backgroundColor: COLORS.BACKGROUND
  }
});
@styled(defaultStyles)
@autobind
export default class EmptyList extends PureComponent {
  static propTypes = {
    message: types.string,
    small: types.bool
  };

  render() {
    const { styles: s, message, small, ...rest } = this.props;
    return (
      <div {...s('container', { small })} {...rest}>
        <Body large grey>
          {message}
        </Body>
      </div>
    );
  }
}
