import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import {
  withModel,
  withQuery,
  query,
  withValueLists
} from '@rexlabs/model-generator';
import announcementsModel from 'data/models/entities/announcements';
import { ReactForms, Form, FormField } from 'view/components/form';
import { TextInput, TextArea } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from 'shared/components/grid';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';
import dayjs from 'dayjs';
import { createValidationRules } from 'shared/utils/form';
import PaddingBox from 'view/components/padding-box';
import TimeSelect from 'view/components/input/select/time';
import assignedAccountGroupsModel from 'data/models/system-lists/assigned-account-groups';
import accountGroupModel from 'data/models/system-lists/account-group';
import sessionModel from 'data/models/custom/session';
import ValueListSelect from 'view/components/input/select/value-list-select';
import DateSelect from 'view/components/input/select/date-select';
import { announcementListQuery } from 'view/components/lists/announcements';
import { COLORS, TEXTS } from 'theme';

export const announcementRecordQuery = query`{
  announcementRecord:${announcementsModel} (id: ${(p) =>
  _.get(p, 'match.params.announcementId')}) {
    id
    subject
    body
    display_until
    limit_to_account_groups
  }
}`;

@styled(
  StyleSheet({
    banner: {
      ...TEXTS.BODY,
      backgroundColor: COLORS.PALE_YELLOW
    }
  })
)
@withErrorDialog
@withQuery(announcementRecordQuery)
@withQuery(announcementListQuery)
@withModel(sessionModel)
@withValueLists(assignedAccountGroupsModel)
@autobind
class AnnouncementsDetailsDialog extends PureComponent {
  handleSubmit(values) {
    const {
      match,
      announcementRecord,
      announcements,
      closeModal,
      errorDialog
    } = this.props;
    const id = _.get(match, 'params.announcementId');

    const action = id
      ? announcementRecord.updateItem({
          data: {
            id,
            display_until: dayjs(
              `${values.expiryDate} ${values.expiryTime}`
            ).unix(),
            subject: values.subject,
            body: values.body,
            display_notice_level: 'info',
            display_area: 'dashboard'
          }
        })
      : announcementRecord.createItem({
          data: {
            display_until: dayjs(
              `${values.expiryDate} ${values.expiryTime}`
            ).unix(),
            subject: values.subject,
            body: values.body,
            display_notice_level: 'info',
            display_area: 'dashboard',
            limit_to_account_groups: _.get(values, 'limitTo', []).map((id) => ({
              account_group_id: id
            }))
          }
        });

    // Note that the announcements list is refreshed using the shared
    // announcementListQuery that's imported from the list component.

    return action
      .then(announcements.refreshList)
      .then(closeModal)
      .catch(errorDialog.open);
  }

  getInitialValues() {
    const { match, announcementRecord } = this.props;

    if (!_.get(match, 'params.announcementId')) {
      return {
        limitTo: []
      };
    }

    const data = _.get(announcementRecord, 'item.data');
    return {
      expiryDate: dayjs(_.get(data, 'display_until', 0) * 1000).format(
        'YYYY-MM-DD'
      ),
      expiryTime: dayjs(_.get(data, 'display_until', 0) * 1000).format(
        'HH:mm:ss'
      ),
      subject: _.get(data, 'subject'),
      body: _.get(data, 'body'),
      limitTo: _.get(data, 'limit_to_account_groups', []).map((related) =>
        _.get(related, 'account_group.id')
      )
    };
  }

  render() {
    const {
      match,
      announcementRecord,
      session,
      valueLists: { assignedAccountGroups },
      closeModal,
      styles: s
    } = this.props;

    const isLoading =
      _.get(match, 'params.announcementId') &&
      _.get(announcementRecord, 'item.status') === 'loading' &&
      _.get(assignedAccountGroups, '') === 'loading';

    const canManageAll = session.checkUserHasPermission('announcements.manage');
    const assignedCount = _.get(assignedAccountGroups, 'items.length');
    const id = _.get(match, 'params.announcementId');
    const title = id ? 'Edit Announcement' : 'Create Announcement';

    return (
      <Dialog
        isLoading={isLoading}
        title={title}
        height={600}
        closeDialog={closeModal}
        hasPadding={false}
      >
        <ReactForms
          asyncValuesReady={!isLoading}
          initialValues={this.getInitialValues()}
          handleSubmit={this.handleSubmit}
          validate={createValidationRules({
            subject: 'required',
            body: 'required',
            expiryDate: ['required', 'expiry date'],
            expiryTime: ['required', 'expiry time']
          })}
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form style={{ width: '100%' }}>
              {!canManageAll &&
                !id &&
                _.get(values, 'limitTo.length') === 0 && (
                  <PaddingBox
                    width='100%'
                    {...s('banner')}
                  >{`By default, this announcement will be limited to your ${assignedCount} Office Group${
                    assignedCount === 1 ? '' : 's'
                  }.`}</PaddingBox>
                )}
              <PaddingBox width='100%'>
                <FormField
                  name='limitTo'
                  label='Limit to Office Groups'
                  Input={ValueListSelect}
                  inputProps={{
                    multi: true,
                    models: [
                      canManageAll || id
                        ? accountGroupModel
                        : assignedAccountGroupsModel
                    ],
                    disabled: Boolean(id)
                  }}
                />
              </PaddingBox>
              <PaddingBox width='100%' grey>
                <Grid>
                  <Column width={6}>
                    <FormField
                      name='expiryDate'
                      label='Expiry Date'
                      Input={DateSelect}
                    />
                  </Column>
                  <Column width={6}>
                    <FormField
                      name='expiryTime'
                      label='Expiry Time'
                      Input={TimeSelect}
                    />
                  </Column>
                </Grid>
                <FormField name='subject' label='Subject' Input={TextInput} />
                <FormField name='body' label='Body' Input={TextArea} />
              </PaddingBox>
              <PaddingBox style={{ paddingTop: '0px' }} width='100%'>
                <ButtonBar isLoading={isSubmitting}>
                  <TextButton blue onClick={closeModal}>
                    Cancel
                  </TextButton>
                  <PrimaryButton blue onClick={submitForm}>
                    Save
                  </PrimaryButton>
                </ButtonBar>
              </PaddingBox>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default AnnouncementsDetailsDialog;
