import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';
import React, { Component } from 'react';
import { autobind } from 'core-decorators';

import Option from './core';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: '#FFFFFF',
    padding: '0.25rem',
    paddingLeft: '1rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#CAE8F8'
    }
  }
});

@styled(defaultStyles)
@autobind
class SelectOption extends Component {
  static defaultProps = {
    isPassive: false,
    onHover: _.noop,
    onSelect: _.noop
  };

  render() {
    const {
      styles: s,
      option,
      pluckLabel,
      isActive,
      isPassive,
      isSelected
    } = this.props;
    const isFixture = _.get(option, 'isFixture');
    const Fixture = _.get(option, 'model.select.Fixture');

    return isFixture ? (
      <Fixture {...this.props} />
    ) : (
      <Option
        {...this.props}
        {...s('container', {
          isActive: isActive && !isPassive,
          isSelected: isSelected && !isPassive,
          isPassive
        })}
      >
        {pluckLabel(option)}
      </Option>
    );
  }
}

export default SelectOption;
