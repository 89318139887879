import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';

// import RexTable from '@rexlabs/table';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'theme';

// import { ArrowDropdown } from 'view/components/action-menu';

import moment from 'moment';
import Icon, { ICONS } from 'shared/components/icon';
import _ from 'lodash';
import { TextButton } from 'shared/components/button';
import Spinner from 'group-app/src/view/components/spinner';
import List from '@rexlabs/list';
import ListRow from 'group-app/src/view/components/list/row';
import ListCell from 'group-app/src/view/components/list/cell';

@styled(
  StyleSheet({
    errorLinkContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%'
    },
    errorIcon: {
      color: COLORS.YELLOW,
      size: '12px',
      paddingTop: PADDINGS.XXS,
      paddingLeft: PADDINGS.XXS
    },
    filenameContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      '&:hover': {
        '& > div': {
          opacity: 1,
          transition: '0.15s'
        }
      }
    },
    filename: {
      display: 'flex',
      alignItems: 'center'
    },
    rollbackDropdown: {
      opacity: 0
    }
  })
)
@autobind
class PafFileView extends PureComponent {
  errorRate() {
    const { ingested, total } = this.props.fileData;

    if (!total || !ingested) {
      return 0;
    }

    return (1 - ingested / total) * 100;
  }

  rollbackDropdown() {
    const { isCurrent, rollbackFile } = this.props;

    if (!isCurrent) {
      return null;
    }

    return [{ id: 0, label: 'Roll Back File', onClick: rollbackFile }];
  }

  renderFilename() {
    const { styles: s, fileData } = this.props;

    return (
      <div {...s('filenameContainer')}>
        <div {...s('filename')}>
          {fileData.name || `${fileData.file_id.slice(0, 8)}...`}
        </div>
        <div {...s('rollbackDropdown')}>{this.rollbackDropdown()}</div>
      </div>
    );
  }

  renderErrorRate() {
    const { fileData, styles: s, openErrorDialog } = this.props;

    const { rows_total: rowsTotal, rows_ingested: rowsIngested } = fileData;
    const errorRate = this.errorRate();

    return (
      <Box styles={{ padding: 0 }} {...s('errorLinkContainer')}>
        <TextButton blue onClick={openErrorDialog(fileData)}>
          {errorRate}% ({rowsTotal - rowsIngested} Rows)
        </TextButton>
        {errorRate >= 10 ? (
          <Icon {...s('errorIcon')} type={ICONS.WARNING} />
        ) : null}
      </Box>
    );
  }

  renderHeader() {
    return (
      <ListRow isHeader>
        <ListCell width={'25%'}>Filename</ListCell>
        <ListCell width={'25%'}>Date uploaded</ListCell>
        <ListCell width={'25%'}>Uploaded by</ListCell>
        <ListCell width={'25%'}>Error rate</ListCell>
      </ListRow>
    );
  }

  renderItem(item, index) {
    return (
      <ListRow
        key={item.id}
        odd={index % 2}
        actionMenuItems={this.rollbackDropdown()}
      >
        <ListCell ellipsis width={'25%'}>
          {item.filename}
        </ListCell>
        <ListCell ellipsis width={'25%'}>
          {item.dateUploaded}
        </ListCell>
        <ListCell ellipsis width={'25%'}>
          {item.uploadedBy}
        </ListCell>
        <ListCell ellipsis width={'25%'}>
          {item.errorRate}
        </ListCell>
      </ListRow>
    );
  }

  getData() {
    const { fileData } = this.props;

    const dateUploaded = _.get(fileData, 'system_ctime');
    const uploadedByName = _.get(fileData, 'uploaded_by.name');

    return [
      {
        key: fileData.file_id,
        filename: fileData.name || `${fileData.file_id.slice(0, 8)}...`,
        dateUploaded: moment(dateUploaded * 1000).format('DD MMM YYYY'),
        uploadedBy: uploadedByName,
        errorRate: this.renderErrorRate()
      }
    ];
  }

  render() {
    const { fileData } = this.props;

    if (!fileData) {
      return null;
    }

    return (
      <List
        isLoading={false}
        items={this.getData()}
        Header={this.renderHeader}
        renderItem={this.renderItem}
        LoadingView={() => <Spinner hasContainer />}
        ErrorView={() => <p>Something went wrong!</p>}
      />
    );
  }
}

export default PafFileView;
