import React, { useMemo, useState, forwardRef, lazy, Suspense } from 'react';
import _ from 'lodash';
import { COLORS, FONT } from 'src/theme';
import Box from '@rexlabs/box';
import { insertCss } from '@rexlabs/styling';
import { TextButton } from 'shared/components/button';

// AG Grid has a hefty bundle size, so we code split
const AgGridReact = lazy(() => import('./ag-grid'));

const ROW_HEIGHT = 30;
const EXPANDED_SUBTABLE_HEIGHT = 290;
const CELL_HORIZONTAL_PADDING = 10;

insertCss(`
  .rex-simple-data-grid .ag-theme-alpine {
    font-family: ${FONT.FAMILY.PROXIMA_NOVA};
    --ag-odd-row-background-color: ${COLORS.BACKGROUND};
    --ag-header-background-color: ${COLORS.BACKGROUND};
    --ag-borders-secondary: none;
    --ag-border-color: ${COLORS.GREY_LIGHT};
    --ag-secondary-border-color: ${COLORS.GREY_LIGHT};
    --ag-row-hover-color: ${COLORS.GREY_LIGHT};
    --ag-foreground-color: ${COLORS.PRIMARY_DARK};
    --ag-range-selection-border-color: transparent;
    --ag-header-column-resize-handle-color: ${COLORS.GREY}
  }
  
  .rex-simple-data-grid .ag-header-cell, .rex-simple-data-grid .ag-cell {
    padding-left: ${CELL_HORIZONTAL_PADDING}px;
    padding-right: ${CELL_HORIZONTAL_PADDING}px;
  }
`);

function calculatePinnedBottomData(gridApi, columnsToTotal) {
  if (!gridApi || !columnsToTotal.length) return [];

  const row = _.mapValues(gridApi.getModel().getRow(0)?.data, () => null);

  if (!row) return [];

  columnsToTotal.forEach((element) => {
    gridApi.forEachNodeAfterFilter((rowNode) => {
      const rowValue = rowNode.data[element] || 0;

      if (Array.isArray(rowValue)) {
        row[element] = Array.isArray(row[element])
          ? row[element].concat(rowValue)
          : rowValue;
      } else {
        row[element] += Number(rowValue);
      }
    });
  });
  return [row];
}

function ExportCsvButton({ api }) {
  return (
    <Box alignItems={'center'} height={'100%'}>
      <TextButton
        onClick={() =>
          api.exportDataAsCsv({
            processCellCallback({ value }) {
              if (value === Object(value)) {
                const mapValue = (v) => v?.name || v?.id || v;
                return Array.isArray(value)
                  ? value.map(mapValue).join(', ')
                  : mapValue(value);
              }
              return value;
            }
          })
        }
      >
        Export as CSV
      </TextButton>
    </Box>
  );
}

const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'right'
    },
    { statusPanel: ExportCsvButton, align: 'right' }
  ]
};

const SimpleDataGrid = forwardRef(function SimpleDataGrid(
  {
    columnDefs,
    rowData,
    detailCellRendererParams,
    columnsToTotal = [],
    style,
    ...rest
  },
  ref
) {
  const [gridApi, setGridApi] = useState(null);
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

  const mappedDetailCellParams = useMemo(() => {
    return {
      ...detailCellRendererParams,
      detailGridOptions: {
        rowHeight: ROW_HEIGHT,
        headerHeight: ROW_HEIGHT,
        ...detailCellRendererParams.detailGridOptions
      }
    };
  }, [detailCellRendererParams]);

  return (
    <div className={'rex-simple-data-grid'}>
      <div style={style} className='ag-theme-alpine'>
        <Suspense fallback={null}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            masterDetail={!!detailCellRendererParams}
            detailCellRendererParams={
              detailCellRendererParams ? mappedDetailCellParams : null
            }
            rowHeight={ROW_HEIGHT}
            headerHeight={ROW_HEIGHT}
            detailRowHeight={EXPANDED_SUBTABLE_HEIGHT}
            animateRows={false}
            statusBar={statusBar}
            pinnedBottomRowData={pinnedBottomRowData}
            onGridReady={({ api }) => {
              if (!gridApi) setGridApi(api);
              setPinnedBottomRowData(
                calculatePinnedBottomData(api, columnsToTotal)
              );
            }}
            onFilterChanged={({ api }) => {
              setGridApi(api);
              setPinnedBottomRowData(
                calculatePinnedBottomData(api, columnsToTotal)
              );
            }}
            {...rest}
          />
        </Suspense>
      </div>
    </div>
  );
});

export default SimpleDataGrid;
