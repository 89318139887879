import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { TEXTS } from 'src/theme';

class OptionNotFound extends PureComponent {
  render() {
    return (
      <Box style={{ ...TEXTS.NOT_FOUND, paddingLeft: '1rem' }}>No Results</Box>
    );
  }
}

export default OptionNotFound;
