import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import PaddingBox from 'view/components/padding-box';
import Box from '@rexlabs/box';
import { ListFilters } from 'view/components/list/index';
import { Body } from 'view/components/text/index';
import Checkbox from '@rexlabs/checkbox';
import { COLORS, PADDINGS } from 'src/theme/index';
import { autobind } from 'core-decorators';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button/index';
import privilegeSetsModel from 'data/models/custom/privilege-sets';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { accountGroupUsersQuery } from 'view/screens/users/details';

const styles = StyleSheet({
  scrollableList: {
    height: '280px',
    overflow: 'auto',
    position: 'relative'
  }
});

@withErrorDialog
@styled(styles)
@withModel(privilegeSetsModel)
@withQuery(accountGroupUsersQuery)
@autobind
class PrivilegeSetsDialog extends PureComponent {
  state = {
    searchTerm: '',
    showSelected: false,
    selected: [],
    isSubmitting: false,
    applyAll: false
  };

  componentDidMount() {
    const { privilegeSets } = this.props;
    privilegeSets.fetchList();
  }

  toggleItem(itemId) {
    const { selected } = this.state;
    this.setState({
      selected: selected.includes(itemId)
        ? selected.filter((id) => id !== itemId)
        : selected.concat([itemId])
    });
  }

  filteredItems() {
    const {
      privilegeSets: { items },
      match: {
        params: { accountGroupUserId }
      },
      accountGroupUsers
    } = this.props;
    const { searchTerm } = this.state;

    const officeGroupPrivilegeSets = accountGroupUsers.list.items.find(
      (item) => parseInt(item.id, 10) === parseInt(accountGroupUserId, 10)
    );

    const hasSearch = (item) => {
      if (searchTerm.length >= 2) {
        return item.name.toLowerCase().includes(this.state.searchTerm);
      }

      return true;
    };

    return Object.values(items).filter((item) => {
      if (!officeGroupPrivilegeSets) {
        return hasSearch(item);
      }

      return (
        !officeGroupPrivilegeSets.related.user_account_group_privilege_sets.find(
          (set) => parseInt(set.privilege_set.id, 10) === parseInt(item.id, 10)
        ) && hasSearch(item)
      );
    });
  }

  filterSelected() {
    this.setState({
      showSelected: !this.state.showSelected
    });
  }

  handleSubmit() {
    const { accountGroupUsers, closeModal, errorDialog, match } = this.props;
    const { selected, applyAll } = this.state;

    this.setState({ isLoading: true });

    const officeGroupsToUpdate = applyAll
      ? accountGroupUsers.list.items
      : [
          accountGroupUsers.list.items.find(
            (item) =>
              parseInt(item.id, 10) ===
              parseInt(match.params.accountGroupUserId, 10)
          )
        ];

    const officeGroupPromises = officeGroupsToUpdate.map(
      (officeGroup) =>
        new Promise((resolve, reject) => {
          accountGroupUsers
            .updateItem({
              id: officeGroup.id,
              data: {
                related: {
                  user_account_group_privilege_sets:
                    officeGroup.related.user_account_group_privilege_sets.concat(
                      selected.map((id) => ({ privilege_set_id: id }))
                    )
                }
              }
            })
            .then(resolve)
            .catch(reject);
        })
    );

    Promise.all(officeGroupPromises)
      .then(closeModal)
      .catch((error) => {
        this.setState({ isLoading: false });
        errorDialog.open(error);
      });
  }

  renderItem(item, index) {
    const {
      privilegeSets: { list }
    } = this.props;
    const { selected } = this.state;
    const { toggleItem } = this;

    const isLastItem = index + 1 === list.length;
    const styleOverrides = { borderBottom: `1px solid ${COLORS.GREY_LIGHT}` };
    if (isLastItem) {
      styleOverrides.borderBottom = 'none';
    }

    if (item) {
      return (
        <PaddingBox
          paddingBottom={isLastItem ? '0' : PADDINGS.S}
          style={styleOverrides}
          flex
          alignItems={'flex-start'}
        >
          <Box marginTop='-2.5px'>
            <Checkbox
              id={item.id}
              value={selected.includes(item.id)}
              onChange={() => toggleItem(item.id)}
            />
          </Box>
          <Box paddingLeft='10px'>
            <Body>{item.name}</Body>
            <Body grey>{item.description}</Body>
          </Box>
        </PaddingBox>
      );
    }
  }

  render() {
    const {
      privilegeSets: { items },
      closeModal,
      styles: s
    } = this.props;
    const { selected, showSelected, isLoading, applyAll } = this.state;

    return (
      <Dialog
        title={'Add a Privilege Set'}
        height={600}
        closeDialog={closeModal}
        hasPadding={false}
      >
        <PaddingBox paddingTop='0' grey>
          <ListFilters
            styles={{
              search: {
                width: '100%'
              }
            }}
            onClear={() => this.setState({ searchTerm: '' })}
            onSearch={(searchTerm) => this.setState({ searchTerm })}
          />
          <Box flex={1}>
            <Body>{`${selected.length} sets selected`}</Body>
            <Box paddingLeft='10px'>
              <Body onClick={this.filterSelected} underlined clickable grey>
                {!showSelected ? 'show selected' : 'show all'}
              </Body>
            </Box>
          </Box>
        </PaddingBox>
        <PaddingBox paddingTop='0px' {...s('scrollableList')}>
          {items && showSelected
            ? selected
                .sort((a, b) => {
                  return a - b;
                })
                .map((id, i) =>
                  this.renderItem(
                    this.filteredItems().find((item) => item && item.id === id),
                    i
                  )
                )
            : this.filteredItems().map((item, i) => this.renderItem(item, i))}
        </PaddingBox>

        <PaddingBox paddingLeft='30px' flex alignItems='center' grey>
          <Checkbox
            id={'apply-all'}
            value={applyAll}
            onClick={() => this.setState({ applyAll: !applyAll })}
          />
          <Body>
            {
              "Apply selected Privilege Sets to all of this user's Office Groups"
            }
          </Body>
        </PaddingBox>

        <PaddingBox paddingTop='0' width='100%'>
          <ButtonBar isLoading={isLoading}>
            <TextButton blue onClick={closeModal}>
              Cancel
            </TextButton>
            <PrimaryButton blue onClick={this.handleSubmit}>
              Apply Selected Sets
            </PrimaryButton>
          </ButtonBar>
        </PaddingBox>
      </Dialog>
    );
  }
}

export default PrivilegeSetsDialog;
