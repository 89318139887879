import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withQuery, query, withModel } from '@rexlabs/model-generator';
import { push, Link } from '@rexlabs/whereabouts';
import announcementsModel from 'data/models/entities/announcements';

import { ListRow, ListCell } from 'view/components/list';
import dayjs from 'dayjs';

import ROUTES from 'src/routes';
import _ from 'lodash';

import FilterableList from 'view/components/filterable-list';
import filtersModel from 'data/models/custom/filters';
import sessionModel from 'data/models/custom/session';

function getCriteria(props) {
  if (_.get(props, 'filters.values.announcements.searchTerm')) {
    return [
      {
        name: 'subject',
        type: 'like',
        value: `%${_.get(props, 'filters.values.announcements.searchTerm')}%`
      }
    ];
  }
  return [];
}

// Note that the query is exported so that it can be referenced in the dialog
// to effect the refreshing of the list from the create/edit anouncement dialog.

export const announcementListQuery = query`{
  ${announcementsModel} (criteria: ${getCriteria}) {
    id
    subject
    display_until
  }
}`;

@withQuery(announcementListQuery)
@withModel(filtersModel)
@withModel(sessionModel)
@autobind
class AnnouncementsList extends PureComponent {
  deleteAnnouncement(itemId) {
    return () => {
      push(ROUTES.ANNOUNCEMENTS.DELETE, {
        params: { announcementId: itemId }
      });
    };
  }

  renderHeader() {
    return (
      <ListRow isHeader>
        <ListCell width={450}>Subject</ListCell>
        <ListCell width={200}>Expiry</ListCell>
        <ListCell flex={1}>Office Groups</ListCell>
      </ListRow>
    );
  }

  renderItem(item, index) {
    const { session } = this.props;
    const canManage =
      session.checkUserHasPermission('announcements.manage') ||
      session.checkUserHasPermission('announcements.manage_for_office_group');

    return (
      <Link to={ROUTES.ANNOUNCEMENTS.EDIT} params={{ announcementId: item.id }}>
        {({ onClick }) => (
          <ListRow
            data-testid='announcement-list-item'
            key={item.id}
            odd={index % 2}
            onClick={canManage ? onClick : _.noop}
            actionMenuItems={
              canManage
                ? [
                    {
                      label: 'Delete Announcement',
                      onClick: this.deleteAnnouncement(item.id)
                    }
                  ]
                : undefined
            }
          >
            <ListCell ellipsis width={450}>
              {item.subject}
            </ListCell>
            <ListCell width={200}>
              {item.display_until
                ? dayjs(item.display_until * 1000).format('DD MMM YYYY')
                : '-'}
            </ListCell>
            <ListCell flex={1}>-</ListCell>
          </ListRow>
        )}
      </Link>
    );
  }

  render() {
    const { announcements, session } = this.props;
    const canManage =
      session.checkUserHasPermission('announcements.manage') ||
      session.checkUserHasPermission('announcements.manage_for_office_group');

    return (
      <FilterableList
        entityName={'announcements'}
        addLink={canManage ? ROUTES.ANNOUNCEMENTS.CREATE : undefined}
        renderItem={this.renderItem}
        renderHeader={this.renderHeader}
        model={announcements}
        getCriteria={getCriteria}
      />
    );
  }
}

export default AnnouncementsList;
