import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import types from 'prop-types';

import { StyleSheet, keyframes, styled } from '@rexlabs/styling';
import { COLORS } from 'theme';

const spinnerAnimation = keyframes({
  to: {
    transform: 'rotate(360deg)'
  }
});

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: 'white',
    minHeight: '200px'
  },
  spinner: {
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderTop: '1px solid #555',
    animation: `${spinnerAnimation} 0.8s infinite cubic-bezier(0.25, 0.1, 0.25, 0.6)`
  },
  small: {
    width: '22px',
    height: '22px',
    border: '2px solid rgba(0, 0, 0, 0.1)',
    borderTop: '2px solid #555'
  },
  medium: {
    width: '36px',
    height: '36px',
    border: '3px solid rgba(0, 0, 0, 0.1)',
    borderTop: '3px solid #555'
  },
  large: {
    width: '64px',
    height: '64px',
    border: '6px solid rgba(0, 0, 0, 0.1)',
    borderTop: '6px solid #555'
  }
});

@styled(defaultStyles)
@autobind
class Spinner extends PureComponent {
  static propTypes = {
    size: types.oneOf(['small', 'medium', 'large']),
    mainColor: types.string,
    secondaryColor: types.string,
    hasContainer: types.bool
  };

  static defaultProps = {
    size: 'medium',
    mainColor: COLORS.BLUE,
    secondaryColor: COLORS.GREY_LIGHT
  };

  render() {
    const {
      styles: s,
      secondaryColor,
      mainColor,
      style,
      hasContainer,
      size,
      ...props
    } = this.props;
    const spinner = (
      <div
        {...s('spinner', {
          small: size === 'small',
          medium: size === 'medium',
          large: size === 'large'
        })}
        style={{
          borderColor: secondaryColor,
          borderTopColor: mainColor,
          ...style
        }}
        {...props}
      />
    );

    return hasContainer ? (
      <Box
        flex={1}
        justifyContent={'center'}
        alignItems={'center'}
        minHeight={'300px'}
        width={'100%'}
        {...s('container')}
      >
        {spinner}
      </Box>
    ) : (
      spinner
    );
  }
}

export default Spinner;
