import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS, COLORS } from 'src/theme';
import { autobind } from 'core-decorators';

@autobind
class PaddingBox extends PureComponent {
  getBackground() {
    const { grey } = this.props;
    if (grey) {
      return COLORS.BACKGROUND;
    }
  }

  render() {
    const { style, ...props } = this.props;
    return (
      <Box
        p={PADDINGS.S}
        width={'100%'}
        style={{ backgroundColor: this.getBackground(), ...(style || {}) }}
        {...props}
      />
    );
  }
}

export default PaddingBox;
