import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'billing';

const userCategoryLabels = {
  head_office_user: 'Head Office',
  partner_integration_user: 'Integration'
};

const initialState = {
  accounts: [],
  users: [],
  summary: {},
  status: 'loading',
  groupUsers: [],
  groupUsersUltimate: []
};

const actionCreators = {
  fetchBillingDetails: {
    request: async (payload) => {
      const [licenceBreakdown, groupAppUsers] = await Promise.all([
        api.post('RexGroupAccountBilling::getUserLicenseBreakdown'),
        api.fetchAll('AccountUsers::search', {
          limit: 100,
          criteria: [{ name: 'id', type: '!=', value: '102' }]
        })
      ]);
      return {
        ...licenceBreakdown.data.result,
        groupUsers: groupAppUsers.rows
      };
    },
    reduce: {
      initial: (state) => {
        return {
          ...state,
          status: 'loading'
        };
      },
      success: (state, action) => {
        const users = getUserData(
          action.payload.accounts,
          action.payload.groupUsers
        );
        return {
          ...state,
          users,
          accounts: getAccountData(
            action.payload.accounts,
            action.payload.groupUsers
          ),
          summary: action.payload.summary,
          groupUsers: action.payload.groupUsers,
          groupUsersUltimate: action.payload.groupUsers.filter((groupUser) =>
            users.find((user) => String(user.id) === String(groupUser.id))
          ),
          status: 'loaded'
        };
      },
      failure: () => {
        return { ...initialState, status: 'error' };
      }
    }
  }
};

const selectors = {
  accounts: (state) => state.billing.accounts,
  users: (state) => state.billing.users,
  status: (state) => state.billing.status,
  summary: (state) => state.billing.summary,
  groupUsers: (state) => state.billing.groupUsers,
  groupUsersUltimate: (state) => state.billing.groupUsersUltimate
};

function mapAccount(account, includeSubRows, groupUsers) {
  return {
    id: account.id,
    name: account.name,
    total: account.billable_seats.total,
    allocated: account.billable_seats.allocated,
    unallocated: account.billable_seats.unallocated,
    ...(includeSubRows ? { users: getUserData([account], groupUsers) } : {})
  };
}

function getAccountData(accounts, groupUsers) {
  return accounts.map((v) => mapAccount(v, true, groupUsers));
}

function getUserData(accounts, groupUsers) {
  const users = accounts.reduce((accum, account) => {
    [
      ...account.non_billable_seats.allocated_to,
      ...account.billable_seats.allocated_to
    ].forEach((user) => {
      if (user.category || !accum[user.id]) {
        accum[user.id] = {
          ...user,
          category: userCategoryLabels[user.category?.id] || 'Agency User',
          isGroupAppUser: !!groupUsers.find(
            (groupUser) => String(groupUser.id) === String(user.id)
          ),
          accounts: (accum[user.id]?.accounts || []).concat([
            mapAccount(account, false)
          ])
        };
      }
    });
    return accum;
  }, {});

  // Filter out support user from results
  const result = Object.values(users).filter(
    (user) => String(user.id) !== '102'
  );
  return result;
}

export default new Generator(TYPE).createModel({
  initialState,
  actionCreators,
  selectors
});
