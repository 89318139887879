import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import { TEXTS, PADDINGS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import ActionMenu from '@rexlabs/action-menu';
import { autobind } from 'core-decorators';

const defaultStyles = StyleSheet({
  container: {
    minHeight: '49px',
    width: '100%',
    position: 'relative',

    '& svg': {
      opacity: 0
    },

    '&:hover svg': {
      opacity: 1
    }
  },

  containerHeader: {
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
    ...TEXTS.BODY_LARGE,
    boxShadow: '0 3px 4px rgba(0,57,149,0.04)',
    position: 'relative',
    minHeight: '42px'
  },

  containerEven: {
    background: COLORS.BACKGROUND
  },

  containerClickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLORS.GREY_LIGHT
    }
  },

  actionMenu: {
    position: 'absolute',
    right: PADDINGS.S
  }
});

@styled(defaultStyles)
@autobind
class ListRow extends PureComponent {
  static defaultProps = {
    justifyContent: 'space-between'
  };

  state = {
    isHovering: false
  };

  hoveringTrue() {
    if (this.state.isHovering) return;
    this.setState({ isHovering: true });
  }

  hoveringFalse() {
    if (!this.state.isHovering) return;
    this.setState({ isHovering: false });
  }

  render() {
    const {
      styles: s,
      children,
      isHeader,
      justifyContent,
      odd,
      actionMenuItems,
      ...props
    } = this.props;
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent={justifyContent}
        onMouseEnter={this.hoveringTrue}
        onMouseLeave={this.hoveringFalse}
        {...s('container', {
          containerEven: !odd && !isHeader,
          containerHeader: isHeader,
          containerClickable: !!props.onClick
        })}
        {...props}
      >
        {children}
        {!!actionMenuItems && (
          <div {...s('actionMenu')}>
            <ActionMenu
              stopPropagation
              preventDefault
              distance={'3px'}
              Button={() => <Icon type={ICONS.ACTION_MENU} />}
              items={actionMenuItems}
            />
          </div>
        )}
      </Box>
    );
  }
}

export default ListRow;
