import React, { PureComponent, Fragment } from 'react';

import PostcodeUpload from 'shared/components/paf-upload';

export default class PAFUpload extends PureComponent {
  render() {
    return (
      <Fragment>
        <PostcodeUpload />
      </Fragment>
    );
  }
}
