/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'core-js';
import 'utils/globals';

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import { PortalProvider } from '@rexlabs/portal';

import { store } from 'store';
import { api } from 'shared/utils/api-client';
import { initTheme, TEXTS } from 'theme';
import config from 'shared/utils/config';
import { initSegment } from 'shared/utils/analytics';
import Analytics from 'shared/utils/vivid-analytics';
import analyticsMiddleware from 'shared/utils/analytics/middleware';
import GroupApp from 'view/app';

// Note: Ensure this is imported last, as it needs to inject styles last.
import COMPONENTS from 'theme/components';
import { createRoot } from 'react-dom/client';

config.SEGMENT_ID && initSegment(config.SEGMENT_ID);
Analytics.setHandlers([analyticsMiddleware]);

window.app = {
  config,
  store,
  api
};

if (config.GROUP_APP_BUGSNAG_ID && window.bugsnag) {
  try {
    window.bugsnagClient = window.bugsnag({
      beforeSend: (report) => {
        // We want to ignore `ResizeObserver loop limit exceeded` errors, since this is
        // just a browser warning that is unfortunately handled through `window.onerror`
        // and is expected behaviour :/
        // See https://github.com/WICG/resize-observer/issues/38#issuecomment-334816361
        if (
          [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.'
          ].includes(report.errorMessage)
        ) {
          report.ignore();
        }
      },
      apiKey: config.GROUP_APP_BUGSNAG_ID,
      appVersion: config.RELEASE?.VERSION,
      releaseStage: config.RELEASE?.STAGE || 'local'
    });
  } catch (e) {
    console.error(`Bugsnag configuration variables are invalid:\n${e}`);
  }
}

// Setup global parts of theme
initTheme();

class GroupAppMount extends Component {
  componentDidMount() {
    // NOTE: this is just to imitate the typekit behaviour to set a class
    //  once app is mounted ... TODO: better solution or simply remove
    const root = document.getElementsByTagName('html')[0];
    root.classList.add('ready');
  }

  render() {
    return (
      <PortalProvider>
        <Provider store={store}>
          <StylesProvider components={COMPONENTS} debug={__DEV__}>
            <TextProvider text={TEXTS}>
              <GroupApp />
            </TextProvider>
          </StylesProvider>
        </Provider>
      </PortalProvider>
    );
  }
}

const root = createRoot(document.querySelector('#app'));
root.render(<GroupAppMount />);
