/* eslint-disable max-lines */

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';
import {
  Select as VividSelect,
  SelectInput as VividSelectInput,
  SelectMenu as VividSelectMenu
} from '@rexlabs/select-input';
import { PADDINGS } from 'src/theme';
import _ from 'lodash';

import { filterOptionsByValue, selectFilter } from './utils';
import OptionNotFound from './options/not-found';
import OptionLoading from './options/loading';
import DefaultValue from './values/default';

import ChevronIcon from 'assets/icons/chevron.svg';

const getMultiStyles = _.memoize(({ isSearchable, withTags }) => ({
  TextInput: StyleSheet({
    container: {
      // HACK: this is to get around specificity issues with the overrides
      '&&': {
        padding: '0',
        height: 'fit-content',
        minHeight: '36px'
      }
    },

    cosmeticWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: '0',
      paddingTop: '0',
      maxWidth: 'calc(100% - 24px)',
      minHeight: '30px'
    },

    input: {
      display: isSearchable ? 'flex' : 'none',
      flex: 1,
      order: 1,
      minWidth: '200px',
      padding: '3px',
      paddingLeft: '0.65rem',
      maxWidth: '100%'
    },

    cosmetic: {
      display: 'flex',
      order: 0,
      position: 'static',
      flexWrap: 'wrap',
      pointerEvents: withTags ? 'all' : 'none',
      padding: 0,
      overflow: 'visible',
      maxWidth: '100%'
    }
  })
}));

const getSingleStyles = _.memoize(({ withTags }) => ({
  TextInput: StyleSheet({
    container: {
      padding: 0
    },

    cosmeticWrapper: {
      padding: PADDINGS.XXS,
      maxWidth: '100%'
    },

    input: {
      maxHeight: '22px',
      padding: 0,
      paddingLeft: '0.25rem'
    },

    cosmetic: {
      padding: PADDINGS.XXS,
      pointerEvents: withTags ? 'all' : 'none',
      overflow: 'visible',
      maxWidth: '100%'
    }
  }),
  SelectValue: StyleSheet({
    container: {
      paddingLeft: '6px'
    }
  }),
  Tether: StyleSheet({
    container: {
      '& > div:first-child': {
        width: '100%',
        '& > div:first-child': {
          width: '100%'
        }
      }
    }
  })
}));

@autobind
class Select extends Component {
  static defaultProps = {
    debounce: false,
    filter: selectFilter,
    shouldClearOptionsOnSelect: false,
    isClearable: false,
    isSearchable: true,
    SelectInput: VividSelectInput,
    placeholder: null,
    DropdownIndicator: () => (
      <ChevronIcon
        style={{
          height: '24px',
          width: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      />
    ),
    OptionNotFound,
    OptionLoading,

    // Extra
    multi: false,
    onChange: () => {},
    onBlur: () => {},
    onSelect: () => {}
  };

  constructor(props) {
    super(props);

    let selected;
    let selectedSourceProp;
    if (props.value && props.options) {
      selected = filterOptionsByValue(
        props.options,
        props.value,
        props.valueAsObject
      );
      selectedSourceProp = 'value';
    } else {
      selected = props.selected;
      selectedSourceProp = 'selected';
    }

    // NOTE: why do we need this?!
    if (props.selected && props.value) {
      console.warn(
        'The "selected" and "value" prop were both provided to Select on startup. ' +
          `"${selectedSourceProp}" was chosen to manage initial selected options.`
      );
    }

    this.state = { selected };

    this.components = props.multi
      ? getMultiStyles(props)
      : getSingleStyles(props);
  }

  componentWillReceiveProps(nextProps) {
    const { value, options, valueAsObject, selected } = this.props;

    const changedValue = nextProps.value !== value;
    const changedOptions = nextProps.value && nextProps.options !== options;
    const changedSelected = nextProps.selected !== selected;

    if (changedValue || changedOptions) {
      // Note: Handling behaviour for redux-form to work.
      const selectedOptions = filterOptionsByValue(
        nextProps.options,
        nextProps.value,
        valueAsObject
      );
      this.setState({ selected: selectedOptions });
    } else if (changedSelected) {
      // Note: Developer wants to handle the options.
      // Note regarding note: then he should do it via the `value` prop!?
      this.setState({ selected: nextProps.selected });
    }
  }

  handleBlur() {
    const { value, onBlur, name } = this.props;
    if (onBlur) {
      onBlur({
        persist: () => null,
        target: {
          type: 'select',
          name,
          id: name,
          value
        }
      });
    }
  }

  handleSelect(selected) {
    const { onChange, onSelect, onBlur, name, multi, valueAsObject } =
      this.props;

    const fakeEvent = {
      persist: () => null,
      target: {
        type: 'select',
        name,
        id: name,
        value: selected
          ? multi
            ? selected.map((s) => (valueAsObject ? s : s.value))
            : valueAsObject
            ? selected
            : selected.value
          : multi
          ? []
          : ''
      }
    };

    if (onChange) {
      onChange(fakeEvent);
    } else {
      // Note: When redux-form isn't present, we manage the selected state manually.
      this.setState({ selected });
    }

    if (onSelect) {
      // Note: Developer may want to handle the options, so we put this handler last.
      onSelect(selected);
    }

    // Hack: onBlur needs to wait for the onChange to change the local
    // state of the form field, so it passes it up to the form correctly
    // The timeout ensures we run onBlur on the next tick, at which point
    // the value should be stored locally!
    !multi &&
      setTimeout(() => {
        window.document.activeElement?.blur?.();
        if (onBlur) {
          onBlur(fakeEvent);
        }
      });
  }

  renderValue(props) {
    const { Value = DefaultValue } = this.props;

    return (
      <Value
        {...this.props}
        {...props}
        selected={this.state.selected}
        handleSelect={this.handleSelect}
      />
    );
  }

  render() {
    const props = this.props;

    const { SelectInput } = props;

    const SelectProps = {
      Container: props.Container,
      options: props.options,
      selected: props.selected,
      multi: props.multi,
      // isLoading: props.isLoading,
      ignoreAccents: props.ignoreAccents,
      ignoreCase: props.ignoreCase,
      filter: props.filter,
      pluckLabel: props.pluckLabel,
      pluckValue: props.pluckValue,
      onSelect: props.onSelect
    };

    const InputProps = {
      styles: props.inputStyles,
      DropdownIndicator: props.DropdownIndicator,
      loadingIndicatorProps: props.loadingIndicatorProps,
      ClearIndicator: props.ClearIndicator,
      Value: props.Value,
      name: props.id || props.name,
      placeholder: props.placeholder,
      isClearable: props.isClearable,
      autoFocus: props.autoFocus,
      required: props.required,
      disabled: props.disabled,
      // NOTE: Disabled because we don't want forms handling them - we want Select handling them.
      // onBlur: props.onBlur,
      // onFocus: props.onFocus,
      // onChange: props.onChange,
      Container: 'div',
      onChange: props.onInputChange,
      onKeyDown: props.onKeyDown,
      autoBlur: props.autoBlur,
      isSearchable: props.isSearchable,
      shouldOpenOnFocus: props.shouldOpenOnFocus,
      shouldBackspaceRemove: props.shouldBackspaceRemove,
      shouldDeleteRemove: props.shouldDeleteRemove,
      shouldEscapeClearValue: props.shouldEscapeClearValue,
      shouldTabSelectValue: props.shouldTabSelectValue,
      shouldBlurResetInput: props.shouldBlurResetInput,
      shouldCloseResetInput: props.shouldCloseResetInput,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      shouldMenuOpen: props.shouldMenuOpen,
      onCleared: props.onCleared,
      readOnly: props.readOnly
    };

    // https://github.com/erikras/redux-form/blob/master/src/propTypes.js
    // Note: We don't want the other prop's, as out Select accounts for most cases.
    const FieldProps = {
      meta: props.meta
    };

    const MenuProps = {
      styles: props.menuStyles,
      Option: props.Option,
      OptionNotFound: props.isLoading
        ? props.OptionLoading
        : props.OptionNotFound,
      OptionSelected: props.OptionSelected,
      onOpen: props.onOpen,
      onClose: props.onClose,
      onScrollToBottom: props.onScrollToBottom,
      onOptionHover: props.onOptionHover,
      onOptionSelect: props.onOptionSelect,
      shouldCloseOnSelect: props.shouldCloseOnSelect,
      shouldSelectResetInput: props.shouldSelectResetInput
    };

    const options = props.isLoading
      ? []
      : props.fixtures
      ? props.options.concat(
          props.fixtures.map((fixture) => ({
            ...fixture,
            fixturePrefillData: props.fixturePrefillData,
            isFixture: true,
            value: fixture.value || fixture.label
          }))
        )
      : props.options;

    return (
      <StylesProvider components={this.components}>
        <VividSelect
          {...SelectProps}
          options={options}
          onSelect={this.handleSelect}
          selected={this.state.selected}
        >
          <SelectInput
            {...InputProps}
            {...FieldProps}
            data-lpignore='true'
            onBlur={this.handleBlur}
            Value={props.Value || props.multi ? this.renderValue : undefined}
          />
          <div style={{ position: 'static', width: '100%' }}>
            <div style={{ position: 'relative' }}>
              <VividSelectMenu {...MenuProps} />
            </div>
          </div>
        </VividSelect>
      </StylesProvider>
    );
  }
}

export default Select;
