import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS, PADDINGS } from 'theme';
import Dialog from 'view/components/dialog';
import { Body, SubHeading } from 'view/components/text';
import ICONS from 'shared/components/icon';

const Warning = ICONS.WARNING;

@styled(
  StyleSheet({
    heading: {
      paddingBottom: PADDINGS.XS
    },
    content: {
      paddingBottom: PADDINGS.XS
    },
    buttonBar: {
      marginTop: PADDINGS.M
    },
    icon: {
      color: COLORS.RED,
      marginRight: PADDINGS.S,
      width: '50px',
      height: '50px'
    }
  })
)
@autobind
class PafFatalErrorDialog extends PureComponent {
  render() {
    const { styles: s, ...rest } = this.props;
    return (
      <Dialog title='Fatal Error' top={200} {...rest}>
        <Box flexDirection='row'>
          <Warning {...s('icon')} color={'red'} />
          <Box>
            <SubHeading {...s('heading')}>
              A fatal error occurred during processing
            </SubHeading>
            <Body {...s('content')}>
              Please try uploading a different file. Alternatively you can
              contact the provider of your file to ensure there are no issues.
            </Body>
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default PafFatalErrorDialog;
