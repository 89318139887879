/* eslint-disable max-lines */

/*
|-------------------------------------------------------------------------------
| App "Theme" for Internal Components
|-------------------------------------------------------------------------------
|
| These theming configurations are suppose to be consumed by `element-style`s
| "StylesProvider". See https://git.io/vNyT4 for more details.
|
| They are kept in a separate module, so we can do fine-grained import-order for
| the App as well as for Storybook Stories.
|
*/

import { StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, SHADOWS } from './index';
import { TEXTS } from 'theme/index';

const ELEMENT_STYLE_COMPONENTS = {
  Form: StyleSheet({
    form: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  }),

  InnerField: StyleSheet({
    field: {
      marginTop: '20px'
    }
  }),

  TextInput: StyleSheet({
    container: {
      height: '36px',
      border: `1px solid ${COLORS.TABLE_BORDER.DARK}`,
      padding: `5px ${PADDINGS.XS}`,
      fontSize: '14px',

      '&:focus-within': {
        borderColor: COLORS.GREY_DARK
      }
    },

    containerValid: {
      border: `1px solid ${COLORS.TABLE_BORDER.DARK}`
    },

    containerFocused: {
      outline: '0 none',
      boxShadow: 'none'
    },

    containerError: {}
  }),

  TextArea: StyleSheet({
    container: {
      border: `1px solid ${COLORS.TABLE_BORDER.DARK}`,
      padding: PADDINGS.XS,

      '&:focus-within': {
        borderColor: COLORS.GREY_DARK
      }
    },

    containerValid: {
      border: `1px solid ${COLORS.TABLE_BORDER.DARK}`
    },

    containerFocused: {
      outline: '0 none',
      boxShadow: 'none'
    },

    containerError: {},

    input: {
      fontSize: `${TEXTS.BODY.fontSize} !important`,
      lineHeight: `${TEXTS.BODY.lineHeight} !important`,
      letterSpacing: TEXTS.BODY.letterSpacing
    }
  }),

  Checkbox: StyleSheet({
    input: {
      '& + label': {
        height: '16px',
        width: '16px',
        border: `2px solid ${COLORS.GREY_MEDIUM}`,
        borderRadius: '2px',
        backgroundColor: 'white',
        marginLeft: '0',
        marginRight: '4px',
        transitionDuration: '100ms',

        '&:before': {
          transitionDuration: '100ms'
        },

        '&:after': {
          width: '200%',
          height: '200%',
          left: '-50%',
          top: '-50%'
        }
      },

      '& + label:focus': {
        outline: 'none',
        borderColor: COLORS.BLUE
      },

      '&:checked + label': {
        background: COLORS.BLUE,
        borderColor: COLORS.BLUE,
        transitionDuration: '100ms',
        color: COLORS.WHITE,

        '&:before': {
          borderColor: COLORS.WHITE,
          height: '10px',
          transitionDuration: '100ms',
          width: '5px',
          borderWidth: '1.5px'
        }
      }
    }
  }),

  SelectInput: StyleSheet({
    indicators: {
      padding: '4px'
    }
  }),

  SelectMenu: StyleSheet({
    container: {
      border: '0 none',
      background: COLORS.WHITE,
      boxShadow: SHADOWS.SELECT,
      padding: '2px 0'
    }
  }),

  Button: StyleSheet({
    container: {
      '&:focus': {
        outline: 'none',
        boxShadow: 'inset 0 0 0 3px rgba(0, 0, 0, 0.07)'
      }
    }
  }),

  SelectOption: StyleSheet({
    container: {
      minHeight: '32px',
      padding: '4px 4px 4px 12px',
      borderTop: '1px solid transparent',
      borderBottom: '1px solid transparent',
      fontSize: '14px',
      color: COLORS.PRIMARY_DARK,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },

    isActive: {
      background: COLORS.BACKGROUND,
      color: COLORS.PRIMARY_DARK
    },

    isSelected: {
      background: COLORS.BACKGROUND,
      borderColor: COLORS.GREY_LIGHT,
      color: COLORS.PRIMARY_DARK
    }
  }),

  List: StyleSheet({
    separator: {
      background: COLORS.GREY_LIGHT
    }
  }),

  Tether: StyleSheet({
    wrapContent: {
      zIndex: 10
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      pointerEvents: 'none',

      '&:before': {
        content: '" "'
      }
    }
  }),

  ActionMenu: StyleSheet({
    menuItem: {
      minHeight: '32px',
      padding: '4px',
      borderTop: '1px solid transparent',
      borderBottom: '1px solid transparent',
      fontSize: '14px',
      color: COLORS.PRIMARY_DARK,
      fontWeight: 500,
      // HACK: This is set to important to fix a current bug with the styling.
      // The styles set in vivid are currently overriding these
      // There are two glamor style nodes in the head tag, and the vivid styles for the actionmenu are in the second one
      // We haven't figured out why it is doing this
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',

      '&:hover': {
        color: COLORS.GREY_DARK
      }
    }
  }),

  Tab: StyleSheet({
    container: {
      ...TEXTS.BODY,
      fontWeight: 600,
      textTransform: 'none',
      maxWidth: '180px',
      padding: '1.4rem',
      height: '5rem',
      flex: 'none',
      color: COLORS.GREY_DARK
    },
    containerActive: {
      color: COLORS.PRIMARY_DARK
    },
    containerDisabled: {
      color: COLORS.GREY
    }
  }),

  TabBar: StyleSheet({
    indicator: {
      color: COLORS.PRIMARY_DARK,
      height: '0.2rem'
    },
    list: {
      marginTop: PADDINGS.L,
      '&::after': {
        position: 'absolute',
        left: 0,
        display: 'block',
        width: '100%',
        right: 0,
        content: '""',
        height: '1px',
        marginTop: '4.9rem',
        zIndex: -1,
        background: COLORS.GREY_LIGHT
      }
    }
  }),

  // DATE SELECT STYLES
  CalendarMonth: StyleSheet({
    week: {
      transition: 'none',
      ' > td': {
        border: 'none',
        background: 'transparent'
      }
    },

    title: {
      borderRadius: '0px',
      padding: '7.5px 0 0 0',
      margin: '0px -22px 38px -22px',
      textTransform: 'uppercase',
      // width: '150px',
      color: 'white',
      backgroundColor: COLORS.BLUE,
      fontSize: '14px',
      height: '30px',
      position: 'relative',
      left: '-1px'
    },

    container: {
      padding: '0 !important'
    }
  }),

  CalendarMonthGrid: StyleSheet({
    horizontal: {
      left: '7px !important'
    }
  }),

  Branch: StyleSheet({
    selected: {
      backgroundColor: `${COLORS.BLUE} !important`,
      borderRadius: '50% !important',
      color: '#000000 !important'
    },

    day: {
      transition: 'none',
      fontSize: '14px',
      fontWeight: 300,

      ':hover': {
        backgroundColor: COLORS.BLUE,
        borderRadius: '50%',
        color: 'white'
      }
    },

    isOutsideDay: {
      color: '#999 !important'
    },

    hoveredSpan: {
      background: 'transparent'
    }
  }),

  DayPickerNavigation: StyleSheet({
    container: {
      height: '30px',
      ' > div': {
        borderRadius: '0px'
      },
      ' > button': {
        border: 'none',
        borderRadius: '50%',
        backgroundColor: COLORS.BLUE,
        color: COLORS.WHITE,
        padding: '0',
        transform: 'rotate(90deg)',
        ':active': {
          outline: 'none',
          borderColor: 'none',
          boxShadow: '0 0 5px dimgrey'
        }
      }
    },
    horizontal: {
      top: '0',
      padding: '0px 10px'
    }
  }),

  DayPicker: StyleSheet({
    headers: {
      zIndex: 301,
      position: 'relative',

      '> ul': {
        top: '30px',
        backgroundColor: COLORS.BACKGROUND,
        lineHeight: '30px',
        margin: '0',
        minWidth: '100%',
        color: COLORS.GREY_DARK,
        fontSize: '14px',
        fontWeight: 300
      }
    },

    region: {
      position: 'relative',
      zIndex: 300,
      background: 'white',

      ' > div:last-of-type > div': {
        // TODO: remove translateX animation here
      },

      '>div:last-of-type': {
        borderRadius: '0px'
      }
    }
  }),

  DateSelectInput: StyleSheet({
    wrapDateInput: {
      '>div>div>div': {
        boxShadow: '0 0 21px rgba(0,0,0,.17)',
        borderRadius: '0'
      }
    }
  })
};

export default ELEMENT_STYLE_COMPONENTS;
