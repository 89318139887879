import React, { Fragment, PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import types from 'prop-types';
import Box from '@rexlabs/box';
import { PrimaryButton, TextButton } from 'shared/components/button';

import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import Spinner from 'shared/components/spinner';

const defaultStyles = StyleSheet({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    color: 'white',
    right: 0,
    width: '100%',
    zIndex: 10,
    height: '70px',
    background: 'rgba(0,0,0,0.7)'
  }
});

@styled(defaultStyles)
@connect()
@autobind
class SaveCancelOverlay extends PureComponent {
  static propTypes = {
    onCancel: types.func,
    onSave: types.func,
    visible: types.any,
    isLoading: types.any
  };

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate() {
    if (this.props.visible) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  render() {
    const {
      styles: s,
      onCancel,
      onSave,
      visible,
      isLoading,
      ...props
    } = this.props;

    if (!visible) return null;

    return (
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='center'
        {...s('container')}
        {...props}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Box mr={'20px'}>
              <TextButton white onClick={onCancel}>
                Cancel
              </TextButton>
            </Box>
            <PrimaryButton
              style={{ alignSelf: 'inital' }}
              blue
              onClick={onSave}
            >
              Save Changes
            </PrimaryButton>
          </Fragment>
        )}
      </Box>
    );
  }
}

export default SaveCancelOverlay;
