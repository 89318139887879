import React from 'react';

import Dialog from 'src/view/components/dialog';
import { Body } from 'src/view/components/text';

export function ErrorDialog({ message, ...props }) {
  return (
    <Dialog title='Error' width={400} height={400} {...props}>
      <Body>{message || 'Something went wrong!'}</Body>
    </Dialog>
  );
}
