/*
|-------------------------------------------------------------------------------
| App Routes
|-------------------------------------------------------------------------------
|
| The main routes for the App should be centralised, configured & exposed here.
|
| Documentation for configuring routes can be found in the `react-whereabouts`
| readme (https://git.io/vNyTN).
|
*/

export default {
  HOME: {
    config: {
      path: '/',
      exact: true,
      redirect: {
        path: '/default'
      }
    }
  },
  DEFAULT: {
    config: {
      path: '/default',
      Component: require('view/screens/default').default
    }
  },

  ANNOUNCEMENTS: {
    config: {
      path: '/announcements',
      Component: require('view/screens/announcements/list').default
    },

    EDIT: {
      config: {
        path: '/announcements/:announcementId/edit',
        Component: require('view/dialogs/announcements/details').default
      }
    },

    DELETE: {
      config: {
        path: '/announcements/:announcementId/delete',
        Component: require('view/dialogs/announcements/delete').default
      }
    },

    CREATE: {
      config: {
        path: '/announcements/new',
        Component: require('view/dialogs/announcements/details').default
      }
    }
  },
  SEARCH: {
    config: {
      path: '/search',
      Component: require('view/screens/search').default
    }
  },
  USERS: {
    config: {
      path: '/users',
      Component: require('view/screens/users/list').default
    },

    DISABLE: {
      config: {
        path: '/users/:userId/disable',
        Component: require('view/dialogs/users/disable').default
      }
    },
    DELETE: {
      config: {
        path: '/users/:userId/delete',
        Component: require('view/dialogs/users/delete').default
      }
    },
    RESET_PASSWORD: {
      config: {
        path: '/users/:userId/reset-password',
        Component: require('view/dialogs/users/reset-password').default
      }
    },
    INVITE: {
      config: {
        path: '/users/invite',
        Component: require('view/dialogs/users/invite').default
      }
    },
    ASSIGN_OFFICE_GROUP: {
      config: {
        path: '/users/assign-office-group',
        Component: require('view/dialogs/users/assign-office-group').default
      }
    }
  },
  USERS_EDIT: {
    config: {
      path: '/user/:userId',
      Component: require('view/screens/users/details').default
    },

    DISABLE: {
      config: {
        path: '/user/:userId/disable',
        Component: require('view/dialogs/users/disable').default
      }
    },
    DELETE: {
      config: {
        path: '/user/:userId/delete',
        Component: require('view/dialogs/users/delete').default
      }
    },
    RESET_PASSWORD: {
      config: {
        path: '/user/:userId/reset-password',
        Component: require('view/dialogs/users/reset-password').default
      }
    },
    ADD_PRIVILEGE_SETS: {
      config: {
        path: '/user/:userId/add-privilege-sets/:accountGroupUserId',
        Component: require('view/dialogs/users/add-privilege-sets').default
      }
    },
    ASSIGN_OFFICE_GROUPS: {
      config: {
        path: '/user/:userId/assign-office-group',
        Component: require('view/dialogs/users/assign-office-group').default
      }
    }
  },
  OFFICE_GROUPS: {
    config: {
      path: '/office-groups',
      Component: require('view/screens/office-groups/list').default
    },

    CREATE: {
      config: {
        path: '/office-groups/create',
        Component: require('view/dialogs/office-groups/details').default
      }
    },

    EDIT: {
      config: {
        path: '/office-groups/edit/:groupId',
        Component: require('view/dialogs/office-groups/details').default
      }
    }
  },
  REPORTS: {
    config: {
      path: '/reports',
      Component: require('view/screens/reports/list').default
    },
    GENERATE: {
      config: {
        path: '/reports/:reportId/:viewId',
        Component: require('view/dialogs/reports/details').default
      }
    }
  },
  ADMIN: {
    config: {
      path: '/admin',
      Component: require('view/screens/admin/details').default
    }
  },
  BILLING: {
    config: {
      path: '/billing',
      Component: require('view/screens/billing/details').default
    }
  }
};
