import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'systemValues';

const actionCreators = {
  fetchList: {
    request: ({ listName }) =>
      api.post('SystemValues::getCategoryValues', {
        list_name: listName
      }),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator(TYPE).createModel({ actionCreators });
