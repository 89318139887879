/*
|-------------------------------------------------------------------------------
| App Styling Constants
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/

import { insertCss, reset, keyframes } from '@rexlabs/styling';

export const COLORS = {
  PRIMARY_DARK: '#26394D',
  BACKGROUND: '#F7FAFC',
  SAND: '#E6E5E3',
  WHITE: '#FFF',
  WHITE_TRANSLUCENT: 'rgba(255,255,255,0.2)',

  BLUE: '#1EA1E6',
  RED: '#EC5858',
  PINK: '#FDEEEE',
  YELLOW: '#F3D200',
  PALE_YELLOW: '#FFFAD0',

  NOTICE: '#f7efac',

  GREY: '#979797',
  GREY_LIGHTEST: '#f7f8f9',
  GREY_LIGHT: '#E7EDF4',
  GREY_MEDIUM: '#B6BFC9',
  GREY_DARK: '#858FA4',
  GREY_DARKEST: '#424242',

  TABLE_BORDER: {
    LIGHT: '#D8D8D8',
    DARK: '#CAD2DF'
  }
};

export const PADDINGS = {
  XXS: '5px',
  XS: '10px',
  S: '15px',
  M: '20px',
  L: '25px',
  XL: '30px',
  XXL: '35px'
};

export const FONT = {
  FAMILY: {
    PROXIMA_NOVA: 'proxima-nova'
  }
};

export const BORDERS = {};

export const EASINGS = {};

export const SHADOWS = {
  DIALOG: '0 7px 15px rgba(133, 143, 164, .5)',
  SELECT: '0 7px 15px rgba(182, 191, 201, .5)'
};

export const TEXTS = {
  HEADING: {
    fontSize: '36px',
    lineHeight: '40px',
    marginTop: '5px',
    letterSpacing: '-.8px',
    fontWeight: 600
  },

  SUB_HEADING: {
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-.2px',
    fontWeight: 600,
    marginBottom: PADDINGS.XXS
  },

  BODY_LARGE: {
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '-.2px',
    fontWeight: 600
  },
  FILTER_TAG: {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 600,
    color: COLORS.WHITE
  },

  LABEL: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 900,
    marginBottom: '4px',
    display: 'block'
  },

  BODY: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    letterSpacing: '-.2px',
    color: COLORS.PRIMARY_DARK
  },

  BODY_SMALL: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-.1px',
    fontWeight: 500
  },

  GREY: {
    color: COLORS.GREY_DARK
  },

  BLUE: {
    color: COLORS.BLUE
  },

  MEDIUM: {
    fontWeight: 500
  },

  BOLD: {
    fontWeight: 700
  },

  UNDERLINED: {
    textDecoration: 'underline'
  },
  CLICKABLE: {
    cursor: 'pointer'
  },

  NOT_FOUND: {
    fontSize: '14px',
    lineHeight: '18px',
    marginTop: '4px',
    marginBottom: '4px',
    color: COLORS.PRIMARY_DARK
  }
};

export const UTILS = {
  TRUNCATE: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export const KEYFRAMES = {
  FADE_IN: keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }),

  FADE_OUT: keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 }
  }),

  DIALOG_FADE_IN: keyframes({
    '0%': { opacity: 0, transform: 'scale(.9)' },
    '100%': { opacity: 1, transform: 'scale(1)' }
  }),

  DIALOG_FADE_OUT: keyframes({
    '0%': { opacity: 1, transform: 'scale(1)' },
    '100%': { opacity: 0, transform: 'scale(.9)' }
  })
};

export function initTheme() {
  reset();
  insertCss(`
    html {
      overflow-x: hidden;
      min-width: 1024px;
      font-size: 62.5% !important;
      height: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
    }
    
    *, *:before, *:after {
      box-sizing: inherit;
    }

    body {
      color: ${COLORS.PRIMARY_DARK};
      font-family: 'proxima-nova', 'Proxima Nova', Monserrat, 'Open Sans', sans-serif;
      overflow: hidden;
    }
  `);
}
