/* eslint-disable max-lines */

import React, { Fragment, PureComponent } from 'react';
import { Body, Heading } from 'view/components/text';
import Box from '@rexlabs/box';
import { PrimaryButton } from 'shared/components/button';
import ROUTES from 'src/routes';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import accountUsersModel from 'data/models/entities/account-users';
import _ from 'lodash';
import { Tabs } from '@rexlabs/tabs';
import UserOverview from 'view/screens/users/overview';
import UserRexPrivileges from 'view/screens/users/rex-privileges';
import UserGroupPrivileges from 'view/screens/users/group-privileges';
import Spinner from 'view/components/spinner';
import { ReactForms } from '@rexlabs/form';
import { createValidationRules } from 'shared/utils/form';
import RecordTopBar from 'shared/components/record-top-bar';
import { Link, push } from '@rexlabs/whereabouts';
import SaveCancelOverlay from 'shared/components/save-cancel-overlay';
import { autobind } from 'core-decorators';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { COLORS, PADDINGS, TEXTS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import SecondaryButton from 'shared/components/button/secondary';
import { styled, StyleSheet } from '@rexlabs/styling';

import NoticeBar from 'view/components/notice-bar';
import dayjs from 'dayjs';
import accountGroupUsersModel from 'data/models/entities/account-groups-users';
import Screen from 'view/components/screen';
import privilegeGroupsModel from 'data/models/custom/privilege-groups';
import sessionModel from 'data/models/custom/session';
import ChevronIcon from 'assets/icons/chevron.svg';
import ActionMenu from '@rexlabs/action-menu';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { subAccountAccessValues } from './sub-account-access';
import customFields from 'data/models/custom/custom-fields';
import RecordFieldGrid from 'view/components/record-field-grid';
import FormField from 'view/components/form/field';
import { Column } from 'shared/components/grid';
import { getCustomFieldInput, toArray } from './utils';

const userQuery = query`{
  ${accountUsersModel} (id: ${(p) => _.get(p, 'match.params.userId')}) {
    id
    first_name
    email
    last_name
    reporting_categories
    system_created_user
    system_ctime
    user_account_status
    user_links
    user_registration_status
  }
}`;

const defaultStyles = StyleSheet({
  pageNumber: {
    paddingRight: PADDINGS.S
  },
  chevronLeft: {
    transform: 'rotate(90deg)'
  },
  chevronRight: {
    transform: 'rotate(-90deg)'
  },
  disabledTag: {
    borderRadius: '6px',
    backgroundColor: COLORS.GREY_LIGHT,
    padding: `${PADDINGS.XXS} ${PADDINGS.S}`,
    margin: `${PADDINGS.XXS} 0 0 ${PADDINGS.S}`,
    ...TEXTS.BODY_SMALL,
    display: 'inline'
  }
});

function getCriteria(props) {
  return _.get(props, 'match.params.userId')
    ? [
        {
          name: 'user.id',
          type: 'in',
          value: [_.get(props, 'match.params.userId')]
        }
      ]
    : [];
}

const getExtraFields = () => ({
  fields: ['related.user_account_group_privilege_sets']
});

export const accountGroupUsersQuery = query`{
  ${accountGroupUsersModel} (criteria: ${getCriteria}, extra_options: ${getExtraFields}) {
    id
    user
    account_group
    related
  }
}`;

@withQuery(accountGroupUsersQuery)
@styled(defaultStyles)
@withQuery(userQuery)
@withModel(privilegeGroupsModel)
@withModel(sessionModel)
@withModel(customFields)
@withErrorDialog
@autobind
class UsersEditScreen extends PureComponent {
  state = {
    activeTab: 'overview',
    privileges: null,
    customTabs: [],
    systemTabGroups: [],
    customFieldValues: null
  };

  componentDidMount() {
    const { match, errorDialog } = this.props;
    const userId = _.get(match, 'params.userId');
    this.props.privilegeGroups.fetchList();
    this.props.customFields.getDefinition().then((response) => {
      const systemTabGroups = _.get(
        response,
        'data.result.groups_on_core_tabs'
      );
      this.setState(
        {
          customTabs: _.get(response, 'data.result.tabs'),
          systemTabGroups
        },
        () => {
          this.props.customFields
            .getRecordData({
              service_name: 'AccountUsers',
              service_object_id: userId
            })
            .then((response) => {
              const fieldValues = response.data.result;
              this.setState({
                customFieldValues: Object.keys(fieldValues).reduce(
                  (result, currentKey) => {
                    return {
                      ...result,
                      [`custom-field-${currentKey}`]: fieldValues[currentKey]
                    };
                  },
                  {}
                )
              });
            });
        }
      );
    });
    this.props.accountUsers
      .getPrivilegesForUser({
        id: userId
      })
      .then((response) =>
        this.setState({ privileges: response.data.extra_privileges })
      )
      .catch(errorDialog.open);
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'match.params.userId') !==
      _.get(this.props, 'match.params.userId')
    ) {
      this.setState({
        privileges: null
      });
      this.props.accountUsers
        .getPrivilegesForUser({
          id: _.get(this.props.match, 'params.userId')
        })
        .then((response) =>
          this.setState({ privileges: response.data.extra_privileges })
        )
        .catch(this.props.errorDialog.open);
    }
  }

  getSubAccountPrivilegeId() {
    const { privilegeGroups } = this.props;
    const subAccountPrivilege = _.get(
      privilegeGroups,
      'items.base.privileges'
    ).find(({ privilege }) => privilege === 'login_to_sub_accounts');
    return _.get(subAccountPrivilege, 'id');
  }

  handleSubmit(data, { resetForm }) {
    const { accountUsers, accountGroupUsers, errorDialog, customFields } =
      this.props;
    const { customTabs, systemTabGroups } = this.state;

    let privileges = _.get(data, 'group_privileges', []);
    let shouldDeletePrivilegeSets = false;

    const subAccountAccess = _.get(data, 'sub_account_access');
    const subAccountPrivilegeId = this.getSubAccountPrivilegeId();
    if (subAccountAccess === subAccountAccessValues.super) {
      privileges = [...privileges, subAccountPrivilegeId];
      shouldDeletePrivilegeSets = true;
    } else {
      privileges = privileges.filter((id) => id !== subAccountPrivilegeId);
      if (subAccountAccess === subAccountAccessValues.none) {
        // The differentiator between whether the user can log in to a specific
        // sub account with specific privileges or whether the user cannot log
        // in to a sub account at all is whether or not there are privileges
        // (that is, privilege sets). So when the sub account access is set to
        // none, the privilege sets need to be deleted.
        shouldDeletePrivilegeSets = true;
      }
    }

    const subAccountPrivilegeSetDeletions = [];
    if (shouldDeletePrivilegeSets) {
      const groups = _.get(accountGroupUsers, 'list.items', []);
      groups.forEach(({ id, related }) => {
        const deletions = related.user_account_group_privilege_sets.map(
          ({ id }) => ({ destroy: 1, id })
        );
        if (deletions.length > 0) {
          subAccountPrivilegeSetDeletions.push(
            accountGroupUsers.updateItem({
              id,
              data: {
                related: {
                  user_account_group_privilege_sets: deletions
                }
              }
            })
          );
        }
      });
    }

    /**
     * Handles collecting the custom field values and putting them into an object with the name valueMap.
     *
     * E.g.
     *
     * {
     *    valueMap: {
     *        1: 'Hello',
     *        2: 'World'
     *    },
     *    firstName: 'Martin',
     *    lastName: 'Wheeler',
     *    email: 'martin.wheeler@rexsoftware.com.au'
     * }
     */
    const { valueMap = {}, ...recordData } = Object.keys(data).reduce(
      (result, currentKey) => {
        if (currentKey.includes('custom-field-')) {
          const newKey = currentKey.replace('custom-field-', '');

          return {
            ...result,
            valueMap: {
              ...result.valueMap,
              [newKey]: data[currentKey]
            }
          };
        }

        return {
          ...result,
          [currentKey]: data[currentKey]
        };
      },
      {}
    );

    const actions = [
      ...subAccountPrivilegeSetDeletions,
      accountUsers.setPrivilegesForUser({
        id: accountUsers.item.data.id,
        extra_privilege_ids: privileges // eslint-disable-line
      }),
      accountUsers.updateItem({
        data: _.omit(recordData, ['can_access_group_app', 'group_privileges'])
      })
    ];

    // NOTE: We only set the custom field values if there are some defined
    if (customTabs.length > 0 || systemTabGroups.length > 0) {
      actions.push(
        customFields.setValues({
          service_name: 'AccountUsers',
          service_object_id: _.get(accountUsers, 'item.data.id'),
          value_map: valueMap
        })
      );
    }

    return Promise.all(actions)
      .then(() => {
        this.setState({ privileges });
        resetForm(data);
      })
      .catch(errorDialog.open);
  }

  isReallyDirty({ initialValues, values, touched, isDirty }) {
    const { accountGroupUsers } = this.props;
    const touchedKeys = Object.keys(touched).filter((key) => touched[key]);
    if (touchedKeys.length !== 1 || touchedKeys[0] !== 'sub_account_access') {
      return isDirty;
    }
    const privilegeSetCount = _.get(accountGroupUsers, 'list.items', []).reduce(
      (acc, { related }) =>
        acc + related.user_account_group_privilege_sets.length,
      0
    );
    switch (values.sub_account_access) {
      case subAccountAccessValues.none:
        return (
          initialValues.sub_account_access === subAccountAccessValues.super ||
          privilegeSetCount !== 0
        );
      case subAccountAccessValues.privileges:
        return (
          initialValues.sub_account_access === subAccountAccessValues.super
        );
      default:
        return values.sub_account_access !== initialValues.sub_account_access;
    }
  }

  /**
   * Renders the custom tabs that have been defined by the user.
   */
  renderCustomTabs() {
    const { customTabs } = this.state;

    return customTabs
      .filter((tab) =>
        tab.groups.some((group) => toArray(group.fields).length > 0)
      )
      .map((tab) => ({
        name: tab.label.toLowerCase(),
        label: tab.label,
        Tab: () =>
          tab.groups.map((group) => (
            <RecordFieldGrid key={group.id} heading={group.label}>
              {toArray(group.fields).map((field) => (
                <Column p={PADDINGS.XS} key={field.id} width={6}>
                  <FormField
                    name={`custom-field-${field.id}`}
                    label={field.label}
                    {...getCustomFieldInput(field)}
                  />
                </Column>
              ))}
            </RecordFieldGrid>
          ))
      }));
  }

  renderGroupPrivilegeTab(props) {
    return (
      <UserGroupPrivileges
        {...this.props}
        {...props}
        privileges={this.state.privileges}
        activeTab={this.state.activeTab}
      />
    );
  }

  renderDetailsTab(props) {
    return (
      <UserOverview
        {...this.props}
        {...props}
        activeTab={this.state.activeTab}
        customGroups={this.state.systemTabGroups}
      />
    );
  }

  renderPrivilegeTab(props) {
    const { values } = this;
    return (
      <UserRexPrivileges
        {...this.props}
        {...props}
        activeTab={this.state.activeTab}
        values={values}
      />
    );
  }

  render() {
    const {
      accountUsers: {
        item,
        lastSearch,
        item: { data }
      },
      session,
      accountGroupUsers,
      privilegeGroups,
      accountUsers,
      errorDialog,
      styles: s
    } = this.props;
    const { privileges, customFieldValues, customTabs, systemTabGroups } =
      this.state;
    // NOTE: We only want to check if custom tabs are loading when we have some to show
    const customTabsLoading =
      customTabs.length || systemTabGroups.length ? !customFieldValues : false;

    const loading =
      !privileges ||
      item.status === 'loading' ||
      accountGroupUsers.list.status === 'loading' ||
      privilegeGroups.status === 'loading' ||
      customTabsLoading;
    if (loading) {
      return (
        <Box justifyContent='center' alignItems='center'>
          <Spinner small dark />
        </Box>
      );
    }

    let userIndexInLastSearch = null;
    let previousUserId = null;
    let nextUserId = null;
    if (_.get(lastSearch, 'data.length') > 1) {
      userIndexInLastSearch = lastSearch.data.findIndex((user) => {
        return _.get(item, 'data.id') === user.id;
      });
      previousUserId = _.get(lastSearch.data[userIndexInLastSearch - 1], 'id');
      nextUserId = _.get(lastSearch.data[userIndexInLastSearch + 1], 'id');
    }

    const canManage = session.checkUserHasPermission('users.manage');
    const canDisable = session.checkUserHasPermission('users.disable');
    const canDelete = session.checkUserHasPermission('users.delete');
    const canAssign = session.checkUserHasPermission(
      'users.assign_to_office_group'
    );
    const canManageRexPrivileges = session.checkUserHasPermission(
      'users.manage_rex_privileges'
    );
    const canManageRexGroupPrivileges = session.checkUserHasPermission(
      'users.manage_rexgroup_privileges'
    );

    // Assume that the user has the sub account access privilege. If they
    // don't, whether or not they can log in to sub accounts depends upon
    // whether or not there are any privilege sets.

    let subAccountAccess = subAccountAccessValues.super;
    const subAccountPrivilegeId = this.getSubAccountPrivilegeId();
    if (!privileges.includes(subAccountPrivilegeId)) {
      const privilegeGroupCount = _.get(
        accountGroupUsers,
        'list.items',
        []
      ).reduce(
        (acc, item) =>
          acc +
          _.get(item, 'related.user_account_group_privilege_sets.length', 0),
        0
      );
      subAccountAccess =
        privilegeGroupCount === 0
          ? subAccountAccessValues.none
          : subAccountAccessValues.privileges;
    }

    const initialValues = {
      ...data,
      group_privileges: privileges,
      sub_account_access: subAccountAccess,
      ...customFieldValues
    };
    // NOTE: This is defined here as calling the function directly inside the items array caused an infinite loop of rendering.
    const customTabItems = this.renderCustomTabs();

    return (
      <Screen title={`${data.first_name} ${data.last_name}`} key={data.id}>
        {data.user_account_status === 'invited' && (
          <NoticeBar
            heading={'Invitation Not Accepted'}
            mainText={`${data.first_name} cannot access Group App or any sub accounts until they accept their invitation.`}
            secondaryText={
              data.system_created_user &&
              data.system_ctime &&
              `Added by ${data.system_created_user.text} on ${dayjs
                .unix(data.system_ctime)
                .format('D MMM YYYY')}`
            }
          />
        )}
        <Body grey>Users</Body>
        <Box alignItems='center'>
          <Heading>
            {data.first_name} {data.last_name}
          </Heading>
          {data.user_account_status === 'disabled' && (
            <span {...s('disabledTag')}>Disabled</span>
          )}
        </Box>
        <Box flexDireciton={'row'} justifyContent={'space-between'}>
          <RecordTopBar spacing={PADDINGS.XS} backTo={ROUTES.USERS}>
            {canManage && (
              <PrimaryButton
                onClick={() =>
                  push(ROUTES.USERS_EDIT.RESET_PASSWORD, {
                    params: { userId: data.id }
                  })
                }
              >
                Reset Password
              </PrimaryButton>
            )}
            {(canDisable || canDelete) && (
              <ActionMenu
                distance={'4px'}
                placement={PLACEMENTS.BOTTOM_END}
                Button={() => (
                  <PrimaryButton IconRight={ChevronIcon}>More</PrimaryButton>
                )}
                items={[
                  ...(canDisable
                    ? [
                        data.user_account_status === 'disabled'
                          ? {
                              label: 'Enable user',
                              onClick: () =>
                                accountUsers
                                  .enable({ user_id: data.id })
                                  .catch(errorDialog.open)
                            }
                          : {
                              label: 'Disable user',
                              onClick: () =>
                                push(ROUTES.USERS_EDIT.DISABLE, {
                                  params: { userId: data.id }
                                })
                            }
                      ]
                    : []),
                  ...(canDelete
                    ? [
                        {
                          label: 'Delete user',
                          onClick: () =>
                            push(ROUTES.USERS_EDIT.DELETE, {
                              params: { userId: data.id }
                            })
                        }
                      ]
                    : [])
                ]}
              />
            )}
          </RecordTopBar>
          {lastSearch && (
            <Box mt={PADDINGS.XL} flexDirection={'row'} alignItems={'center'}>
              <Body {...s('pageNumber')}>
                {userIndexInLastSearch + 1} of{' '}
                {_.get(lastSearch, 'data.length')}
              </Body>
              {previousUserId ? (
                <Link
                  to={ROUTES.USERS_EDIT}
                  params={{ userId: previousUserId }}
                >
                  {({ onClick }) => (
                    <SecondaryButton onClick={onClick}>
                      <Icon {...s('chevronLeft')} type={ICONS.CHEVRON} />
                    </SecondaryButton>
                  )}
                </Link>
              ) : (
                <SecondaryButton disabled>
                  <Icon {...s('chevronLeft')} type={ICONS.CHEVRON} />
                </SecondaryButton>
              )}

              {nextUserId ? (
                <Link to={ROUTES.USERS_EDIT} params={{ userId: nextUserId }}>
                  {({ onClick }) => (
                    <SecondaryButton onClick={onClick}>
                      <Icon {...s('chevronRight')} type={ICONS.CHEVRON} />
                    </SecondaryButton>
                  )}
                </Link>
              ) : (
                <SecondaryButton disabled>
                  <Icon {...s('chevronRight')} type={ICONS.CHEVRON} />
                </SecondaryButton>
              )}
            </Box>
          )}
        </Box>

        {!!privileges && (
          <ReactForms
            asyncValuesReady={item.status === 'loading'}
            initialValues={initialValues}
            shouldUnregister={false}
            handleSubmit={this.handleSubmit}
            validate={createValidationRules({
              first_name: 'required',
              last_name: 'required',
              email: 'required'
            })}
          >
            {({
              submitForm,
              isSubmitting,
              resetForm,
              isDirty,
              values,
              touched
            }) => {
              this.values = values;
              return (
                <Fragment>
                  <Tabs
                    activeTab={this.state.activeTab}
                    onChange={(tab) => {
                      this.setState({ activeTab: tab.name });
                    }}
                    key={data.id}
                    alwaysRenderAll
                    accountGroupUsers={
                      accountGroupUsers.list.status + privilegeGroups.status
                    } // TODO hack to make tabs rerender
                    items={[
                      {
                        name: 'overview',
                        label: 'Overview',
                        Tab: this.renderDetailsTab
                      },
                      ...(canAssign || canManageRexPrivileges
                        ? [
                            {
                              name: 'rexPrivileges',
                              label: 'Rex Account Privileges',
                              Tab: this.renderPrivilegeTab
                            }
                          ]
                        : []),
                      ...(canManageRexGroupPrivileges
                        ? [
                            {
                              name: 'groupPrivileges',
                              label: 'Group App Privileges',
                              Tab: this.renderGroupPrivilegeTab
                            }
                          ]
                        : []),
                      ...customTabItems
                    ]}
                  />
                  <SaveCancelOverlay
                    onCancel={resetForm}
                    onSave={() => submitForm()}
                    visible={this.isReallyDirty({
                      initialValues,
                      values,
                      touched,
                      isDirty
                    })}
                    isLoading={isSubmitting}
                  />
                </Fragment>
              );
            }}
          </ReactForms>
        )}
      </Screen>
    );
  }
}

export default UsersEditScreen;
