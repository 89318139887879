import React, { PureComponent } from 'react';

import { styled, StyleSheet } from '@rexlabs/styling';
import {
  ReactForms,
  withForm,
  Form as RexlabsForm,
  FieldArray
} from '@rexlabs/form';
import HiddenField from './hidden-field';
import { COLORS, PADDINGS, TEXTS } from 'src/theme';

import FormField from './field';

const defaultStyles = StyleSheet({
  errorList: {
    listStyle: 'none',
    paddingLeft: '14px',
    paddingTop: PADDINGS.S,
    paddingBottom: PADDINGS.S,
    backgroundColor: COLORS.PINK,
    width: '100%',
    position: 'relative',

    '> li': {
      position: 'relative',
      ...TEXTS.BODY_SMALL
    },

    '> li:before': {
      content: '" "',
      position: 'absolute',
      top: '7px',
      left: '-10px',
      height: '4px',
      width: '4px',
      borderRadius: '4px',
      background: COLORS.BLACK
    }
  }
});

@styled(defaultStyles)
class Form extends PureComponent {
  static defaultProps = {
    showErrors: true
  };

  render() {
    const {
      styles: s,
      name,
      showErrors,

      style,
      ...props
    } = this.props;

    return (
      <RexlabsForm
        style={{ width: '100%', ...style }}
        FormError={({ errors, touched }) => {
          const actualErrors = Object.keys(errors)
            .filter((key) => touched && errors[key] && touched[key])
            .map((key) => errors[key]);

          if (!showErrors || !actualErrors.length) {
            return null;
          }

          return (
            <ul {...s('errorList')}>
              {actualErrors.map((message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          );
        }}
        id={name}
        name={name}
        autocomplete='off'
        {...props}
      />
    );
  }
}

export { ReactForms, withForm, Form, FormField, FieldArray, HiddenField };
