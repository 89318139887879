import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { autobind } from 'core-decorators';
import { COLORS, PADDINGS } from 'theme';
import { Body } from 'view/components/text';
import Icon, { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  container: {},
  collapsed: {
    height: '56px',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    paddingLeft: PADDINGS.M,
    paddingRight: PADDINGS.M,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginBottom: '5px',
    backgroundColor: '#F7FAFC',
    border: `1px solid ${COLORS.GREY_LIGHT}`,
    borderRadius: '4px'
  },

  chevron: {
    transition: 'transform 200ms ease-out'
  },
  chevronOpen: {
    transform: 'rotateX(180deg)'
  }
});

@styled(defaultStyles)
@autobind
export default class Accordion extends PureComponent {
  state = {
    isOpen: !!this.props.isOpenInitially
  };

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { styles: s, label, children, subLabel, ...rest } = this.props;
    const { isOpen } = this.state;
    return (
      <div {...s('container')} {...rest}>
        <Box
          flexDirection={'row'}
          justifyContent={'space-between'}
          {...s('collapsed')}
          onClick={this.toggleOpen}
        >
          <Body>{label}</Body>
          {subLabel && (
            <Box ml={'auto'} mr={PADDINGS.M}>
              <Body grey>{subLabel}</Body>
            </Box>
          )}
          <Icon
            {...s('chevron', { chevronOpen: isOpen })}
            type={ICONS.CHEVRON}
          />
        </Box>
        {isOpen && <div {...s('children')}>{children}</div>}
      </div>
    );
  }
}
