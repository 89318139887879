import { StyleSheet, useStyles } from '@rexlabs/styling';
import React from 'react';
import Tooltip, { PLACEMENTS, TooltipStateful } from '@rexlabs/tooltip';
import { Body } from 'view/components/text';
import { TEXTS } from 'src/theme';
import HelpTooltip from 'view/components/help-tooltip';

const styles = StyleSheet({
  container: {
    width: '20%'
  },
  label: {
    ...TEXTS.BODY
  },
  value: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '20px'
  }
});

export default function Stat({ label, value, helpText }) {
  const s = useStyles(styles);
  return (
    <dl {...s('container')}>
      <dt {...s('label')}>
        {label}
        {helpText ? <HelpTooltip text={helpText} /> : null}
      </dt>
      <dt {...s('value')}>{value}</dt>
    </dl>
  );
}
