import Box from '@rexlabs/box';
import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    width: '36px',
    height: '36px',
    backgroundColor: 'rgba(0,3,6,0.1)',
    position: 'relative',
    margin: '0 5px',
    cursor: 'pointer',

    '& svg': {
      color: 'white'
    }
  }
});

@styled(defaultStyles)
class Circle extends PureComponent {
  render() {
    const { styles: s, render, ...props } = this.props;
    return <Box {...s.with('container')(props)}>{render()}</Box>;
  }
}

export default Circle;
