import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import List from '@rexlabs/list';
import Box from '@rexlabs/box';
import { Link } from '@rexlabs/whereabouts';
import _ from 'lodash';
import EmptyList from 'view/components/empty-list';
import Spinner from 'view/components/spinner';
import { StyleSheet, styled } from '@rexlabs/styling';
import { PADDINGS } from 'auth-app/src/theme';
import PrimaryButton from 'shared/components/button/primary';
import Checkbox from '@rexlabs/checkbox';
import Icon, { ICONS } from 'shared/components/icon';
import BulkSelect from 'view/components/bulk-select';
import ChevronIcon from 'assets/icons/chevron.svg';
import ActionMenu from '@rexlabs/action-menu';
import { PLACEMENTS } from '@rexlabs/tooltip';
import ListFilters from 'view/components/list/filters';
import filtersModel from 'data/models/custom/filters';
import types from 'prop-types';
import Pagination from 'view/components/pagination';

const defaultStyles = StyleSheet({
  truncated: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

@withModel(filtersModel)
@autobind
export default class FilterableList extends PureComponent {
  static propTypes = {
    entityName: types.string.isRequired,
    items: types.array,
    renderItem: types.func.isRequired,
    renderHeader: types.func.isRequired,
    model: types.object.isRequired,
    bulkActions: types.array.isRequired,
    standardActions: types.array.isRequired,
    getCriteria: types.func.isRequired,
    filterConfig: types.array,
    selectedIds: types.array,
    addLink: types.object.isRequired
  };

  static defaultProps = {
    standardActions: [],
    bulkActions: []
  };

  renderFilters(deselectAll) {
    const {
      filters,
      entityName,
      filterConfig,
      model,
      onFilterClear,
      getCriteria
    } = this.props;

    return (
      <ListFilters
        placeholder={'Search'}
        onFilter={(newValues) => {
          filters.setFilters({ listId: entityName, filterValues: newValues });
          model.fetchList({ criteria: getCriteria(this.props) });
          deselectAll();
        }}
        resetFilter={() =>
          filters.clearFilters({ listId: _.camelCase(entityName) })
        }
        filterValues={filters.values[_.camelCase(entityName)]}
        filters={filterConfig}
        onFilterClear={onFilterClear}
        onClear={() => {
          filters.clearFilters({ listId: _.camelCase(entityName) });
          deselectAll();
          model.fetchList({ criteria: [] });
        }}
        onSearch={(searchTerm) => {
          filters.setFilters({
            listId: _.camelCase(entityName),
            filterValues: { searchTerm }
          });
          deselectAll();
          model.fetchList({ criteria: getCriteria(this.props) });
        }}
      />
    );
  }

  render() {
    return (
      <FilterableListInner renderFilters={this.renderFilters} {...this.props} />
    );
  }
}

@styled(defaultStyles)
@autobind
export class FilterableListInner extends React.Component {
  render() {
    const {
      loadingStatus,
      renderFilters,
      model,
      bulkActions,
      renderItem,
      renderHeader,
      getCriteria,
      standardActions,
      addLink,
      entityName,
      selectedIds,
      items: itemsProp,
      model: {
        list: { pagination }
      }
    } = this.props;
    const { currentPage, itemsPerPage } = pagination || {};

    let items = itemsProp || _.get(model, 'list.items', []);
    const firstItem = itemsPerPage * (currentPage - 1);
    const lastItem = itemsPerPage * currentPage;
    items = items.slice(firstItem, lastItem);

    return (
      <BulkSelect
        getAllIds={() =>
          model.getAllIds({
            criteria: getCriteria(this.props)
          })
        }
        items={items}
        selectedIds={selectedIds}
        totalSize={_.get(model, 'list.pagination.totalItems')}
        updateSelectedIds={model.updateSelectedIds}
      >
        {({
          toggleSelectAll,
          toggleSelectItem,
          selectedCount,
          deselectAll
        }) => (
          <Fragment>
            <Box
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mb={PADDINGS.M}
            >
              <Box sx={PADDINGS.XS} mt={PADDINGS.M} flexDirection={'row'}>
                {!!bulkActions && bulkActions.length > 0 && (
                  <PrimaryButton onClick={toggleSelectAll}>
                    <Checkbox
                      style={{ top: '-1px', left: '3px' }}
                      value={selectedCount === items.length && items.length > 0}
                    />
                  </PrimaryButton>
                )}
                {!!addLink && (
                  <Link to={addLink}>
                    {({ onClick }) => (
                      <PrimaryButton
                        blue
                        style={{ minWidth: '80px' }}
                        onClick={onClick}
                      >
                        <Icon
                          style={{ position: 'relative', top: '3px' }}
                          type={ICONS.ADD}
                        />
                      </PrimaryButton>
                    )}
                  </Link>
                )}
                {!!bulkActions && bulkActions.length > 0 && (
                  <Fragment>
                    {selectedCount > 0 && (
                      <ActionMenu
                        distance={'4px'}
                        placement={PLACEMENTS.BOTTOM_END}
                        Button={() => (
                          <PrimaryButton IconRight={ChevronIcon}>
                            Action {selectedCount} {entityName}
                          </PrimaryButton>
                        )}
                        items={bulkActions}
                      />
                    )}
                  </Fragment>
                )}
                {standardActions.map((action) => (
                  <PrimaryButton key={action.label} onClick={action.onClick}>
                    {action.label}
                  </PrimaryButton>
                ))}
              </Box>
              {renderFilters(deselectAll)}
            </Box>
            <List
              isLoading={
                loadingStatus === 'loading' ||
                (_.get(model, 'list.status') === 'loading' &&
                  items.length === 0) ||
                _.get(model, 'list.status') === 'fetching'
              }
              items={items}
              Header={renderHeader}
              renderItem={_.memoize((item, index) =>
                renderItem(item, index, toggleSelectItem)
              )}
              LoadingView={() => <Spinner hasContainer />}
              EmptyView={() => (
                <EmptyList message={`No ${entityName} found.`} />
              )}
              ErrorView={() => null}
            />
            {loadingStatus !== 'loading' && (
              <Pagination list={model.list} fetchPage={model.fetchPage} />
            )}
          </Fragment>
        )}
      </BulkSelect>
    );
  }
}
