import React, { PureComponent } from 'react';
import { styled } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import types from 'prop-types';

import { SecondaryButton } from 'shared/components/button';
import { push } from '@rexlabs/whereabouts';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import Icon, { ICONS } from 'shared/components/icon';

const defaultStyles = {};

@styled(defaultStyles)
@connect()
@autobind
class RecordTopBar extends PureComponent {
  static propTypes = {
    currentIndex: types.number,
    totalItems: types.number,
    onNext: types.func,
    onPrevious: types.func,
    backTo: types.object
  };

  goBack() {
    const { backTo } = this.props;
    push(backTo);
  }

  render() {
    const { styles: s, children, spacing, ...props } = this.props;
    return (
      <Box
        sx={spacing}
        mt={'30px'}
        flexDirection='row'
        {...s('container')}
        {...props}
      >
        <SecondaryButton onClick={this.goBack}>
          <Icon type={ICONS.ARROW_BACK} />
        </SecondaryButton>
        {children}
      </Box>
    );
  }
}

export default RecordTopBar;
