import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { withQuery } from '@rexlabs/model-generator';
import { accountUsersListQuery } from 'view/components/lists/users';
import { autobind } from 'core-decorators';

import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';
import { Body } from 'view/components/text';
import ROUTES from 'src/routes';
import { PADDINGS } from 'src/theme';
import { push } from '@rexlabs/whereabouts';
import Box from '@rexlabs/box';

@withErrorDialog
@withQuery(accountUsersListQuery)
@autobind
class UserDeleteDialog extends PureComponent {
  state = {
    isLoading: false
  };

  deleteUser() {
    const {
      match: {
        params: { userId }
      },
      accountUsers,
      errorDialog
    } = this.props;

    this.setState({ isLoading: true });

    accountUsers
      .purgeItem({
        id: userId
      })
      .then(() => {
        accountUsers.refreshList();
        push(ROUTES.USERS);
      })
      .catch((e) => {
        errorDialog.open(e);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog title={'Delete User'} height={200} closeDialog={closeModal}>
        <Box mt={PADDINGS.XS} mb={PADDINGS.M}>
          <Body>
            Are you sure you want to delete this user? This can not be undone.
          </Body>
        </Box>
        <ButtonBar width={'auto'} isLoading={isLoading}>
          <TextButton blue onClick={closeModal}>
            Cancel
          </TextButton>
          <PrimaryButton red onClick={this.deleteUser}>
            Delete User
          </PrimaryButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default UserDeleteDialog;
