import _ from 'lodash';
import Box from '@rexlabs/box';
import { autobind } from 'core-decorators';
import React, { Component } from 'react';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Tooltip, { PLACEMENTS } from '@rexlabs/tooltip';
import Circle from '../components/circle';
import NotificationBadge from '../components/notification-badge';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'theme';
import { createKey } from 'shared/utils/react';
import sessionModel from 'data/models/custom/session';
import { withModel } from '@rexlabs/model-generator';
import { Link } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';

@styled(
  StyleSheet({
    container: {
      width: 'inherit',
      minWidth: '270px',
      display: 'flex',
      flexDirection: 'column'
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'rgba(0,0,0,0.75)',
      minHeight: '50px',
      fontSize: '15px',
      lineHeight: '22px',
      cursor: 'pointer',

      '& svg': {
        marginRight: '15px',
        marginLeft: '20px',

        width: '15px',
        height: '15px'
      },

      '&:hover': {
        backgroundColor: '#F7F8F9',
        color: 'black'
      }
    },
    iconLabelWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    label: {},
    badge: {
      textAlign: 'center',
      marginRight: '20px',
      padding: '0 7.5px',
      backgroundColor: '#29a2e2',
      color: 'white',
      borderRadius: '85px'
    },
    separator: {
      height: '1px',
      backgroundColor: '#E1E1E1',
      margin: '13px 20px',
      opacity: '0.5'
    },

    metaWrapper: {
      display: 'flex',
      padding: '15px'
    },
    profilePicture: {
      height: '56px',
      minWidth: '56px',
      marginRight: '15px',
      borderRadius: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',

      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '20px'
    },
    metaInfo: {
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: '600',
      width: '100%'
    },
    fullName: {
      color: '#424242',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'calc(100% - 20px)'
    },
    role: {
      color: '#A8AFB4'
    }
  })
)
@withModel(sessionModel)
@autobind
class UserProfileMenu extends Component {
  constructor(props) {
    super(props);

    this.MENU_CONFIG = [
      {
        icon: ICONS.SETTINGS,
        name: 'Edit My Account',
        linkProps: {
          to: ROUTES.USERS_EDIT,
          params: { userId: this.props.session.userProfile.id }
        }
      },
      {
        icon: ICONS.LOGOUT,
        name: 'Logout',
        onClick: this.props.session.logout
      }
    ];
  }

  render() {
    const { styles: s, meta, session } = this.props;
    const { role, initials, profilePicture } = meta;
    const { full_name: name } = _.get(session, 'userProfile');

    return (
      <Box {...s('container')}>
        <Box {...s('metaWrapper')}>
          <Box
            {...s.with('profilePicture')({
              backgroundImage: `url(${profilePicture})`,
              backgroundColor: profilePicture ? 'transparent' : COLORS.BLUE
            })}
          >
            {!profilePicture && initials}
          </Box>
          <Box
            flex={1}
            flexDirection={'column'}
            justifyContent={'center'}
            {...s('metaInfo')}
          >
            <Box {...s('fullName')}>{name}</Box>
            {role && <Box {...s('role')}>{role}</Box>}
          </Box>
        </Box>

        {this.MENU_CONFIG.map((menuItem, index) => {
          const { name, onClick, linkProps, icon, isSeparator } = menuItem;
          const key = createKey(menuItem.id || menuItem.name);
          const { onClose } = this.props;

          if (isSeparator) {
            return <Box key={`separator-${index}`} {...s('separator')} />;
          }

          return (
            <Link key={key} {...linkProps}>
              {({ onClick: linkOnClick }) => (
                <span
                  {...s('menuItem')}
                  onClick={
                    onClick === undefined
                      ? (e) => {
                          onClose();
                          linkOnClick(e);
                        }
                      : onClick
                  }
                >
                  <Box {...s('iconLabelWrapper')}>
                    <Icon style={{ display: 'flex' }} type={icon} />
                    <Box {...s('label')}>{name}</Box>
                  </Box>
                </span>
              )}
            </Link>
          );
        })}
      </Box>
    );
  }
}

const userProfileTooltipOverrides = {
  Tooltip: StyleSheet({
    tooltip: {
      borderRadius: '3px',
      padding: '5px'
    },
    arrow: {
      borderRadius: '2px',
      margin: '-4px'
    },
    overlay: {}
  }),
  Tether: StyleSheet({
    popperContainer: {
      zIndex: '26 !important'
    }
  })
};

@styled(
  StyleSheet({
    container: {
      color: 'white',
      height: '36px',
      width: '45px',
      borderRadius: '3px 0 0 3px',
      backgroundColor: 'rgba(0,0,0,0.1)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      '& svg': {
        color: 'white',
        width: '15px',
        height: '15px'
      },

      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.5)'
      }
    },
    profilePicture: {
      width: 'inherit',
      height: 'inherit',
      borderRadius: 'inherit',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',

      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '14px'
    }
  })
)
@autobind
class UserProfile extends Component {
  state = { isMenuOpen: false };

  toggleMenuState() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  render() {
    const { styles: s, meta, session, ...props } = this.props;
    const profilePicture = _.get(meta, 'profilePicture', false);
    const { first_name, last_name } = _.get(session, 'userProfile') || {
      first_name: '',
      last_name: ''
    };
    const initials = `${_.first(first_name)}${_.first(last_name)}`;

    return (
      <StylesProvider components={userProfileTooltipOverrides}>
        <Tooltip
          placement={PLACEMENTS.BOTTOM_END}
          offset={'-10px'}
          openOn={'CLICK'}
          closeOn={'CLICK'}
          hoverTimeout={250}
          Content={() => (
            <UserProfileMenu
              onClose={this.toggleMenuState}
              meta={{ ...meta, initials }}
              {...props}
            />
          )}
          popperStyles={{ zIndex: 26 }}
          setOpen={this.toggleMenuState}
          isOpen={this.state.isMenuOpen}
          distance={'14px'}
        >
          <Circle
            style={{ overflow: 'hidden' }}
            render={() => {
              return (
                <span style={{ width: 'inherit', height: 'inherit' }}>
                  <NotificationBadge count={0} />
                  <div
                    {...s.with('profilePicture')({
                      backgroundImage: `url(${profilePicture})`
                    })}
                  >
                    {!profilePicture && initials}
                  </div>
                </span>
              );
            }}
          />
        </Tooltip>
      </StylesProvider>
    );
  }
}

export default UserProfile;
