import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { withQuery, query, withValueLists } from '@rexlabs/model-generator';
import { autobind } from 'core-decorators';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import accountGroupsModel from 'data/models/entities/account-groups';
import _ from 'lodash';
import { accountGroupsListQuery } from 'view/components/lists/office-groups';
import { ReactForms } from '@rexlabs/form';
import { createValidationRules } from 'shared/utils/form';
import { Form } from 'view/components/form';
import PaddingBox from 'view/components/padding-box';
import FormField from 'view/components/form/field';
import { TextInput } from '@rexlabs/text-input';
import ButtonBar from 'view/components/button-bar';
import TextButton from 'shared/components/button/text';
import PrimaryButton from 'shared/components/button/primary';

import subAccountsModel from 'data/models/system-lists/sub-accounts';
import Select from 'view/components/input/select/select';

const itemQuery = query`{
  accountGroupRecord:${accountGroupsModel} (id: ${(p) =>
  _.get(p, 'match.params.groupId')}) {
    id
    name
    related {
      account_group_accounts
    }
  }
}`;

@withValueLists(subAccountsModel)
@withErrorDialog
@withQuery(itemQuery)
@withQuery(accountGroupsListQuery)
@autobind
class OfficeGroupsDetailsDialog extends PureComponent {
  handleSubmit(data) {
    const {
      closeModal,
      match,
      errorDialog,
      accountGroups,
      accountGroupRecord
    } = this.props;
    const id = _.get(match, 'params.groupId');

    // Add destroy key to old subAccounts that are no longer required
    let accountGroupAccounts = _.get(
      accountGroupRecord,
      'item.data.related.account_group_accounts',
      []
    ).map((existingAccount) => {
      existingAccount.destroy = true;
      if (
        _.find(data.offices, (newAccount) => {
          return existingAccount.account_id === newAccount;
        })
      ) {
        delete existingAccount.destroy;
      }
      return existingAccount;
    });

    // Add any new subAccounts that dont already exist
    _.forEach(data.offices, (newAccountId) => {
      if (
        // eslint-disable-next-line
        !accountGroupAccounts.find(({ account_id }) => {
          //eslint-disable-line
          return account_id === newAccountId; //eslint-disable-line
        })
      ) {
        // didn't find the new id
        accountGroupAccounts = accountGroupAccounts.concat([
          { account_id: newAccountId }
        ]);
      }
    });

    const action = id
      ? accountGroupRecord.updateItem({
          id: accountGroupRecord.item.data.id,
          data: {
            name: data.name,
            related: {
              account_group_accounts: [...accountGroupAccounts]
            }
          }
        })
      : accountGroupRecord.createItem({
          data: {
            name: data.name,
            related: {
              account_group_accounts: [...accountGroupAccounts]
            }
          }
        });

    return action
      .then(accountGroups.refreshList)
      .then(closeModal)
      .catch(errorDialog.open);
  }

  getInitialValues() {
    const { match, accountGroupRecord } = this.props;

    if (!_.get(match, 'params.groupId')) {
      return {};
    }

    const initialOffices = _.get(
      accountGroupRecord,
      'item.data.related.account_group_accounts',
      []
    ).map((office) => {
      return office.account.id;
    });

    return {
      ..._.get(accountGroupRecord, 'item.data'),
      offices: initialOffices
    };
  }

  render() {
    const {
      closeModal,
      match,

      valueLists: { subAccounts },
      accountGroupRecord
    } = this.props;
    const id = _.get(match, 'params.groupId');

    return (
      <Dialog
        title={id ? 'Edit Office Group' : 'Create Office Group'}
        height={200}
        closeDialog={closeModal}
        hasPadding={false}
      >
        <ReactForms
          handleSubmit={this.handleSubmit}
          initialValues={this.getInitialValues()}
          enableReinitialize={true}
          asyncValuesReady={accountGroupRecord.item.status === 'loaded'}
          validate={createValidationRules({
            name: 'required'
          })}
        >
          {(form) => (
            <Form>
              <PaddingBox grey>
                <FormField name='name' label='Group name' Input={TextInput} />
              </PaddingBox>
              <PaddingBox>
                <FormField
                  name='offices'
                  label='Offices'
                  Input={Select}
                  inputProps={{
                    multi: true,
                    options: _.get(subAccounts, 'items'),
                    shouldCloseOnSelect: false
                  }}
                />
              </PaddingBox>
              <PaddingBox>
                <ButtonBar isLoading={form.isSubmitting} width={'auto'}>
                  <TextButton blue onClick={closeModal}>
                    Cancel
                  </TextButton>
                  <PrimaryButton blue onClick={form.submitForm}>
                    {id ? 'Save' : 'Create group'}
                  </PrimaryButton>
                </ButtonBar>
              </PaddingBox>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default OfficeGroupsDetailsDialog;
