import React, { Component } from 'react';
import { autobind } from 'core-decorators';

@autobind
class StateToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: props.initialToggle || false
    };
  }

  componentDidMount() {
    // HACK: timeout is needed, because otherwise the click event that
    // triggered this component to get mounted would also immediately
    // trigger the body click :/
    // https://app.clubhouse.io/rexlabs/story/53963
    setTimeout(() => {
      document.body.addEventListener('click', this.onBodyClick);
    });
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.onBodyClick);
  }

  setRef(ref) {
    this.toggleable = ref;
  }

  onBodyClick(e) {
    if (this.toggleable && !this.toggleable.contains(e.target)) {
      this.setState({ toggled: false });
    }
  }

  toggle() {
    this.setState({
      toggled: !this.state.toggled
    });
  }

  setToggled() {
    this.setState({
      toggled: true
    });
  }

  renderItem() {
    const { ToggledRender, InitialRender } = this.props;
    return this.state.toggled ? (
      <ToggledRender
        toggled={this.state.toggled}
        toggle={this.toggle}
        data={this.props.data}
      />
    ) : (
      <InitialRender
        toggled={this.state.toggled}
        toggle={this.toggle}
        data={this.props.data}
      />
    );
  }

  render() {
    return (
      <div onClick={this.setToggled} ref={this.setRef}>
        {this.renderItem()}
      </div>
    );
  }
}

export default StateToggle;
