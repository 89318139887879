import { PLACEMENTS, TooltipStateful } from '@rexlabs/tooltip';
import React from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';
import Icon, { ICONS } from 'shared/components/icon';
import { COLORS } from 'src/theme';
import { Body } from 'view/components/text';

const styles = StyleSheet({
  button: {
    borderRadius: '100%',
    width: '12px',
    height: '12px',
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    '& svg': {
      width: '16px',
      height: '16px',
      position: 'relative',
      top: '3px'
    }
  }
});

export default function HelpTooltip({ text }) {
  const s = useStyles(styles);

  return (
    <TooltipStateful
      placement={PLACEMENTS.TOP}
      distance={'4px'}
      openOn={'HOVER'}
      closeOn={'HOVER'}
      Content={() => (
        <Body small width={200}>
          {text}
        </Body>
      )}
    >
      <div {...s('button')}>
        <Icon {...s('icon')} type={ICONS.HELP_ALT} />
      </div>
    </TooltipStateful>
  );
}
