import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withQuery, query } from '@rexlabs/model-generator';
import List from '@rexlabs/list';
import Box from '@rexlabs/box';
import { ListRow, ListCell } from 'view/components/list';
import { Link, withWhereabouts } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';

import Checkbox from '@rexlabs/checkbox';
import types from 'prop-types';
import PrimaryButton from 'shared/components/button/primary';
import { PADDINGS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import SubHeading from 'view/components/text/sub-heading';
import EmptyList from 'view/components/empty-list';
import accountGroupUsersModel from 'data/models/entities/account-groups-users';
import RenderLoading from 'view/components/render-loading';

import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { withDialog } from 'shared/hocs/with-dialog';
import RemovePrivilegeSetsDialog from 'view/dialogs/users/remove-privilege-sets';

const accountGroupQuery = query`{
  ${accountGroupUsersModel} (id:${(p) => p.accountGroupUserId}) {
    id
    user
    account_group
    related
  }
}`;

@withErrorDialog
@withDialog(RemovePrivilegeSetsDialog, { propName: 'removePrivilegeSets' })
@withWhereabouts()
@withQuery(accountGroupQuery)
@autobind
class PrivilegeSetsList extends PureComponent {
  static propTypes = {
    items: types.array.isRequired
  };

  state = {
    selected: [],
    isSubmitting: false
  };

  renderHeader() {
    return (
      <ListRow justifyContent={'flex-start'} isHeader>
        <ListCell width={50} />
        <ListCell width={250}>Privilege Set name</ListCell>
        <ListCell width={350}>Description</ListCell>
      </ListRow>
    );
  }

  toggleItem(itemId) {
    const { selected } = this.state;
    this.setState({
      selected: selected.includes(itemId)
        ? selected.filter((id) => id !== itemId)
        : selected.concat([itemId])
    });
  }

  onRemoveSelectedClick() {
    const { removePrivilegeSets } = this.props;
    const { selected } = this.state;

    removePrivilegeSets.open({
      callback: this.removeSelected,
      selectedCount: selected.length
    });
  }

  removeSelected() {
    const { accountGroupUsers } = this.props;
    const { selected } = this.state;

    return accountGroupUsers
      .updateItem({
        data: {
          related: {
            user_account_group_privilege_sets:
              accountGroupUsers.item.data.related.user_account_group_privilege_sets.map(
                (item) => {
                  if (selected.includes(item.id)) {
                    return { ...item, destroy: 1 };
                  }

                  return item;
                }
              )
          }
        }
      })
      .then(() => {
        this.setState({
          selected: []
        });
      });
  }

  toggleSelectAll() {
    const { selected } = this.state;
    const { items } = this.props;

    this.setState({
      selected:
        selected.length === items.length ? [] : items.map((item) => item.id)
    });
  }

  onRemoveSetClick(item) {
    const { removePrivilegeSets } = this.props;

    removePrivilegeSets.open({
      callback: () => this.removeSet(item),
      selectedCount: 1
    });
  }

  removeSet(item) {
    const { accountGroupUsers } = this.props;

    accountGroupUsers.updateItem({
      data: {
        related: {
          user_account_group_privilege_sets: [{ ...item, destroy: 1 }]
        }
      }
    });
  }

  renderEmpty() {
    return (
      <div>
        {this.renderHeader()}
        <EmptyList
          small
          message={
            'No Privilege Sets. Add Privilege Sets using the add button above. '
          }
        />
      </div>
    );
  }

  renderItem(item, index) {
    const { selected } = this.state;
    const { toggleItem } = this;

    const actionMenuItems = [
      {
        label: 'Remove Privilege Set',
        onClick: () => this.onRemoveSetClick(item)
      }
    ];

    return (
      <ListRow
        justifyContent={'flex-start'}
        key={item.id}
        odd={index % 2}
        actionMenuItems={actionMenuItems}
      >
        <ListCell width={50}>
          <Checkbox
            id={item.id}
            value={selected.includes(item.id)}
            onChange={() => toggleItem(item.id)}
          />
        </ListCell>
        <ListCell ellipsis width={250}>
          {item.privilege_set.name}
        </ListCell>
        <ListCell ellipsis width={350}>
          {item.privilege_set.description}
        </ListCell>
      </ListRow>
    );
  }

  render() {
    const { isLoading, items, accountGroupUserId, userId, heading } =
      this.props;
    const { selected } = this.state;

    return (
      <Box mb={PADDINGS.XXL}>
        <SubHeading>{heading}</SubHeading>
        <Box sx={PADDINGS.XS} mt={PADDINGS.M} flexDirection={'row'}>
          {items.length > 0 && (
            <PrimaryButton onClick={this.toggleSelectAll}>
              <Checkbox
                style={{ top: '-1px', left: '3px' }}
                value={selected.length === items.length}
              />
            </PrimaryButton>
          )}

          {selected.length > 0 ? (
            <PrimaryButton red onClick={this.onRemoveSelectedClick}>
              <Box style={{ position: 'relative' }}>
                {`Remove ${selected.length} Selected Set${
                  selected.length > 1 ? 's' : ''
                }`}
              </Box>
            </PrimaryButton>
          ) : (
            <Link
              to={ROUTES.USERS_EDIT.ADD_PRIVILEGE_SETS}
              params={{ userId, accountGroupUserId }}
            >
              {({ onClick }) => (
                <PrimaryButton
                  blue
                  style={{ minWidth: '80px' }}
                  onClick={onClick}
                >
                  <Icon
                    style={{ position: 'relative', top: '3px' }}
                    type={ICONS.ADD}
                  />
                </PrimaryButton>
              )}
            </Link>
          )}
        </Box>
        <RenderLoading isLoading={isLoading}>
          <List
            isLoading={false}
            items={items}
            Header={this.renderHeader}
            renderItem={this.renderItem}
            EmptyView={this.renderEmpty}
            LoadingView={() => <p>Loading</p>}
            ErrorView={() => <p>Something went wrong!</p>}
          />
        </RenderLoading>
      </Box>
    );
  }
}

export default PrivilegeSetsList;
