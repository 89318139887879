import React, { PureComponent } from 'react';
import { Heading } from 'view/components/text';
import Box from '@rexlabs/box';
import { ListFilters } from 'view/components/list';

import ReportsList from 'view/components/lists/reports';
import Screen from 'view/components/screen';

class ReportsListScreen extends PureComponent {
  state = {
    searchTerm: ''
  };

  render() {
    return (
      <Screen title={'Reports'}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Heading>Reports</Heading>
          <ListFilters
            placeholder={'Search Reports'}
            onClear={() => this.setState({ searchTerm: '' })}
            onSearch={(searchTerm) => this.setState({ searchTerm })}
          />
        </Box>
        <ReportsList searchTerm={this.state.searchTerm} />
      </Screen>
    );
  }
}

export default ReportsListScreen;
