import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';
import _ from 'lodash';

import CloseIcon from 'assets/icons/close.svg';

const defaultStyles = StyleSheet({
  container: {
    maxWidth: '100%',
    padding: '2px'
  },

  tag: {
    background: COLORS.GREY_LIGHT,
    padding: '4px 8px',
    display: 'inline-block',
    margin: '2px',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '4px',
    pointerEvents: 'none'
  },

  closeIcon: {
    width: '18px',
    height: '18px',
    marginLeft: '5px',
    cursor: 'pointer',
    pointerEvents: 'auto'
  }
});

@styled(defaultStyles)
@autobind
class DefaultValue extends Component {
  handleOptionClick(value) {
    const { onClick } = this.props;
    onClick && onClick(value);
  }

  handleClose(value) {
    const { handleSelect, selected } = this.props;
    const index = _.findIndex(selected, (item) => {
      return item.value === value;
    });
    const newSelected = [
      ...selected.slice(0, index),
      ...selected.slice(index + 1, selected.length)
    ];
    handleSelect(newSelected);
  }

  renderInputTag(option) {
    const { onClose, styles: s } = this.props;

    // return (
    //   <InputTag
    //     disabled={disabled}
    //     clickable={!!Tooltip}
    //     key={(option.data && option.data.id) || index}
    //     item={option.value}
    //     label={option.label}
    //     multi={multi}
    //     Icon={Icon}
    //     onClose={this.handleClose}
    //   />
    // );
    return (
      <Box {...s('tag')} key={option.value} flex alignItems='center'>
        {option.label}
        {onClose !== false && (
          <CloseIcon
            {...s('closeIcon')}
            onClick={() => this.handleClose(option.value)}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        )}
      </Box>
    );
  }

  render() {
    const { styles: s, Tooltip, selected = [], multi } = this.props;

    return selected.length > 0 ? (
      <Box
        {...s('container')}
        alignItems='center'
        flexWrap='wrap'
        width={!multi ? '100%' : undefined}
        height={!multi ? '100%' : undefined}
      >
        {selected.map((option, index) => {
          const data = {
            ..._.get(option, 'data'),
            phone: _.get(option, 'data.phone_number'),
            email: _.get(option, 'data.email_address'),
            name: _.get(option, 'data.name'),
            image: _.get(option, 'data.contact_image'),
            status: _.get(option, 'data.interest_level'),
            date: _.get(option, 'data.last_contacted_date')
          };

          return Tooltip ? (
            <Box
              key={(option.data && option.data.id) || index}
              width={!multi ? '100%' : undefined}
              height={!multi ? '100%' : undefined}
              flex
              alignItems='center'
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              <Tooltip data={data}>
                {this.renderInputTag(option, index)}
              </Tooltip>
            </Box>
          ) : (
            this.renderInputTag(option, index)
          );
        })}
      </Box>
    ) : null;
  }
}

export default DefaultValue;
