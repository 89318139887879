import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Portal } from '@rexlabs/portal';
import { autobind } from 'core-decorators';
import { COLORS, PADDINGS, SHADOWS } from 'theme';
import Draggable from 'react-draggable';

import CloseIcon from 'assets/icons/close.svg';

const defaultStyles = StyleSheet({
  wrapOuter: {
    position: 'absolute',
    zIndex: 5
  },

  container: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.DIALOG,
    margin: '20px',
    borderRadius: '4px'
  },

  titleContainer: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.WHITE,
    padding: `0 ${PADDINGS.S}`,
    cursor: 'move',
    height: '50px'
  },

  title: {
    userSelect: 'none',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: '-.2px',
    fontWeight: 600
  },

  content: {
    padding: '0'
  },
  hasPadding: {
    padding: PADDINGS.S
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  }
});

@styled(defaultStyles)
@autobind
class Dialog extends PureComponent {
  static propTypes = {
    title: types.string.isRequired,
    width: types.number,
    height: types.number,
    closeDialog: types.func.isRequired
  };

  static defaultProps = {
    title: 'Default Dialog Heading',
    width: 500,
    height: 400
  };

  static isDialog = true;

  componentDidMount() {
    if (!this.props.isLoading && this.props.onLoad) {
      this.props.onLoad();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoading && !nextProps.isLoading && nextProps.onLoad) {
      nextProps.onLoad();
    }
  }

  render() {
    const {
      styles: s,
      width,
      height,
      title,
      children,

      closeDialog,

      isLoading,
      hasPadding = true,
      ...props
    } = this.props;

    return isLoading ? null : (
      <Portal target='dialogs'>
        <Draggable
          isDialog={true}
          defaultPosition={{
            x: window.innerWidth / 2 - width / 2,
            y: Math.max(window.innerHeight / 2 - height / 2, 0) - 100
          }}
          handle='.handle'
          bounds='parent'
        >
          <Box flexDirection='column' {...s.with('container')({ width })}>
            <Box
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
              {...s.with('titleContainer')({ className: 'handle' })}
            >
              <Box {...s('title')}>{title}</Box>
              <CloseIcon onClick={closeDialog} {...s('closeIcon')} />
            </Box>
            <Box
              {...s('content', {
                hasPadding
              })}
            >
              {children}
            </Box>
          </Box>
        </Draggable>
      </Portal>
    );
  }
}

export default Dialog;
