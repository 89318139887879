import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      createItem: (type, args) => {
        return api
          .post(`${_.upperFirst(type)}::create`, {
            data: { ...args }
          })
          .then((response) => ({ ...response, data: response.data.result }));
      },
      fetchList: (type, args) => {
        return (
          api
            // remove page from the args as not valid for BE
            .fetchAll(`${_.upperFirst(type)}::search`, _.omit(args, 'page'))
            .then((response) => {
              return { ...response, data: response.rows };
            })
            .catch((e) => {
              throw new Error(e);
            })
        );
      }
    }
  }
};

const actionCreators = {
  purgeItem: {
    request: ({ id, ...args }) =>
      api
        .post('AccountGroupUsers::purge', { ...args, id: id })
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const accountGroupUsersModel = new Generator(
  'accountGroupUsers',
  config
).createEntityModel({
  actionCreators
});

accountGroupUsersModel.select = {
  autocomplete: () => {
    return api.post('UserProfile::getAccessibleAccounts').then(({ data }) =>
      (_.get(data, 'result') || []).map((account) => ({
        value: account.account_id,
        label: account.name,
        data: account,
        model: accountGroupUsersModel
      }))
    );
  }
};

export default accountGroupUsersModel;
