import _ from 'lodash';
import { api, parseUserPrivilegesResponse } from 'shared/utils/api-client';
import { checkUserHasPermission } from 'shared/utils/rights';
import config from 'shared/utils/config';
import authModel from 'shared/data/models/custom/auth';
import { parseUrlToRoute, push } from '@rexlabs/whereabouts';
import Analytics from 'shared/utils/vivid-analytics';
import { Generator } from 'shared/utils/models';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { getRedirectToAuthService } from 'shared/utils/api-client/redirect';
export const TYPE = 'session';

const initialState = {
  ready: false,
  apiToken: null,
  apiRegion: null,
  regions: {},
  accountSettings: null,
  accounts: [],
  userProfile: null,
  groupIds: [],
  privileges: {},
  isSwitching: false,
  isExchangingToken: false
};

const selectors = {
  ready: (state) => _.get(state, 'session.ready'),
  apiToken: (state) => _.get(state, 'session.apiToken'),
  apiRegion: (state) => _.get(state, 'session.apiRegion'),
  regions: (state) => _.get(state, 'session.regions'),
  accountSettings: (state) => _.get(state, 'session.accountSettings'),
  accounts: (state) => _.get(state, 'session.accounts'),
  userProfile: (state) => _.get(state, 'session.userProfile'),
  groupIds: (state) => _.get(state, 'session.groupIds'),
  isSwitching: (state) => _.get(state, 'session.isSwitching'),
  isExchangingToken: (state) => _.get(state, 'session.isExchangingToken'),
  isSupportUser: (state) =>
    String(_.get(state, 'session.userProfile.id')) === '102',
  checkUserHasPermission: (state) => (accessRights) =>
    checkUserHasPermission((dotNotation) =>
      _.get(state, `session.${dotNotation}`)
    )(accessRights)
};

const actionCreators = {
  init: {
    request: async (payload, actions, dispatch, getState) => {
      const currSession = getState().session;
      const route = parseUrlToRoute(window.location.href);

      // Always disable zendesk chat and intercom chat for group app!
      if (window.$zopim) {
        const liveChat = _.get(window, '$zopim.livechat');
        liveChat && liveChat.hideAll();
      }

      if (_.get(currSession, 'userProfile.id')) {
        Analytics.identify({
          userId: currSession.userProfile.id
        });
      }

      api.setApp('rexgroup');

      if (currSession.apiToken) {
        api.setAuthToken(currSession.apiToken);
      }

      if (currSession.apiRegion) {
        api.setBaseUrl(`${currSession.apiRegion.base_url}/v1/rexgroup/`);
      }

      const shouldLoginViaAuthService =
        route.query?.login_source === 'authentication-service';
      const shouldLoginViaRexAuthApp = !!(
        route &&
        route.hashQuery?.token &&
        route.query?.region_id
      );
      const isLoggedOut =
        !getState().session.apiToken || !getState().session.apiRegion;

      if (shouldLoginViaAuthService) {
        await dispatch(
          authModel.actionCreators.loginViaAuthService({
            appId: 'rex_crm_group_app',
            ...actions
          })
        );
      } else if (shouldLoginViaRexAuthApp) {
        await actions.loginViaRexAuthApp();
      } else if (isLoggedOut) {
        actions.logout();
      } else {
        await actions.refresh();
      }

      return {};
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        ...action.payload,
        ready: true
        // Enable when we start to populate user data
        // user: action.payload.user
      }),
      failure: (state) => ({
        ...state,
        ready: true,
        apiToken: null,
        apiRegion: null
      })
    }
  },

  loginViaRexAuthApp: {
    request: async (payload, actions) => {
      const route = parseUrlToRoute(window.location.href);
      await actions.exchangeApiToken({
        token: route.hashQuery?.token,
        accountId: route.query.account_id,
        regionId: route.query.region_id
      });

      delete route.query.region_id;
      delete route.query.account_id;
      route.hash = '';
      route.path = window.location.pathname || '/';
      push({ config: route });

      await actions.refresh();
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  refresh: {
    request: async (payload, actions, dispatch, getState) => {
      const currSession = getState().session;

      if (currSession.apiToken) {
        api.setAuthToken(currSession.apiToken);
      }

      if (currSession.apiRegion) {
        api.setBaseUrl(`${currSession.apiRegion.base_url}/v1/rexgroup/`);
      }

      const request = await Promise.all([
        api.post('AccountSettings::read'),
        api.post('UserProfile::getAccessibleAccounts'),
        api.post('UserProfile::read'),
        api.post('UserProfile::getGroupMemberIds'),
        api.post('SecurityPrivileges::getEffectivePrivilegesForUser', {
          describe_privileges: 1
        })
      ]);

      const results = request.map((r) => _.get(r, 'data.result'));

      const [accountSettings, accounts, userProfile, groupIds, privileges] =
        results;

      Analytics.identify({
        userId: userProfile.id,
        properties: {
          app: 'group-app',
          user: userProfile,
          office: accountSettings
        }
      });

      return {
        accountSettings,
        accounts,
        userProfile,
        groupIds,
        privileges
      };
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        accountSettings: action.payload.accountSettings,
        accounts: action.payload.accounts,
        userProfile: action.payload.userProfile,
        groupIds: action.payload.groupIds,
        privileges: parseUserPrivilegesResponse(action.payload.privileges)
      }),
      failure: _.identity
    }
  },

  exchangeApiToken: {
    request: async (payload, actions, dispatch) => {
      const { token, accountId, regionId } = payload;

      await actions.setRegion({ regionId });
      const exchange = await dispatch(
        authModel.actionCreators.exchangeApiToken({
          token,
          accountId
        })
      );

      const apiToken = _.get(exchange, 'data.result');
      actions.setApiToken(apiToken);

      return actions.refresh();
    },
    reduce: {
      initial: (state) => ({ ...state, isExchangingToken: true }),
      success: (state) => ({ ...state, isExchangingToken: false }),
      failure: (state) => ({ ...state, isExchangingToken: false })
    }
  },

  setApiToken: {
    reduce: (state, action) => {
      if (state.apiToken === action.payload) {
        return state;
      }

      api.setAuthToken(action.payload);

      return {
        ...state,
        apiToken: action.payload
      };
    }
  },

  setRegion: {
    request: async (payload, actions, dispatch) => {
      const { regionId } = payload;

      api.setBaseUrl(config.API_URL);
      const regions = _.get(
        await dispatch(authModel.actionCreators.getRegions()),
        'data.result'
      );

      const region = _.find(regions, (region) => region.id === regionId);
      if (!region) {
        throw new Error('Region for token exchange not found!');
      }

      api.setBaseUrl(`${region.base_url}/v1/rexgroup/`);

      return { region, regions };
    },
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        regions: _.get(action, 'payload.regions'),
        apiRegion: _.get(action, 'payload.region')
      }),
      failure: _.identity
    }
  },

  switchToAccount: {
    request: async (payload, actions) => {
      const { id, isRedirecting = true } = payload;

      const globalToken = await api.post('UserProfile::getGlobalAuthToken');
      const account = _.get(globalToken, 'data.result.accounts', []).find(
        (a) => a.account_id === id
      );

      if (!account) {
        throw new Error('Account not found!');
      }

      await actions.exchangeApiToken({
        token: _.get(globalToken, 'data.result.token'),
        accountId: account.account_id,
        regionId: _.get(account, 'region.id')
      });

      await actions.refresh();

      if (isRedirecting) {
        push({ config: parseUrlToRoute('/') });
      }
    },
    reduce: {
      initial: (state) => ({ ...state, isSwitching: true }),
      success: (state) => ({ ...state, isSwitching: false }),
      failure: (state) => ({ ...state, isSwitching: false })
    }
  },

  logout: {
    request: async (payload, actions, dispatch) => {
      try {
        await api.post('Authentication::getUserLogoutUrl');
        await api.post('Authentication::logout');
      } catch (e) {
        console.error(e);
      }

      dispatch(authModel.actionCreators.clear());

      window.location.href = getRedirectToAuthService(
        'rex_crm_group_app',
        true
      ).redirectUrl;
    },
    reduce: {
      initial: (state) => ({ ...state, apiToken: null, apiRegion: null }),
      success: _.identity,
      failure: _.identity
    }
  }
};

const sessionModel = new Generator(TYPE).createModel({
  actionCreators,
  selectors,
  initialState
});

export default sessionModel;
