import React, { PureComponent } from 'react';
import { PADDINGS, COLORS } from 'theme';
import Dialog from 'view/components/dialog';
import Box from '@rexlabs/box';
import Tick from 'assets/icons/tick_circle2.svg';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Body, SubHeading } from 'view/components/text';

@styled(
  StyleSheet({
    heading: {
      // ...TEXTS.HEADERS.HEADING_2,
      paddingBottom: PADDINGS.XS
    },
    circle: {
      borderRadius: '50%',
      border: `2px solid ${COLORS.GREEN}`,
      overflow: 'hidden',
      width: '20px',
      height: '20px'
    }
  })
)
class SuccessDialog extends PureComponent {
  render() {
    const { styles: s, onSubmit, ...props } = this.props;

    return (
      <Dialog
        title='Upload Successful'
        top={Math.floor(window.innerHeight / 2) - 200}
        {...props}
        closeDialog={onSubmit}
      >
        <Box flexDirection='row'>
          <Box mr={PADDINGS.S}>
            <Tick />
          </Box>
          <Box>
            <SubHeading {...s('heading')}>
              Your PAF file has been successfully uploaded
            </SubHeading>
            <Body>
              The file is now pending processing. You will receive an email when
              the file has been processed. This usually takes about a day.
            </Body>
            <br />
            <Body>Note: you can now close this tab or leave the page.</Body>
          </Box>
        </Box>
      </Dialog>
    );
  }
}

export default SuccessDialog;
