import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'theme';
import DateInput from '@rexlabs/date-input';
import moment from 'moment';
import { autobind } from 'core-decorators';

import ChevronIcon from 'assets/icons/chevron.svg';
import CalendarIcon from 'assets/icons/calendar.svg';

const defaultStyles = StyleSheet({
  wrapDateInput: {
    position: 'relative'
  }
});

@styled(defaultStyles)
@autobind
class DateSelect extends PureComponent {
  render() {
    const { styles: s, onChange, value, ...props } = this.props;

    function fakeEvent(name, value) {
      return {
        persist: () => null,
        target: {
          type: 'date',
          name,
          id: name,
          value
        }
      };
    }

    return (
      <div {...s('wrapDateInput')}>
        <DateInput
          debounce={0}
          padding={0}
          monthPadding={2}
          paddingBottom={5}
          onChange={({ target }) => {
            onChange(
              fakeEvent(props.name, moment(target.value).format('YYYY-MM-DD'))
            );
          }}
          onBlur={({ target }) => {
            props.onBlur(
              fakeEvent(props.name, moment(target.value).format('YYYY-MM-DD'))
            );
          }}
          position='bottom'
          daySize={26}
          navPrev={
            <ChevronIcon
              style={{
                height: '25px',
                width: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'Transparent',
                backgroundRepeat: 'no-repeat',
                border: 'none',
                cursor: 'pointer',
                overflow: 'hidden',
                outline: 'none'
              }}
            />
          }
          navNext={
            <ChevronIcon
              style={{
                height: '25px',
                width: '25px',
                display: 'flex',
                transform: 'rotate(180deg)',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'Transparent',
                backgroundRepeat: 'no-repeat',
                border: 'none',
                cursor: 'pointer',
                overflow: 'hidden',
                outline: 'none'
              }}
            />
          }
          value={
            moment.isMoment(value) ||
            moment(value, 'YYYY-MM-DD', true).isValid()
              ? moment(value)
              : undefined
          }
          suffix={<CalendarIcon style={{ color: COLORS.GREY_LIGHT }} />}
          displayFormat='D MMM YY'
          {...props}
        />
      </div>
    );
  }
}

export default DateSelect;
