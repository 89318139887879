import { TextInput } from '@rexlabs/text-input';
import { Select } from 'view/components/input/select';

/**
 * Handles converting an object of fields into an array of fields as Wings returns both randomly.
 *
 * @param {Object|Array} fields The custom fields that a user has defined to show.
 */
export const toArray = (fields) => {
  if (Array.isArray(fields)) {
    return fields;
  } else {
    return Object.values(fields);
  }
};

const fieldMap = {
  text: TextInput,
  select: Select
};

export const getCustomFieldInput = (field) => {
  const {
    field_type: { id: inputType },
    options
  } = field;
  const inputProps = {
    options: options ? options.map((value) => ({ value, label: value })) : []
  };

  return {
    Input: fieldMap[inputType] || TextInput,
    inputProps
  };
};
