import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';

class OptionLoading extends PureComponent {
  render() {
    return (
      <Box
        style={{ padding: '7px' }}
        justifyContent='center'
        alignItems='center'
      >
        <Spinner small dark />
      </Box>
    );
  }
}

export default OptionLoading;
