import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const actionCreators = {
  purgeItem: {
    request: ({ id, ...args }) =>
      api
        .post('Announcements::purge', { ...args, id: id })
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator('announcements').createEntityModel({
  actionCreators
});
