import { getUserColumnDefs } from 'view/screens/billing/grid-users';
import SimpleDataGrid from 'view/components/simple-data-grid';
import { PADDINGS } from 'src/theme';
import React from 'react';

export function getAccountColumnDefs(includeSubData) {
  return [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      flex: 1,
      filter: 'agTextColumnFilter',
      resizable: true
    },
    {
      field: 'name',
      sortable: true,
      flex: 3,
      filter: 'agTextColumnFilter',
      resizable: true
    },
    {
      field: 'total',
      headerName: 'Total Slots',
      filter: 'agNumberColumnFilter',
      sortable: true,
      flex: 2,
      resizable: true
    },
    {
      field: 'allocated',
      headerName: 'Used Slots',
      filter: 'agNumberColumnFilter',
      sortable: true,
      flex: 2,
      resizable: true
    },
    {
      field: 'unallocated',
      headerName: 'Available Slots',
      filter: 'agNumberColumnFilter',
      sortable: true,
      flex: 2,
      resizable: true
    },
    ...(includeSubData
      ? [
          {
            field: 'users',
            headerName: 'Users',
            width: 110,
            sortable: true,
            filter: 'agSetColumnFilter',
            valueFormatter: ({ value }) => value?.length,
            keyCreator: ({ value }) => value?.map((v) => v.name),
            cellRenderer: 'agGroupCellRenderer'
          }
        ]
      : [])
  ];
}

export const accountsDetailCellRendererParams = {
  // provide the Grid Options to use on the Detail Grid
  detailGridOptions: {
    columnDefs: getUserColumnDefs(false),
    rowHeight: 30,
    headerHeight: 30
  },

  // get the rows for each Detail Grid
  getDetailRowData: (params) => {
    params.successCallback(params.data.users);
  }
};

export const accountColumnsToTotal = [
  'total',
  'allocated',
  'unallocated',
  'users'
];

export function renderAccounts(accounts) {
  return (
    <SimpleDataGrid
      style={{
        height: 'calc(100vh - 290px)',
        width: '100%',
        marginTop: PADDINGS.XS
      }}
      columnDefs={getAccountColumnDefs(true)}
      rowData={accounts}
      columnsToTotal={accountColumnsToTotal}
      detailCellRendererParams={accountsDetailCellRendererParams}
    />
  );
}
