import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS, COLORS } from 'src/theme';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid } from 'shared/components/grid';
import SubHeading from 'view/components/text/sub-heading';
import Heading from 'view/components/text/heading';

const defaultStyles = StyleSheet({
  container: {
    paddingBottom: PADDINGS.XXL,
    paddingTop: PADDINGS.XXL,
    borderBottom: `1px solid ${COLORS.GREY_LIGHT}`
  },
  heading: {
    marginBottom: PADDINGS.L
  }
});

@styled(defaultStyles)
class RecordFieldGrid extends PureComponent {
  render() {
    const { styles: s, children, heading, largeHeading, ...props } = this.props;
    return (
      <Box {...s('container')} {...props}>
        <Box mb={PADDINGS.M}>
          {largeHeading ? (
            <Heading>{heading}</Heading>
          ) : (
            <SubHeading>{heading}</SubHeading>
          )}
        </Box>
        <Grid>{children}</Grid>
      </Box>
    );
  }
}

export default RecordFieldGrid;
