import { getAccountColumnDefs } from 'view/screens/billing/grid-accounts';
import SimpleDataGrid from 'view/components/simple-data-grid';
import { PADDINGS } from 'src/theme';
import React from 'react';

export function getUserColumnDefs(includeSubData) {
  return [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      filter: 'agTextColumnFilter',
      flex: 1,
      resizable: true
    },
    {
      field: 'name',
      sortable: true,
      filter: 'agTextColumnFilter',
      flex: 2,
      resizable: true
    },
    {
      field: 'email',
      sortable: true,
      filter: 'agTextColumnFilter',
      flex: 3,
      resizable: true
    },
    {
      field: 'category',
      sortable: true,
      filter: 'agSetColumnFilter',
      flex: 2,
      resizable: true
    },
    {
      field: 'isGroupAppUser',
      headerName: 'Is Group User',
      sortable: true,
      flex: 2,
      resizable: true,
      cellRenderer: ({ value }) => (value ? 'Yes' : ''),
      filter: 'agSetColumnFilter'
    },
    ...(includeSubData
      ? [
          {
            field: 'accounts',
            headerName: 'Accounts',
            sortable: true,
            filter: 'agSetColumnFilter',
            valueFormatter: ({ value }) => value?.length,
            keyCreator: ({ value }) => value?.map((v) => v.name),
            cellRenderer: 'agGroupCellRenderer',
            width: 110
          }
        ]
      : [])
  ];
}

export const usersDetailCellRendererParams = {
  // provide the Grid Options to use on the Detail Grid
  detailGridOptions: {
    columnDefs: getAccountColumnDefs(false),
    rowHeight: 30,
    headerHeight: 30
  },

  // get the rows for each Detail Grid
  getDetailRowData: (params) => {
    params.successCallback(params.data.accounts);
  }
};

export function renderUsers(users) {
  return (
    <SimpleDataGrid
      style={{
        height: 'calc(100vh - 290px)',
        width: '100%',
        marginTop: PADDINGS.XS
      }}
      columnDefs={getUserColumnDefs(true)}
      rowData={users}
      detailCellRendererParams={usersDetailCellRendererParams}
    />
  );
}
