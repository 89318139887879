import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'theme';
import ProgressBar from 'shared/components/progress-bar';
import { PrimaryButton } from 'shared/components/button';
import { SubHeading, Body } from 'view/components/text/';

@styled(
  StyleSheet({
    contentParagraph: {
      // ...TEXTS.CONTENT.INFORMATIVE_SUBTEXT_1,
      color: COLORS.GREY_MEDIUM,
      paddingBottom: PADDINGS.L
    },
    progressBarContainer: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    paddedContainer: {
      paddingLeft: PADDINGS.XXS,
      paddingTop: PADDINGS.XS
    },
    status: {
      // ...TEXTS.APP_SEARCH.RECORD_NAME,
      fontSize: '12px',
      paddingBottom: PADDINGS.XXS
    },
    sectionItem: {
      paddingBottom: PADDINGS.M
    },
    sectionHeading: {
      paddingTop: PADDINGS.XXL,
      paddingBottom: PADDINGS.M
    }
  })
)
class PafUploadProgress extends PureComponent {
  render() {
    const {
      styles: s,
      percent,
      onCancel,
      onPause,
      onResume,
      isPaused,
      filename,
      status,
      isPending,
      isProcessing,
      hasFailed
    } = this.props;

    return (
      <Box>
        <SubHeading {...s('sectionHeading')}>Upload in Progress</SubHeading>
        <Body {...s('sectionItem')} grey>
          Please do not close this window or navigate away from this page during
          the upload. Once the upload is complete the file will be pending
          processing, during which, the addresses within the file will be added
          to the address database in your account. This may take up to 24 hours.
          Check back here for updates.
        </Body>
        <Box>
          <span
            {...s('status')}
            style={{ color: hasFailed ? 'red' : undefined }}
          >
            {hasFailed ? 'Error uploading ' : 'Uploading '} {filename} •{' '}
            {status}
          </span>
        </Box>
        <Box {...s('progressBarContainer')}>
          <ProgressBar
            styles={{
              barFrame: {
                width: '100%'
              },
              uploadStatus: {
                padding: '0'
              }
            }}
            percent={percent}
            isUploading
            isErrored={hasFailed}
          />
          <Box {...s('paddedContainer')}>
            <PrimaryButton
              onClick={isPaused ? onResume : onPause}
              isDisabled={isPending || isProcessing}
            >
              {isPaused ? 'Resume' : 'Pause'}
            </PrimaryButton>
          </Box>
          <Box {...s('paddedContainer')}>
            <PrimaryButton
              red
              onClick={onCancel}
              isDisabled={isPending || isProcessing}
            >
              Cancel
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default PafUploadProgress;
