import React, { Fragment } from 'react';
import { withModel } from '@rexlabs/model-generator';
import PrimaryButton from 'shared/components/button/primary';
import { Body } from 'view/components/text';
import ROUTES from 'src/routes';
import _ from 'lodash';
import PrivilegeSetsList from 'view/components/lists/privilege-sets';
import FormField from 'view/components/form/field';
import { Link } from '@rexlabs/whereabouts';
import { autobind } from 'core-decorators';
import RecordFieldGrid from 'view/components/record-field-grid';
import { Select } from 'view/components/input/select';
import { Column } from 'shared/components/grid';
import { PADDINGS } from 'theme';
import Box from '@rexlabs/box';
import RenderLoading from 'view/components/render-loading';
import sessionModel from 'data/models/custom/session';
import {
  subAccountAccessOptions,
  subAccountAccessValues
} from './sub-account-access';

@withModel(sessionModel)
@autobind
export default class UserRexPrivileges extends React.PureComponent {
  tabName = 'rexPrivileges';

  renderPrivileges({ onChange }) {
    const { match, accountGroupUsers, session, values } = this.props;

    const subAccountAccess = _.get(values, 'sub_account_access');
    const canManageRexPrivileges =
      session.checkUserHasPermission('users.manage_rex_privileges') &&
      subAccountAccess === subAccountAccessValues.privileges;

    return (
      canManageRexPrivileges && (
        <RenderLoading
          isLoading={_.get(accountGroupUsers, 'list.status') === 'loading'}
        >
          {accountGroupUsers.list.items.map((item) => (
            <PrivilegeSetsList
              heading={_.get(item, 'account_group.name')}
              userId={_.get(match, 'params.userId')}
              accountGroupUserId={_.get(item, 'id')}
              key={_.get(item, 'id')}
              onChange={onChange}
              items={
                _.get(item, 'related.user_account_group_privilege_sets') || []
              }
            />
          ))}
        </RenderLoading>
      )
    );
  }

  render() {
    const { accountGroupUsers, activeTab, match, session, values } = this.props;

    const subAccountAccess = _.get(values, 'sub_account_access');
    const canManage = session.checkUserHasPermission('users.manage');
    const canAssign =
      session.checkUserHasPermission('users.assign_to_office_group') &&
      subAccountAccess === subAccountAccessValues.privileges;

    return canManage && activeTab === this.tabName ? (
      <Fragment>
        <RecordFieldGrid mb={PADDINGS.XXL} heading={'Access to Sub Accounts'}>
          <Column width={12}>
            <Body grey>
              Select if this user can log in to sub accounts, or specify
              privileges for any office groups.
            </Body>
          </Column>
          <Column width={12}>
            <Box mt={PADDINGS.M}>
              <FormField
                label={null}
                name={'sub_account_access'}
                Input={Select}
                inputProps={{ options: subAccountAccessOptions }}
              />
            </Box>
          </Column>
        </RecordFieldGrid>
        {canAssign && (
          <RecordFieldGrid
            mb={PADDINGS.XXL}
            heading={'Privileges Per Office Group'}
          >
            <Column width={12}>
              <Body grey>
                First, assign this user to an Office Group. Office groups are a
                collection of Rex accounts which are used by individual offices.
                After assigning the user, you can then add Rex account privilege
                sets that apply to the user within that Office Group.
              </Body>
              <Box mt={PADDINGS.M}>
                <Link
                  to={ROUTES.USERS_EDIT.ASSIGN_OFFICE_GROUPS}
                  params={{ userId: _.get(match, 'params.userId') }}
                >
                  {({ onClick }) => (
                    <PrimaryButton onClick={onClick}>
                      {accountGroupUsers.list.items.length > 0
                        ? 'Manage Office Groups'
                        : 'Assign Office Groups'}
                    </PrimaryButton>
                  )}
                </Link>
              </Box>
            </Column>
          </RecordFieldGrid>
        )}
        <FormField
          label={null}
          accountGroupUsers={accountGroupUsers} // NEED THIS TO RERENDER
          name={'rexPrivileges'}
          Input={this.renderPrivileges}
          inputProps={{ values }}
        />
      </Fragment>
    ) : null;
  }
}
