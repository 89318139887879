import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withQuery, query, withModel } from '@rexlabs/model-generator';
import { ListRow, ListCell } from 'view/components/list';
import { Link } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import _ from 'lodash';
import accountGroupsModel from 'data/models/entities/account-groups';
import FilterableList from 'view/components/filterable-list';
import filtersModel from 'data/models/custom/filters';
import sessionModel from 'data/models/custom/session';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

function getCriteria(props) {
  if (_.get(props, 'filters.values.officeGroups.searchTerm')) {
    return [
      {
        name: 'name',
        type: 'like',
        value: `%${_.get(props, 'filters.values.officeGroups.searchTerm')}%`
      }
    ];
  }
  return [];
}

const getExtraFields = () => ({
  fields: ['related.account_group_accounts']
});

export const accountGroupsListQuery = query`{
  ${accountGroupsModel} (criteria: ${(props) =>
  getCriteria(props)}, extra_options: ${getExtraFields}) {
    id
    name
    related {
      account_group_accounts
    }
  }
}`;

@withErrorDialog
@withQuery(accountGroupsListQuery)
@withModel(filtersModel)
@withModel(sessionModel)
@autobind
class OfficeGroupsList extends PureComponent {
  deleteOfficeGroup(item) {
    const { accountGroups, errorDialog } = this.props;

    accountGroups
      .purgeItem({
        id: item.id
      })
      .then(accountGroups.refreshList)
      .catch(errorDialog.open);
  }

  renderHeader() {
    return (
      <ListRow isHeader>
        <ListCell width={450}>Group name</ListCell>
        <ListCell flex={1}>Offices</ListCell>
      </ListRow>
    );
  }

  renderItem(item, index) {
    const { session } = this.props;
    const canManage = session.checkUserHasPermission('account_groups.manage');

    const actionMenuItems = [
      {
        label: 'Delete Office Group',
        onClick: () => this.deleteOfficeGroup(item)
      }
    ];

    return (
      <Link to={ROUTES.OFFICE_GROUPS.EDIT} params={{ groupId: item.id }}>
        {({ onClick }) => (
          <ListRow
            key={item.id}
            odd={index % 2}
            onClick={canManage ? onClick : _.noop}
            actionMenuItems={canManage ? actionMenuItems : undefined}
          >
            <ListCell ellipsis width={450}>
              {item.name}
            </ListCell>
            <ListCell ellipsis flex={1}>
              {_.get(item, 'related.account_group_accounts', [])
                .map((office) => office.account.text)
                .join(', ')}
            </ListCell>
          </ListRow>
        )}
      </Link>
    );
  }

  render() {
    const { accountGroups, session } = this.props;
    const canManage = session.checkUserHasPermission('account_groups.manage');
    return (
      <FilterableList
        entityName={'office groups'}
        addLink={canManage ? ROUTES.OFFICE_GROUPS.CREATE : undefined}
        renderItem={this.renderItem}
        renderHeader={this.renderHeader}
        model={accountGroups}
        getCriteria={getCriteria}
      />
    );
  }
}

export default OfficeGroupsList;
