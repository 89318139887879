import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Generator } from 'shared/utils/models';

const TYPE = 'reports';

const initialState = {
  status: 'loading',
  items: [],
  list: []
};

const actionCreators = {
  generateReport: {
    request: (data) => api.post('ReportGenerator::generateReport', data),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchList: {
    request: () => api.post('ReportCenter::getReportCenterDefinition'),

    reduce: {
      success: (state, action) => ({
        ...state,
        items: _.keyBy({ ...action.payload.data.result }, 'id'),
        list: action.payload.data.result.map((item) => item.id),
        status: 'loaded'
      }),
      failure: (state) => ({ ...state, status: 'error' })
    }
  },
  describeReport: {
    request: ({ id, viewId }) =>
      api.post('ReportGenerator::describeReport', { id, view_id: viewId }),

    reduce: {
      success: (state, action) => ({
        ...state,
        items: {
          ...state.items,
          [action.meta.originalPayload.id]: {
            ...state.items[action.meta.originalPayload.id],
            ...action.payload.data.result
          }
        },
        status: 'loaded'
      }),
      failure: (state) => ({ ...state, status: 'error' })
    }
  }
};

const selectors = {
  items: (state) => state.reports.items,
  list: (state) => state.reports.list,
  status: (state) => state.reports.status,
  getReport: (state) => (id, viewId) =>
    _.find(
      _.find(state.reports.items, (category) =>
        _.find(
          category.reports,
          (report) => report.id === id && report.view_id === viewId
        )
      ).reports,
      (report) => report.id === id && report.view_id === viewId
    )
};

export default new Generator(TYPE).createModel({
  actionCreators,
  initialState,
  selectors
});
