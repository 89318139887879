import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';

import sessionModel from 'data/models/custom/session';
import AppLayout from 'view/layouts/app';

import WrapperLayout from 'shared/layouts/wrapper';

@withModel(sessionModel)
@autobind
class GroupApp extends PureComponent {
  render() {
    const { session } = this.props;

    return (
      <WrapperLayout
        loadingMessage={
          !session.ready || session.isSwitching ? 'Loading app' : null
        }
      >
        <AppLayout {...this.props} />
      </WrapperLayout>
    );
  }
}

export default GroupApp;
