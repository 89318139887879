import React, { PureComponent } from 'react';
import { KEYFRAMES, COLORS } from 'theme';
import { PortalTarget } from '@rexlabs/portal';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';
import CSSAnimationGroup from '@rexlabs/css-animation-group';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  wrapItems: {
    position: 'fixed',
    zIndex: 900,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none'
  },

  wrapItemsActive: {
    opacity: 1,
    pointerEvents: 'auto'
  },

  wrapItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto'
  },

  wrapDialog: {
    transformOrigin: 'center center'
  },

  backdrop: {
    background: COLORS.PRIMARY_DARK,
    opacity: 0.2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    transition: 'opacity .1s'
  }
});

@styled(defaultStyles)
class DialogsPortal extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <PortalTarget name='dialogs'>
        {({ children }) => (
          <Box
            {...s('wrapItems', {
              wrapItemsActive: !!children && children.length
            })}
          >
            {/* BACKDROP */}
            <CSSAnimationGroup
              leaveAnimation={keyframes({
                '0%': { opacity: 1 },
                '100%': { opacity: 0 }
              })}
              leaveDuration='250ms'
              enterAnimation={keyframes({
                '0%': { opacity: 0 },
                '100%': { opacity: 1 }
              })}
              enterDuration='250ms'
              enterOnAppear={true}
              enterTimingFunction='cubic-bezier(0.25,0.1,0.25,1)'
              leaveTimingFunction='cubic-bezier(0.25,0.1,0.25,1)'
            >
              {!!children && children.length > 0 ? (
                <div {...s('wrapItem')} key='backdrop'>
                  <div {...s('backdrop')} />
                </div>
              ) : null}
            </CSSAnimationGroup>

            {/* DIALOGS */}
            <CSSAnimationGroup
              leaveAnimation={KEYFRAMES.DIALOG_FADE_OUT}
              leaveDuration='250ms'
              enterAnimation={KEYFRAMES.DIALOG_FADE_IN}
              enterDuration='250ms'
              enterTimingFunction='cubic-bezier(0.25,0.1,0.25,1)'
              leaveTimingFunction='cubic-bezier(0.25,0.1,0.25,1)'
            >
              {React.Children.map(children, (child, i) => (
                <Box {...s('wrapItem', 'wrapDialog')} key={`dialogs-${i}`}>
                  {child}
                </Box>
              ))}
            </CSSAnimationGroup>
          </Box>
        )}
      </PortalTarget>
    );
  }
}

export default DialogsPortal;
