import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withValueLists } from '@rexlabs/model-generator';
import invariant from 'invariant';
import _ from 'lodash';

import Select from './select';
import ModelOption from './options/model';

@autobind
class WithValueListSelect extends PureComponent {
  static defaultProps = {
    models: [],
    debounce: 300
  };

  constructor(props) {
    super(props);

    invariant(props.models, 'You need to define models for EntitySelect!');

    this.state = {
      searchTerm: '',
      options: props.initialOptions || [],
      selected: []
    };
  }

  handleChange(e) {
    const { options } = this.state;
    const { multi, onChange } = this.props;
    this.setState({
      selected: multi
        ? options.filter((option) => e.target.value.includes(option.data.id))
        : []
    });
    onChange(e);
  }

  render() {
    const { hasFixtures, models, valueLists } = this.props;

    // Get all options and loading states from any models that have been
    // passed into this select component!
    const { options, isLoading } = models.reduce(
      (all, model) => {
        const options = [
          ...all.options,
          ..._.get(valueLists, `${model.modelName}.items`, [])
        ];
        const isLoading =
          all.isLoading ||
          _.get(valueLists, `${model.modelName}.status`) === 'loading';
        return { options, isLoading };
      },
      { options: [], isLoading: false }
    );

    const fixtures = models
      .map((model, index) => ({
        model,
        value: index,
        hasFixture: !!_.get(model, 'select.Fixture')
      }))
      .filter((fixture) => fixture.hasFixture);

    return (
      <Select
        Option={ModelOption}
        OptionSelected={ModelOption}
        isLoading={isLoading}
        shouldSelectResetInput={true}
        fixtures={hasFixtures ? fixtures : undefined}
        {...this.props}
        options={options}
        onChange={this.handleChange}
      />
    );
  }
}

class ValueListSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.Component = withValueLists(...props.models)(WithValueListSelect);
  }

  render() {
    const { Component } = this;
    return <Component {...this.props} />;
  }
}

export default ValueListSelect;
