import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { withQuery } from '@rexlabs/model-generator';

import { autobind } from 'core-decorators';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';

import { Body } from 'view/components/text';

import { PADDINGS } from 'src/theme';
import Box from '@rexlabs/box';
import { announcementRecordQuery } from 'view/dialogs/announcements/details';
import { announcementListQuery } from 'view/components/lists/announcements';

@withErrorDialog
@withQuery(announcementListQuery)
@withQuery(announcementRecordQuery)
@autobind
class AnnouncementDeleteDialog extends PureComponent {
  state = {
    isLoading: false
  };

  deleteAnnouncement() {
    const {
      closeModal,
      announcementRecord,
      announcements,
      match,
      errorDialog
    } = this.props;
    const id = _.get(match, 'params.announcementId');
    this.setState({ isLoading: true });
    announcementRecord
      .purgeItem({ id })
      .then(() => {
        announcements.refreshList();
        closeModal();
      })
      .catch((e) => {
        errorDialog.open(e);
        closeModal();
      });
  }

  render() {
    const { closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        title={'Delete Announcement'}
        height={200}
        closeDialog={closeModal}
      >
        <Box mt={PADDINGS.XS} mb={PADDINGS.M}>
          <Body>Are you sure you want to delete this announcement?</Body>
        </Box>
        <ButtonBar isLoading={isLoading} width={'auto'}>
          <TextButton blue onClick={closeModal}>
            Cancel
          </TextButton>
          <PrimaryButton red onClick={this.deleteAnnouncement}>
            Delete Announcement
          </PrimaryButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default AnnouncementDeleteDialog;
