import React, { PureComponent, Fragment } from 'react';
import types from 'prop-types';
import { autobind } from 'core-decorators';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

@withErrorDialog
@autobind
export default class BulkSelect extends PureComponent {
  static propTypes = {
    getAllIds: types.func,
    selectedIds: types.arrayOf(types.string),
    updateSelectedIds: types.func,
    totalSize: types.number
  };

  state = {
    selectedCount: 0
  };

  componentWillUnmount() {
    const { updateSelectedIds } = this.props;
    if (updateSelectedIds) updateSelectedIds([]);
  }

  toggleSelectItem(itemId) {
    const { selectedIds, updateSelectedIds } = this.props;
    const newIds = selectedIds.includes(itemId)
      ? selectedIds.filter((id) => id !== itemId)
      : selectedIds.concat([itemId]);
    updateSelectedIds(newIds);

    this.setState({
      selectedCount: newIds.length
    });
  }

  toggleSelectAll() {
    const { selectedCount } = this.state;
    const { getAllIds, totalSize, updateSelectedIds, items, errorDialog } =
      this.props;

    if (selectedCount !== totalSize) {
      updateSelectedIds(items.map((item) => item.id));
      // We're firing off this API request now, but updating the selected count immediately
      getAllIds().then(updateSelectedIds).catch(errorDialog.open);
      this.setState({
        selectedCount: totalSize
      });
    } else {
      this.setState({
        selectedCount: 0
      });
      updateSelectedIds([]);
    }
  }

  deselectAll() {
    const { updateSelectedIds } = this.props;

    this.setState({ selectedCount: 0 });
    updateSelectedIds && updateSelectedIds([]);
  }

  render() {
    const { children } = this.props;
    const { selectedCount } = this.state;
    const { toggleSelectAll, toggleSelectItem, deselectAll } = this;
    return (
      <Fragment>
        {children({
          toggleSelectAll,
          toggleSelectItem,
          selectedCount,
          deselectAll
        })}
      </Fragment>
    );
  }
}
