import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { query, withQuery } from '@rexlabs/model-generator';
import accountUsersModel from 'data/models/entities/account-users';

import { autobind } from 'core-decorators';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';

import { Body } from 'view/components/text';

import { PADDINGS } from 'src/theme';
import Box from '@rexlabs/box';

const userQuery = query`{
  ${accountUsersModel} (id: ${(p) => _.get(p, 'match.params.userId')}) {
    id
    first_name
    email
    last_name
    reporting_categories
    system_created_user
    system_ctime
    user_account_status
    user_links
    user_registration_status
  }
}`;

@withErrorDialog
@withQuery(userQuery)
@autobind
class UserResetPasswordDialog extends PureComponent {
  state = { isLoading: false };

  sendResetEmail() {
    const { closeModal, accountUsers, errorDialog } = this.props;
    this.setState({ isLoading: true });
    accountUsers
      .resetPassword({ user_id: _.get(this.props, 'match.params.userId') })
      .then(closeModal)
      .catch((e) => {
        errorDialog.open(e);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      closeModal,

      accountUsers: { item }
    } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog title={'Password Reset'} height={200} closeDialog={closeModal}>
        <Box mt={PADDINGS.XS} mb={PADDINGS.M}>
          <Body>
            An email will be sent to {item.data.email} with a link for the user
            to create a new password.
          </Body>
        </Box>
        <ButtonBar isLoading={isLoading} width={'auto'}>
          <TextButton blue onClick={closeModal}>
            Cancel
          </TextButton>
          <PrimaryButton blue onClick={this.sendResetEmail}>
            Send Reset Email
          </PrimaryButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default UserResetPasswordDialog;
