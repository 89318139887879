import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';

import moment from 'moment';
import { autobind } from 'core-decorators';

import DateSelect from 'view/components/input/select/date-select';
import Box from '@rexlabs/box';

const defaultStyles = StyleSheet({
  wrapDateInput: {
    display: 'flex',
    flexDirection: 'row'
  }
});

function fakeEvent(name, value) {
  return {
    persist: () => null,
    target: {
      type: 'date',
      name,
      id: name,
      value
    }
  };
}

@styled(defaultStyles)
@autobind
class DateRangeSelect extends PureComponent {
  static defaultProps = {
    value: []
  };

  render() {
    const { styles: s, onChange, value, ...props } = this.props;

    return (
      <div {...s('wrapDateInput')}>
        <Box mr={PADDINGS.S}>
          <DateSelect
            placeholder={'From'}
            value={value[0]}
            onChange={(e) =>
              onChange(fakeEvent(props.name, [e.target.value, value[1]]))
            }
            isOutsideRange={(day) =>
              value[1] ? day.isAfter(moment(value[1])) : false
            }
            {...props}
          />
        </Box>
        <DateSelect
          value={value[1]}
          placeholder={'To'}
          onChange={(e) =>
            onChange(fakeEvent(props.name, [value[0], e.target.value]))
          }
          isOutsideRange={(day) =>
            value[0] ? day.isBefore(moment(value[0])) : false
          }
          {...props}
        />
      </div>
    );
  }
}

export default DateRangeSelect;
