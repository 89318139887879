import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import moment from 'moment';
import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';

import { COLORS, PADDINGS } from 'theme';
import { Body } from 'view/components/text';
import SubHeading from 'view/components/text/sub-heading';
import Icon, { ICONS } from 'shared/components/icon';

const defaultStyles = StyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: 'inherit',
    textDecoration: 'none',
    padding: PADDINGS.M,
    borderBottom: COLORS.GREY_LIGHT,
    '&:hover': {
      backgroundColor: COLORS.BACKGROUND
    },
    cursor: 'pointer'
  },

  image: {
    width: '64px',
    height: '64px',
    marginRight: PADDINGS.XS,
    background: COLORS.GREY_LIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    },

    '& svg': {
      width: '30px',
      height: '30px',
      color: COLORS.GREY_DARK
    }
  },

  heading: {
    padding: `0 ${PADDINGS.XS}`
  },

  body: {
    padding: `0 ${PADDINGS.XS}`
  },

  meta: {
    marginLeft: 'auto',
    textAlign: 'right'
  }
});

@styled(defaultStyles)
@autobind
class SearchListItem extends PureComponent {
  render() {
    const { styles: s, item } = this.props;
    const { content, image, meta, heading, link } = processItem(item);

    function onClick() {
      const isSelection = window.getSelection()?.type === 'Range';
      if (isSelection) {
        return false;
      }
      window.open(link, '_blank');
    }

    return (
      <div
        {...s('container')}
        onClick={onClick}
        data-testid='search-list-item'
        data-item-href={link}
      >
        <div {...s('image')}>
          {_.isString(image) ? (
            <img src={image} />
          ) : (
            <Icon hasControlledColor type={image} />
          )}
        </div>
        <div>
          <SubHeading {...s('heading')}>{heading}</SubHeading>
          {content.map((c, index) => (
            <div {...s('body')} key={index}>
              <Body small grey>
                {c}
              </Body>
            </div>
          ))}
        </div>
        <div {...s('meta')}>
          {meta.map((m, index) => (
            <div key={index}>
              <Body small grey>
                {m}
              </Body>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default SearchListItem;

function processItem(item) {
  // Contact
  if (_.has(item, 'email_address')) {
    return {
      link: `https://app.rexsoftware.com/contacts/#id=${item.id}&_account_id=${item.account.id}`,
      image: _.get(item.contact_image, 'thumbs.200x150.url') || ICONS.CONTACTS,
      content: [item.email_address, item.phone_number, item.address].filter(
        Boolean
      ),
      meta: [item.account.name, `#${item.id}`],
      heading: item.name
    };
  }

  // Listing
  if (item.address) {
    return {
      link: `https://app.rexsoftware.com/listings/#id=${item.id}&_account_id=${item.account.id}`,
      image: _.get(item.image, 'thumbs.200x150.url') || ICONS.LISTINGS,
      content: [`${item.category.text}`, _.startCase(item.status)],
      meta: [item.account.name, `#${item.id}`],
      heading: item.address
    };
  }

  // Invoice
  if (item.invoice_date) {
    return {
      link: `https://app.rexsoftware.com/listings/#id=${item.listing.id}&mode=financial&invoice_id=${item.id}&_account_id=${item.account.id}`,
      content: [
        item.system_total_balance
          ? `$${item.system_total_balance} ${
              item.due_date
                ? 'due ' + moment(item.due_date).format('DD MMM YYYY')
                : undefined
            }`
          : undefined,
        `Invoicee: ${item.invoicee_name}`
      ].filter(Boolean),
      image: ICONS.FINANCE,
      meta: [item.account.name, `#${item.id}`],
      heading: item.listing.property.system_search_key
    };
  }
}
