import React, { Component, Fragment } from 'react';
import { autobind, decorate } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';

import List from '@rexlabs/list';
import Box from '@rexlabs/box';
import { ListRow, ListCell } from 'view/components/list';

import { Link } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import _ from 'lodash';

import reportsModel from 'data/models/custom/reports';
import sessionModel from 'data/models/custom/session';
import SubHeading from 'view/components/text/sub-heading';
import EmptyList from 'view/components/empty-list';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import RenderLoading from 'view/components/render-loading';

const EmptyView = () => <EmptyList small message={'No reports found.'} />;

const defaultStyles = StyleSheet({
  overflowText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
});

@styled(defaultStyles)
@withModel(reportsModel)
@withModel(sessionModel)
@autobind
class ReportsList extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.reports.status !== nextProps.reports.status ||
      this.props.searchTerm !== nextProps.searchTerm
    );
  }

  componentDidMount() {
    this.props.reports.fetchList();
  }

  renderItem(item, index) {
    const { session, styles: s } = this.props;
    const canGenerate =
      session.checkUserHasPermission('reports.generate') ||
      session.checkUserHasPermission('reports.generate_for_office_group');
    if (!item.view_id) {
      item.view_id = 'no_view_id';
    }
    return (
      <Link
        to={ROUTES.REPORTS.GENERATE}
        params={{ reportId: item.id, viewId: item.view_id }}
      >
        {({ onClick }) => (
          <ListRow
            key={item.id}
            odd={index % 2}
            onClick={canGenerate ? onClick : _.noop}
          >
            <ListCell width={330}>{item.title}</ListCell>
            <ListCell width={'564px'}>
              <div {...s('overflowText')}>{item.description}</div>
            </ListCell>
          </ListRow>
        )}
      </Link>
    );
  }

  @decorate(_.memoize)
  getFilteredReportItems(searchTerm) {
    const { reports } = this.props;
    return _.mapValues(reports.items, (reportCategory) => {
      if (!reportCategory.reports) return [];
      return {
        ...reportCategory,
        reports: reportCategory.reports.filter((report) => {
          return (
            report.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            reportCategory.title
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          );
        })
      };
    });
  }

  renderHeader() {
    return (
      <ListRow isHeader>
        <ListCell width={330}>Report name</ListCell>
        <ListCell flex={1}>Description</ListCell>
      </ListRow>
    );
  }

  render() {
    const { reports, searchTerm } = this.props;

    return (
      <Fragment>
        <RenderLoading isLoading={_.get(reports, 'list.status') === 'loading'}>
          {reports.list.map((reportCategoryId) => {
            const allItems = this.getFilteredReportItems(searchTerm);
            const items = _.get(allItems, `${reportCategoryId}.reports`);

            return (
              <Box mb={PADDINGS.XL} key={reportCategoryId}>
                <section>
                  <SubHeading>
                    {_.get(reports, `items.${reportCategoryId}.title`)}
                  </SubHeading>
                  <Box pt={PADDINGS.XS}>
                    <List
                      items={items}
                      Header={this.renderHeader}
                      renderItem={this.renderItem}
                      LoadingView={() => <p>Loading</p>}
                      EmptyView={EmptyView}
                      ErrorView={() => <p>Something went wrong!</p>}
                    />
                  </Box>
                </section>
              </Box>
            );
          })}
        </RenderLoading>
      </Fragment>
    );
  }
}

export default ReportsList;
