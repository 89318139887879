import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import Select from './select';
import { StylesProvider, StyleSheet } from '@rexlabs/styling';

const timeComponentOverrides = {
  SelectOption: StyleSheet({
    container: {
      fontWeight: 400
    }
  }),
  SelectValue: StyleSheet({
    container: {
      fontWeight: 400
    }
  }),
  TextInput: StyleSheet({
    cosmeticWrapper: {
      display: 'flex',
      alignItems: 'center'
    }
  })
};

@autobind
class TimeSelect extends PureComponent {
  state = {
    options: this.getOptions(),
    value: this.props.value
  };

  getOptions() {
    const values = [];
    for (let hour = 1; hour <= 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const meridian = hour > 11 && hour < 24 ? 'PM' : 'AM';
        const label = `${hour < 13 ? hour : hour - 12}:${`${minute}`.padStart(
          2,
          '0'
        )} ${meridian}`;

        // TODO: This should be an object. Vivid component only allows number or string so this should be refactored.
        const value = `${String(hour).padStart(2, '0')}:${String(
          minute
        ).padStart(2, '0')}:00`;

        values.push({ label, value });
      }
    }

    return values;
  }

  handleSelection(e) {
    const { onChange } = this.props;
    this.setState({ value: e.target.value }, () => {
      const splitValue = e.target.value.split('-');
      const value = {
        hour: splitValue[0],
        minute: splitValue[1]
      };

      if (onChange) {
        onChange(e, value);
      }
    });
  }

  render() {
    const { name, placeholder, disabled } = this.props;
    const { options, value } = this.state;

    return (
      <StylesProvider components={timeComponentOverrides}>
        <Select
          disabled={disabled}
          value={value || this.props.value}
          multi={false}
          options={options}
          name={name}
          placeholder={placeholder}
          debounce={null}
          onChange={this.handleSelection}
        />
      </StylesProvider>
    );
  }
}

export default TimeSelect;
