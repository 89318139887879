import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const TYPE = 'customFields';

const actionCreators = {
  getDefinition: {
    request: () =>
      api.post('RexGroupAdminCustomFields::getDefinition', {
        module_name: 'account_users',
        include_hidden: true
      }),

    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  setValues: {
    request: (args) => api.post('RexGroupCustomFields::setFieldValues', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getRecordData: {
    request: (args) =>
      api.post('RexGroupCustomFields::getValuesKeyedByFieldId', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

export default new Generator(TYPE).createModel({
  actionCreators
});
