import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import Dialog from 'view/components/dialog';
import { autobind } from 'core-decorators';
import { Body, SubHeading } from 'view/components/text';
import Spinner from 'group-app/src/view/components/spinner';
import List from '@rexlabs/list';
import ListRow from 'group-app/src/view/components/list/row';
import ListCell from 'group-app/src/view/components/list/cell';

// Put styles in-line to work around Maximum update depth exceeded
@styled(
  StyleSheet({
    icon: {
      paddingRight: PADDINGS.XXS
      // color: COLORS.PRIMARY.YELLOW
    },
    cell: {
      width: '100%'
    }
  })
)
@styled(
  StyleSheet({
    heading: {
      // ...TEXTS.HEADERS.HEADING_2,
      paddingBottom: PADDINGS.XS
    },
    content: {
      // ...TEXTS.CONTENT.DEFAULT_TEXT,
      paddingBottom: PADDINGS.XS
    },
    buttonBar: {
      marginTop: PADDINGS.M
    },
    icon: {
      // color: COLORS.PRIMARY.RED,
      marginRight: PADDINGS.S,
      width: '50px',
      height: '50px'
    },
    listContainer: {
      boxShadow: '1px 1px 5px black',
      padding: '1rem'
    },
    highErrorRate: {
      width: '100%',
      backgroundColor: '#f4f4f1',
      display: 'flex',
      justifyContent: 'flex-start',
      borderBottom: '2px solid lightgrey'
    },
    highErrorRateIcon: {
      color: COLORS.RED,
      paddingLeft: PADDINGS.S,
      paddingTop: PADDINGS.M,
      ' > svg': {
        opacity: 1
      }
    },
    highErrorRateText: {
      // ...TEXTS.BODY,
      // fontWeight: FONT.WEIGHTS.SEMIBOLD,
      fontSize: '12px',
      fontStyle: 'none',
      paddingRight: PADDINGS['3XL'],
      paddingLeft: PADDINGS.XS,
      paddingBottom: PADDINGS.M,
      paddingTop: PADDINGS.M
    },
    emptyDialog: {
      display: 'flex',
      justifyContent: 'center',
      padding: PADDINGS.XL
    },
    warningIcon: {
      color: COLORS.YELLOW,
      height: 25,
      width: 25,
      marginRight: PADDINGS.XS,
      ' > svg': {
        opacity: 1
      }
    }
  })
)
@autobind
class PafErrorListDialog extends PureComponent {
  errors() {
    return this.props.errors.map((error, index) => ({
      ...error,
      index
    }));
  }

  highErrorRateWarning() {
    const { highErrorRate, styles: s } = this.props;

    if (!highErrorRate) {
      return null;
    }

    return (
      <Box {...s('highErrorRate')}>
        <Icon {...s('highErrorRateIcon')} type={ICONS.WARNING} />
        <Box>
          <Body>
            More than 10% of the rows in this file were unable to be processed
            due to errors If you are unable to source a better quality file,
            consider rolling back this file.
          </Body>
        </Box>
      </Box>
    );
  }

  renderItem(item, index) {
    const { styles: s } = this.props;
    return (
      <ListRow key={item.id} odd={index % 2}>
        <ListCell ellipsis width={'100%%'}>
          <Box flex={1} alignItems={'center'}>
            <Icon {...s('warningIcon')} type={ICONS.WARNING} />
            <Body>{`Row ${item.cell.row};  ${item.type.label}`}</Body>
            <Body bold> {item.cell.column}</Body>
          </Box>
        </ListCell>
      </ListRow>
    );
  }

  renderContent() {
    const { styles: s, errors } = this.props;

    if (errors.length === 0) {
      return (
        <Box {...s('emptyDialog')}>
          <SubHeading>No results found.</SubHeading>
        </Box>
      );
    }

    return (
      <List
        isLoading={false}
        items={this.errors()}
        renderItem={this.renderItem}
        LoadingView={() => <Spinner hasContainer />}
        ErrorView={() => <p>Something went wrong!</p>}
      />
    );
  }

  render() {
    const { errors, onCancel } = this.props;
    return (
      <Dialog
        title={`Errors (${errors.length} rows)`}
        closeDialog={onCancel}
        styles={{ content: { padding: 0 } }}
      >
        <Box style={{ maxHeight: '480px', overflow: 'scroll' }}>
          {this.highErrorRateWarning()}
          {this.renderContent()}
        </Box>
      </Dialog>
    );
  }
}

export default PafErrorListDialog;
