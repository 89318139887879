import { api } from 'shared/utils/api-client';
import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const TYPE = 'privilegeGroups';

const initialState = {
  status: 'loading',
  items: [],
  list: []
};

const actionCreators = {
  fetchList: {
    request: () =>
      api.post('SecurityPrivileges::describePrivilegeGroups', {
        force_current_app_only: true
      }),

    reduce: {
      success: (state, action) => ({
        ...state,
        items: _.keyBy({ ...action.payload.data.result }, 'friendly_id'),
        list: action.payload.data.result.map((item) => item.friendly_id),
        status: 'loaded'
      }),
      failure: (state) => ({ ...state, status: 'error' })
    }
  }
};

const selectors = {
  items: (state) => state.privilegeGroups.items,
  list: (state) => state.privilegeGroups.list,
  status: (state) => state.privilegeGroups.status
};

export default new Generator(TYPE).createModel({
  actionCreators,
  initialState,
  selectors
});
