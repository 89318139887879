import React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@rexlabs/box';

export default ({ title, children }) => (
  <Box pb={'100px'}>
    <Helmet>
      <title>{title} - Rex Group App</title>
    </Helmet>
    {children}
  </Box>
);
