import React, { PureComponent, Children, Fragment } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import Spinner from 'view/components/spinner';

export class ButtonBar extends PureComponent {
  static defaultProps = {
    hasPadding: true
  };

  render() {
    const { children, isLoading, hasPadding, ...props } = this.props;
    return (
      <Box
        justifyContent='space-between'
        alignItems='center'
        height='34px'
        mt={hasPadding ? '15px' : 0}
        {...props}
      >
        {isLoading ? (
          <Fragment>
            <span />
            <Box ml={PADDINGS.S} mr={PADDINGS.S}>
              <Spinner size={'small'} />
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <span />
            <Box style={{ display: 'inline-flex' }} alignItems='center'>
              {Children.map(children, (child) => (
                <Box ml={PADDINGS.XXS}>{child}</Box>
              ))}
            </Box>
          </Fragment>
        )}
      </Box>
    );
  }
}

export default ButtonBar;
