import { touchEtag } from '@rexlabs/model-generator';
import { Generator } from 'shared/utils/models';
import {
  api,
  transformList,
  transformAutocomplete,
  transformListArgs,
  transformAutocompleteArgs
} from 'shared/utils/api-client';
import _ from 'lodash';

let lastSearch = null;

const config = {
  entities: {
    api: {
      createItem: (type, args) =>
        api.post(`${_.upperFirst(type)}::create`, args),
      fetchList: (type, args) => {
        const { method = 'search', ...rest } = args;
        return api
          .post(
            `${_.upperFirst(type)}::${method}`,
            method === 'search'
              ? transformListArgs({ type, args: rest })
              : transformAutocompleteArgs({ args: rest })
          )
          .then(method === 'search' ? transformList : transformAutocomplete)
          .then((res) => {
            // TODO: remove this hack way of setting a variable that the selectors have access to, I don't know how the correct way to update the store from here
            lastSearch = res;
            return res;
          });
      }
    }
  }
};

const actionCreators = {
  getAllIds: {
    request: (
      { criteria } //eslint-disable-line
    ) =>
      api
        .post('AccountUsers::search', {
          criteria,
          result_format: 'ids',
          limit: 10000
        })
        .then((response) => response.data.result.rows),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  updateSelectedIds: {
    reduce(state, action) {
      return { ...state, selectedIds: action.payload };
    }
  },
  setPrivilegesForUser: {
    request: (
      { id, extra_privilege_ids } //eslint-disable-line
    ) =>
      api
        .post('SecurityPrivileges::setPrivilegesForUser', {
          extra_privilege_ids,
          user_id: id
        })
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  getPrivilegesForUser: {
    request: (
      { id } //eslint-disable-line
    ) =>
      api
        .post('SecurityPrivileges::getPrivilegesForUser', {
          user_id: id
        })
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  invite: {
    request: (payload) => api.post('AccountUsers::invite', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  resetPassword: {
    request: (payload) => api.post('AccountUsers::resetPassword', payload),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  purgeItem: {
    request: ({ id, ...args }) =>
      api
        .post('AccountUsers::purge', { ...args, id: id })
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  enable: {
    request: (payload) => api.post('AccountUsers::enable', payload),
    reduce: {
      initial: _.identity,
      success: setUserAccountStatusTo('active'),
      failure: _.identity
    }
  },
  disable: {
    request: (payload) => api.post('AccountUsers::disable', payload),
    reduce: {
      initial: _.identity,
      success: setUserAccountStatusTo('disabled'),
      failure: _.identity
    }
  }
};

const selectors = {
  lastSearch: () => lastSearch,
  selectedIds: (state) => _.get(state, 'entities.accountUsers.selectedIds')
};

const accountUsersModel = new Generator(
  'accountUsers',
  config
).createEntityModel({
  selectors,
  actionCreators
});

function setUserAccountStatusTo(status) {
  return (state, action) => {
    const id = action.meta.originalPayload.user_id;
    const item = state.items[id];
    const result = {
      ...state,
      items: {
        ...state.items,
        // Because of the memoization that happens within model generator, the
        // item's ETag needs to be touched. If this isn't done, the change made
        // to the user_account_status will be ignored.
        [id]: touchEtag({
          ...item,
          data: {
            ...item.data,
            user_account_status: status
          }
        })
      }
    };
    return result;
  };
}

// TEMPORARY SOLUTION
// For now we will define select specific behaviour in the models, to control
// all EntitySelects that use this model
accountUsersModel.select = {
  autocomplete: (searchTerm) =>
    !searchTerm
      ? api
          .post('AccountUsers::search', {
            limit: 100,
            order_by: { first_name: 'ASC', last_name: 'ASC' }
          })
          .then(({ data }) =>
            (_.get(data, 'result.rows') || []).map((user) => ({
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
              data: user,
              model: accountUsersModel
            }))
          )
      : api
          // User search endpoint because AccountUsers::autocomplete
          // doesn't work right now. Caveat is that it is not searchable
          // and limited to 100 users
          // TODO: fix
          .post('SystemValues::autocompleteCategoryValues', {
            list_name: 'account_users',
            search_string: searchTerm
          })
          .then(({ data }) =>
            _.map(_.get(data, 'result') || [], (user) => ({
              value: user.id,
              label: user.text,
              data: user,
              model: accountUsersModel
            }))
          )
};

export default accountUsersModel;
