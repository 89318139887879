import { useEffect } from 'react';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import sessionModel from 'data/models/custom/session';
import { useModelState } from '@rexlabs/model-generator';
import _ from 'lodash';

export function useInternalOnlyRedirect() {
  const session = useModelState(sessionModel);
  useEffect(() => {
    if (String(_.get(session, 'userProfile.id')) !== '102') {
      push(ROUTES.HOME);
    }
  }, [session]);
}
