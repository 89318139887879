import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { withModel } from '@rexlabs/model-generator';
import accountUsersModel from 'data/models/entities/account-users';

import { autobind } from 'core-decorators';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';

import { Body } from 'view/components/text';

import { PADDINGS } from 'src/theme';
import Box from '@rexlabs/box';

@withErrorDialog
@withModel(accountUsersModel)
@autobind
class UserDisableDialog extends PureComponent {
  state = {
    isLoading: false
  };

  disableUser() {
    const { closeModal, accountUsers, errorDialog } = this.props;
    this.setState({ isLoading: true });
    accountUsers
      .disable({ user_id: _.get(this.props, 'match.params.userId') })
      .then(closeModal)
      .catch(errorDialog.open);
  }

  render() {
    const { closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog title={'Disable User'} height={200} closeDialog={closeModal}>
        <Box mt={PADDINGS.XS} mb={PADDINGS.M}>
          <Body>
            Are you sure you want to disable this user? The person attached to
            this user will not be able to log into the system.
          </Body>
        </Box>
        <ButtonBar width={'auto'} isLoading={isLoading}>
          <TextButton blue onClick={closeModal}>
            Cancel
          </TextButton>
          <PrimaryButton blue onClick={this.disableUser}>
            Disable User
          </PrimaryButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default UserDisableDialog;
