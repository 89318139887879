import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { autobind } from 'core-decorators';
import React, { PureComponent } from 'react';
import { TextInput } from '@rexlabs/text-input';
import Icon, { ICONS } from 'shared/components/icon';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import Tooltip, { PLACEMENTS } from '@rexlabs/tooltip';
import { createKey } from 'shared/utils/react';
import _ from 'lodash';

const TextInputOverrides = StyleSheet({
  wrapper: {
    flex: '1 1 auto'
  },
  container: {
    borderRadius: '0',
    border: 'none',
    padding: '0'
  },
  cosmeticWrapper: {},
  input: {
    color: '#424242',
    border: 'none',
    lineHeight: '22px',
    fontSize: '17px',
    height: '17px',
    padding: '0',
    margin: '0',

    '::placeholder': {
      // ...TEXTS.GLOBAL_NAVIGATION.SEARCH_PLACEHOLDER_IDLE,
      color: '#7F7F7F',
      opacity: 1
    }
  }
});

const agencyListStyles = {
  container: {
    width: '340px',
    display: 'flex',
    flexDirection: 'column'
  },
  agencyName: {
    color: '#424242',
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '22px',
    padding: '14px 15px 14px 20px',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '50px',

    '&:hover': {
      backgroundColor: '#F9FAFB',
      color: '#000000'
    }
  },
  filterContainer: {
    paddingBottom: '5px',
    margin: '0 20px'
  },
  filterWrapper: {
    color: '#7F7F7F',
    fontSize: '17px',
    lineHeight: '22px',
    fontWeight: '500',
    display: 'flex',
    paddingTop: '20px',
    paddingBottom: '12px'
  },
  separator: {
    width: 'inherit',
    height: '1px',
    opacity: 0.5,
    backgroundColor: '#E1E1E1'
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',

    '& svg': {
      color: '#79818D'
    }
  },
  agenciesContainer: {
    overflow: 'auto',
    overflowX: 'hidden',
    maxHeight: '340px',
    display: 'flex',
    flexDirection: 'column',

    '::-webkit-scrollbar': {
      width: '6px'
    },

    '::-webkit-scrollbar-thumb': {
      background: '#B2B2B2',
      borderRadius: '4px'
    }
  },
  noMaxHeight: {
    maxHeight: 'none',
    height: 'calc(100vh - 92px)' // Remove the height of the filter and title
  },
  closeContainer: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    padding: '21px'
  },
  closeButton: {
    cursor: 'pointer',
    width: 'auto',
    height: '18px',

    '& > svg': {
      height: 'inherit',
      width: 'inherit'
    }
  },
  agencyTitleContainer: {
    width: '100%',
    // minHeight: HEIGHTS.SHELL_HEADER.MOBILE,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    // borderBottomColor: COLORS.BACKGROUNDS.SAND,
    display: 'flex',
    alignItems: 'center'
  },
  agencyTitle: {
    paddingLeft: '25px',
    fontSize: '18px',
    lineHeight: '26px',
    // color: COLORS.DARK_GREY,
    maxWidth: '74%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
};

@styled(StyleSheet(agencyListStyles))
@autobind
class AgencyList extends PureComponent {
  state = { term: '' };

  setSearchTerm(event) {
    this.agenciesContainer.scrollTop = 0;
    this.setState({ term: event.target.value });
  }

  handleAccountSwitch(agencyId) {
    const { session } = this.props;
    // TODO: loading state here
    session.switchToAccount({ id: agencyId });
  }

  renderAgencyTitle() {
    const { styles: s, currentAgencyName } = this.props;

    return (
      <div {...s('agencyTitleContainer')}>
        <span {...s('agencyTitle')}>{currentAgencyName}</span>
      </div>
    );
  }

  setListRef(ref) {
    this.agenciesContainer = ref;
  }

  render() {
    const { styles: s, isMobile, onClose, session } = this.props;
    const agencies = session.accounts;

    const shouldShowFilter = agencies.length > 5;
    const prefixIcon = <Icon type={ICONS.SEARCH} {...s('searchIcon')} />;

    return (
      <Box {...s('container')}>
        {isMobile && (
          <div {...s('closeContainer')} onClick={onClose}>
            <Icon type={ICONS.CLOSE} {...s('closeButton')} />
          </div>
        )}
        {isMobile && this.renderAgencyTitle()}
        {shouldShowFilter && (
          <Box {...s('filterContainer')}>
            <Box {...s('filterWrapper')}>
              <TextInput
                setRef={this.props.setInputFocus}
                prefix={prefixIcon}
                name='agency-search'
                autoComplete={false}
                styles={TextInputOverrides}
                placeholder='Filter Agencies'
                onChange={this.setSearchTerm}
              />
            </Box>
            <Box {...s('separator')} />
          </Box>
        )}
        <div
          ref={this.setListRef}
          {...s('agenciesContainer', { noMaxHeight: isMobile })}
        >
          {agencies
            .filter((agency) => {
              return (
                (`${agency.name}`
                  .toLowerCase()
                  .includes(this.state.term.toLowerCase()) ||
                  agency.account_id.toString() ===
                    this.state.term.toString()) &&
                agency.application.toLowerCase() === 'rexgroup'
              );
            })
            .map((agency) => {
              const agencyName = `${agency.name}`;
              const onClickHandler = () => {
                this.props.onClose();
                this.handleAccountSwitch(agency.account_id);
              };
              const key = createKey(`${agencyName}-${agency.account_id}`);
              return (
                <span key={key} onClick={onClickHandler} {...s('agencyName')}>
                  {agencyName}
                </span>
              );
            })}
        </div>
      </Box>
    );
  }
}

const tooltipOverrides = {
  Tooltip: StyleSheet({
    container: {
      whiteSpace: 'nowrap',
      maxWidth: '100%'
    },
    tooltip: {
      borderRadius: '3px',
      padding: '5px'
    },
    arrow: {
      borderRadius: '2px',
      margin: '-4px'
    },
    overlay: {}
  }),

  Tether: StyleSheet({
    popperContainer: {
      zIndex: '26 !important'
    }
  })
};

const agencySelectorStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '26px',
    color: 'white',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '26px',
    cursor: 'pointer',
    fontFamily: 'proxima-nova, lato, arial, serif'
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  chevron: {
    minWidth: '34px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '7px'
  }
};

@withModel(sessionModel)
@styled(StyleSheet(agencySelectorStyles))
@autobind
class AgencySelector extends PureComponent {
  state = { isMenuOpen: false };
  currentAgencyName = _.get(this.props, 'session.accountSettings.name');

  static setInputFocus(element) {
    setTimeout(() => {
      element && element.focus();
    }, 250);
  }

  toggleMenuState() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  renderContent() {
    const { isMobile, session } = this.props;

    return (
      <AgencyList
        isMobile={isMobile}
        onClose={this.toggleMenuState}
        currentAgencyName={this.currentAgencyName}
        setInputFocus={AgencySelector.setInputFocus}
        session={session}
      />
    );
  }

  render() {
    const { styles: s } = this.props;
    return (
      <StylesProvider components={tooltipOverrides}>
        <Tooltip
          placement={PLACEMENTS.BOTTOM_START}
          offset={'0px'}
          openOn={'CLICK'}
          closeOn={'CLICK'}
          hoverTimeout={250}
          Content={this.renderContent}
          setOpen={this.toggleMenuState}
          isOpen={this.state.isMenuOpen}
          distance={'14px'}
        >
          <Box {...s('container')}>
            <span {...s('name')}>{this.currentAgencyName}</span>
            <Icon tabIndex={'-1'} type={ICONS.CHEVRON} {...s('chevron')} />
          </Box>
        </Tooltip>
      </StylesProvider>
    );
  }
}

export default AgencySelector;
