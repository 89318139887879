import React, { PureComponent } from 'react';
import { StyleSheet } from '@rexlabs/styling';
import { Field } from '@rexlabs/form';
import { COLORS, PADDINGS } from 'theme';

import { Label } from 'view/components/text';

const fieldStyles = StyleSheet({
  container: {
    flexShrink: 0
  },

  containerError: {
    borderColor: COLORS.RED
  },

  field: {
    position: 'relative',
    marginTop: PADDINGS.S,

    '&:first-child': {
      marginTop: 0
    }
  },

  fieldLabel: {
    marginBottom: PADDINGS.XXS
  },

  inlineFieldLabel: {},

  error: {
    display: 'none'
  }
});

class FormField extends PureComponent {
  render() {
    const { isInlineLabel, ...rest } = this.props;
    return (
      <Field
        key={rest.name}
        styles={fieldStyles}
        isInlineLabel={isInlineLabel}
        {...rest}
        FieldLabel={(props) => <Label {...props} />}
      />
    );
  }
}

export default FormField;
