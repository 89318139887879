import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { query, withQuery } from '@rexlabs/model-generator';
import { ReactForms, Form, FormField } from 'view/components/form/index';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button/index';
import { createValidationRules } from 'shared/utils/form';
import EntitySelect from 'view/components/input/select/entity-select';
import officeGroupsModel from 'data/models/entities/account-groups';
import { accountUsersListQuery } from 'view/components/lists/users';
import Option from 'view/components/input/select/options/core';
import Box from '@rexlabs/box';
import { Body } from 'view/components/text';
import { COLORS, UTILS } from 'src/theme';
import { ICONS } from 'shared/components/icon';
import { accountGroupUsersQuery } from 'view/screens/users/details';

const userQuery = query`{
  ${officeGroupsModel} (id: ${(p) => _.get(p, 'match.params.groupId')}) {
    id
    name
  }
}`;

@styled(
  StyleSheet({
    optionContainer: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: COLORS.BACKGROUND,
        borderColor: COLORS.GREY_LIGHT
      }
    },
    selectedOptionContainer: {
      height: '5px',
      overflow: 'visible'
    },
    optionInfo: {
      ...UTILS.TRUNCATE
    }
  })
)
@autobind
class SelectOption extends PureComponent {
  render() {
    const { styles: s, option, pluckLabel, selected } = this.props;
    const groupAccounts = _.get(option, 'data.related.account_group_accounts');

    return (
      <Option {...this.props}>
        <Box p={8} {...s('optionContainer')}>
          <Body medium blue={selected}>
            <Box
              pr={10}
              mb={5}
              mt={8}
              alignItems={'center'}
              justifyContent={'space-between'}
              {...s('selectedOptionContainer')}
            >
              {pluckLabel(option)}
              {selected && <ICONS.TICK />}
            </Box>
          </Body>
          <Box pr={30}>
            <Body small grey {...s('optionInfo')}>
              {groupAccounts.map((account, index, array) => (
                <span key={account.account.id}>
                  {account.account.text}
                  {index < array.length - 1 && ', '}
                </span>
              ))}
            </Body>
          </Box>
        </Box>
      </Option>
    );
  }
}

const OfficeGroupMultiSelect = (props) => (
  <EntitySelect
    searchOnMount
    multi
    models={[officeGroupsModel]}
    Option={(optionProps) => <SelectOption {...optionProps} />}
    OptionSelected={(optionProps) => (
      <SelectOption {...optionProps} selected={true} />
    )}
    shouldCloseOnSelect={false}
    {...props}
  />
);

const OfficeGroupSingleSelect = (props) => (
  <EntitySelect
    searchOnMount
    models={[officeGroupsModel]}
    Option={SelectOption}
    {...props}
  />
);

@withErrorDialog
@withQuery(userQuery)
@withQuery(accountUsersListQuery)
@withQuery(accountGroupUsersQuery)
@autobind
class UserAssignOfficeGroupsDialog extends PureComponent {
  handleSubmit(formData) {
    const {
      closeModal,
      accountGroupUsers,
      accountUsers: { selectedIds, refreshList },
      match,
      errorDialog
    } = this.props;

    if (selectedIds && selectedIds.length > 0) {
      this.handleMultiSubmit(formData);
      return;
    }

    const existingGroups = this.getExistingGroups();

    const createActions = formData.office_groups
      .filter((group) => !existingGroups.includes(group))
      .map((group) => {
        return accountGroupUsers.createItem({
          data: {
            user_id: match.params.userId,
            account_group_id: group
          }
        });
      });

    const deleteActions = existingGroups
      .filter((group) => !formData.office_groups.includes(group))
      .map((group) => {
        const id = accountGroupUsers.list.items.find(
          (item) => item.account_group.id === group
        ).id;

        return accountGroupUsers.purgeItem({
          id
        });
      });

    return Promise.all([...createActions, ...deleteActions])
      .then(() => {
        closeModal();
        refreshList();
        accountGroupUsers.refreshList();
      })
      .catch(errorDialog.open);
  }

  handleMultiSubmit(formData) {
    const {
      closeModal,
      accountGroupUsers,
      accountUsers: { selectedIds, refreshList },
      errorDialog
    } = this.props;

    const submitPromises = selectedIds.map(
      (userId) =>
        new Promise((resolve, reject) => {
          accountGroupUsers
            .createItem({
              data: {
                user_id: userId,
                account_group_id: formData.office_group
              }
            })
            .then(resolve)
            .catch((e) => {
              if (e.message.includes('already exists in this account group')) {
                resolve();
              } else {
                reject(e);
              }
            });
        })
    );

    return Promise.all(submitPromises)
      .then(() => {
        closeModal();
        refreshList();
        accountGroupUsers.refreshList();
      })
      .catch(errorDialog.open);
  }

  getExistingGroups() {
    const {
      accountUsers: { selectedIds },
      accountGroupUsers: {
        list: { items }
      }
    } = this.props;

    if (selectedIds && selectedIds.length > 0) {
      return [];
    }

    return items.map((item) => item.account_group.id);
  }

  render() {
    const {
      closeModal,
      accountUsers: { selectedIds },
      accountGroupUsers
    } = this.props;
    const multipleUsers = selectedIds && selectedIds.length > 0;
    const existing = this.getExistingGroups();

    const isLoading = accountGroupUsers.list.status === 'loading';

    return (
      <Dialog
        title={
          multipleUsers
            ? 'Assign users to Office Group'
            : existing.length > 0
            ? 'Manage Office Groups'
            : 'Assign user to Office Groups'
        }
        height={200}
        isLoading={isLoading}
        closeDialog={closeModal}
      >
        {existing.length > 0 && (
          <Box mb={20}>
            <Body grey>
              The Office Groups this user is assigned to are shown below.
              Removing this user from an Office Group will remove all their
              privilege sets for that group.
            </Body>
          </Box>
        )}
        <ReactForms
          handleSubmit={this.handleSubmit}
          initialValues={{ office_groups: existing }}
          validate={
            existing.length === 0
              ? createValidationRules({
                  office_groups: 'required'
                })
              : null
          }
        >
          {(form) => (
            <Form style={{ width: '100%' }}>
              {multipleUsers ? (
                <FormField
                  name='office_group'
                  label='Office Group'
                  Input={OfficeGroupSingleSelect}
                />
              ) : (
                <FormField
                  name='office_groups'
                  label='Office Groups'
                  Input={OfficeGroupMultiSelect}
                />
              )}
              <ButtonBar isLoading={form.isSubmitting} width={'100%'}>
                <TextButton blue onClick={closeModal}>
                  Cancel
                </TextButton>
                <PrimaryButton blue onClick={form.submitForm}>
                  {multipleUsers
                    ? 'Assign users'
                    : existing.length > 0
                    ? 'Save'
                    : 'Assign user'}
                </PrimaryButton>
              </ButtonBar>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default UserAssignOfficeGroupsDialog;
