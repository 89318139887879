import { api } from 'shared/utils/api-client';
import _ from 'lodash';
import { Generator } from 'shared/utils/models';

const TYPE = 'privilegeSets';

const initialState = {
  status: 'loading',
  items: [],
  list: []
};

const actionCreators = {
  fetchList: {
    request: () =>
      api.post('SecurityPrivilegeSets::getSubAccountPrivilegeSets'),

    reduce: {
      success: (state, action) => ({
        ...state,
        items: _.keyBy({ ...action.payload.data.result }, 'id'),
        list: action.payload.data.result.map((item) => item.id),
        status: 'loaded'
      }),
      failure: (state) => ({ ...state, status: 'error' })
    }
  }
};

const selectors = {
  items: (state) => state.privilegeSets.items,
  list: (state) => state.privilegeSets.list,
  status: (state) => state.privilegeSets.status
};

export default new Generator(TYPE).createModel({
  actionCreators,
  initialState,
  selectors
});
