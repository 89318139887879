import React, { PureComponent } from 'react';
import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Link } from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import ROUTES from 'routes';
import { COLORS, PADDINGS } from 'theme';
import Icon, { ICONS } from 'shared/components/icon';
import sessionModel from 'data/models/custom/session';

const defaultStyles = StyleSheet({
  container: {
    width: '200px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    zIndex: 9,
    height: 'calc(100vh - 70px)',
    boxShadow: '0 0 14px rgba(0,0,0,0.08)'
  },

  list: {
    listStyleType: 'none',
    padding: 0,
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },

  listItem: {
    '& > *': {
      width: '100%',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '15px',
      color: COLORS.PRIMARY_DARK,
      cursor: 'pointer',
      textDecoration: 'none',
      fontWeight: 500,
      paddingLeft: '50px',
      position: 'relative'
    },

    '& > *:hover': {
      backgroundColor: COLORS.GREY_LIGHTEST,
      color: 'black'
    },

    '& .active:before': {
      content: '" "',
      width: '3px',
      position: 'absolute',
      left: 0,
      height: '44px',
      backgroundColor: COLORS.BLUE
    },
    '& span': {
      padding: 0
    }
  },
  icon: {
    position: 'absolute',
    left: PADDINGS.S,
    top: '20px',
    height: 'auto',
    width: 'auto',
    padding: 0
  },

  bottom: {
    marginTop: 'auto'
  }
});

@styled(defaultStyles)
@withModel(sessionModel)
export default class MainMenu extends PureComponent {
  render() {
    const { session, styles: s } = this.props;

    const canViewReports = session.checkUserHasPermission(
      'reports.view_section'
    );
    const canViewAnnouncements = session.checkUserHasPermission(
      'announcements.view_section'
    );
    const canViewUsers = session.checkUserHasPermission('users.view_section');
    const canViewGroups = session.checkUserHasPermission(
      'account_groups.view_section'
    );
    const canViewAdmin = session.checkUserHasPermission('admin.view_section');

    const isUK = /^uk$/i.test(_.get(session, 'accountSettings.locale.code'));

    return (
      <menu {...s('container')}>
        <ul {...s('list')}>
          {canViewReports && (
            <li {...s('listItem')}>
              <Link to={ROUTES.REPORTS}>
                <Icon
                  {...s('icon')}
                  style={{ left: '14px', top: '19px' }}
                  type={ICONS.DOCUMENT}
                />
                <span>Reports</span>
              </Link>
            </li>
          )}
          {canViewAnnouncements && (
            <li {...s('listItem')}>
              <Link {...s('listItem')} to={ROUTES.ANNOUNCEMENTS}>
                <span>Announcements</span>
                <Icon
                  {...s('icon')}
                  style={{ left: '18px', top: '24px' }}
                  type={ICONS.ANNOUNCEMENTS}
                />
              </Link>
            </li>
          )}
          <li {...s('listItem')}>
            <Link to={ROUTES.SEARCH}>
              <Icon
                {...s('icon')}
                style={{ left: '19px', top: '22px' }}
                type={ICONS.SEARCH}
              />
              <span>Search</span>
            </Link>
          </li>
          {canViewUsers && (
            <li {...s('listItem')}>
              <Link to={ROUTES.USERS}>
                <Icon {...s('icon')} type={ICONS.CONTACTS} />
                <span>Users</span>
              </Link>
            </li>
          )}
          {canViewGroups && (
            <li {...s('listItem')}>
              <Link to={ROUTES.OFFICE_GROUPS}>
                <Icon {...s('icon')} type={ICONS.BUILDING} />
                <span>Office Groups</span>
              </Link>
            </li>
          )}
          {/* Billing section internal only for now */}
          {canViewAdmin && session.isSupportUser && (
            <li {...s('listItem')}>
              <Link to={ROUTES.BILLING}>
                <Icon {...s('icon')} type={ICONS.FINANCIALS_DOLLAR_12} />
                <span>Billing</span>
              </Link>
            </li>
          )}
          {canViewAdmin && isUK && (
            <li {...s('listItem', 'bottom')}>
              <Link to={ROUTES.ADMIN}>
                <Icon {...s('icon')} type={ICONS.ADMIN} />
                <span>Admin</span>
              </Link>
            </li>
          )}
        </ul>
      </menu>
    );
  }
}
