import React, { ComponentProps, PropsWithChildren, ReactElement } from 'react';
import Text from '@rexlabs/text';
import { map } from 'shared/utils/text';

interface BodyProps {
  grey?: boolean;
  blue?: boolean;
  medium?: boolean;
  underlined?: boolean;
  bold?: boolean;
  clickable?: boolean;
  small?: true;
  is?: string; // TODO: fix to align with @rexlabs/text once upgraded
}

export default ({
  grey,
  blue,
  medium,
  underlined,
  bold,
  clickable,
  small,
  ...props
}: PropsWithChildren<BodyProps>) => (
  <Text
    is='p'
    {...props}
    type={map({
      BODY: true,
      GREY: grey,
      BLUE: blue,
      MEDIUM: medium,
      BOLD: bold,
      UNDERLINED: underlined,
      CLICKABLE: clickable,
      BODY_SMALL: small
    })}
  />
);
