import { Generator } from 'shared/utils/models';
import _ from 'lodash';

const TYPE = 'filters';

const initialState = {};

const actionCreators = {
  setFilters: {
    reduce(state, action) {
      const { listId, filterValues } = action.payload;

      return {
        ...state,
        [listId]: {
          ...state[listId],
          ...filterValues
        }
      };
    }
  },
  clearFilters: {
    reduce(state, action) {
      const { listId } = action.payload;
      return _.omit({ ...state }, listId);
    }
  }
};

const selectors = {
  values: (state) => state.filters
};

export default new Generator(TYPE).createModel({
  initialState,
  actionCreators,
  selectors
});
