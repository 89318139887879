import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { PADDINGS, COLORS } from 'src/theme';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Body } from 'view/components/text';
import NextPageIcon from 'assets/icons/next-page.svg';
import SecondaryButton from 'shared/components/button/secondary';
import PageInput from 'view/components/input/page-input';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: PADDINGS.S
  },

  buttons: {
    display: 'flex',
    flexDirection: 'row'
  },

  button: {
    // HACK: to increase specificity
    '&&': {
      width: '36px',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${COLORS.TABLE_BORDER.DARK}`,
      borderRadius: '0',
      cursor: 'pointer'
    }
  },

  buttonLeft: {
    '&&': {
      marginRight: '-1px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px'
    }
  },

  buttonRight: {
    '&&': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    }
  },

  disabled: {
    '&&': {
      cursor: 'default',
      border: `1px solid ${COLORS.GREY_LIGHT}`
    }
  }
});

const previousPageStyles = {
  width: '6px',
  height: 'auto',
  transform: 'rotate(180deg)'
};

const nextPageStyles = {
  width: '6px',
  height: 'auto'
};

@styled(defaultStyles)
class Pagination extends PureComponent {
  static defaultProps = {
    hasPadding: true
  };

  render() {
    if (!this.props.list.pagination || !this.props.list.args) return null;
    const {
      styles: s,

      fetchPage,

      list: {
        args = {},
        pagination: { totalItems, currentPage, itemsPerPage, endReached }
      },
      ...props
    } = this.props;

    // TODO: Find out why we have to rely on the args prop instead of the pagination values??
    const limit = args.limit || itemsPerPage;
    const page = args.page || currentPage || 1;
    const startingItem = totalItems === 0 ? 0 : 1 + (page - 1) * limit;
    const endingItem = page * limit > totalItems ? totalItems : page * limit;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <Box {...s('container')} {...props}>
        <div {...s('summary')}>
          <Body>
            {startingItem} – {endingItem} of {totalItems} items
          </Body>
        </div>
        <div {...s('buttons')}>
          <SecondaryButton
            {...s('button', 'buttonLeft', { disabled: page === 1 })}
            onClick={page !== 1 ? () => fetchPage(1) : undefined}
          >
            <NextPageIcon style={previousPageStyles} />
            <NextPageIcon style={previousPageStyles} />
          </SecondaryButton>
          <SecondaryButton
            {...s('button', { disabled: page === 1 })}
            onClick={page !== 1 ? () => fetchPage(page - 1) : undefined}
          >
            <NextPageIcon style={previousPageStyles} />
          </SecondaryButton>
          <PageInput
            changePage={(pageValue) => {
              if (pageValue > totalPages) {
                pageValue = totalPages;
              }
              return fetchPage(pageValue);
            }}
            page={page}
            totalPages={totalPages}
          />

          <SecondaryButton
            {...s('button', {
              disabled: endReached
            })}
            onClick={!endReached ? () => fetchPage(page + 1) : _.noop}
          >
            <NextPageIcon style={nextPageStyles} />
          </SecondaryButton>
          <SecondaryButton
            {...s('button', 'buttonRight', {
              disabled: endReached
            })}
            onClick={!endReached ? () => fetchPage(totalPages) : _.noop}
          >
            <NextPageIcon style={nextPageStyles} />
            <NextPageIcon style={nextPageStyles} />
          </SecondaryButton>
        </div>
      </Box>
    );
  }
}

export default Pagination;
