import React, { PureComponent } from 'react';
import { withModel } from '@rexlabs/model-generator/lib/connectors';
import { Heading } from 'view/components/text';
import _ from 'lodash';
import { TabsStateful } from '@rexlabs/tabs';
import { connect } from 'react-redux';
import { autobind, decorate } from 'core-decorators';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import PAFUpload from 'view/screens/admin/paf';
import Screen from 'view/components/screen';
import sessionModel from 'data/models/custom/session';

@connect()
@withErrorDialog
@withModel(sessionModel)
@autobind
class AdminDetailsScreen extends PureComponent {
  @decorate(_.memoize)
  decorateTab(TabComponent) {
    return (props) => <TabComponent {...this.props} {...props} />;
  }

  render() {
    const { session } = this.props;
    const canManagePAF = session.checkUserHasPermission('admin.manage_paf');

    return (
      <Screen title={'Admin'}>
        <Heading>Admin</Heading>
        <TabsStateful
          alwaysRenderAll
          items={
            canManagePAF
              ? [
                  {
                    name: 'paf',
                    label: 'PAF Upload',
                    Tab: this.decorateTab(PAFUpload)
                  }
                ]
              : []
          }
        />
      </Screen>
    );
  }
}

export default AdminDetailsScreen;
