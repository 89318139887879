import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    padding: '10px 15px',
    fontSize: '14px',
    lineHeight: '21px'
  },

  containerEllipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
});

@styled(defaultStyles)
class ListCell extends PureComponent {
  render() {
    const { styles: s, ellipsis, ...props } = this.props;
    return (
      <Box
        {...s('container', {
          containerEllipsis: ellipsis
        })}
        {...props}
      />
    );
  }
}

export default ListCell;
