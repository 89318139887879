import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withWhereabouts } from '@rexlabs/whereabouts';

import Analytics from 'shared/utils/vivid-analytics';

@withWhereabouts
@autobind
class ErrorBoundary extends PureComponent {
  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    console.error('Error caught');
    console.error(error);
    console.error(errorInfo);
    this.setState({ error });

    Analytics.error({
      error,
      properties: {
        metaData: {
          reactTrace: errorInfo,
          caughtBy: 'components/error-boundary'
        }
      }
    });
  }

  clearError() {
    this.setState({ error: null });
  }

  componentDidUpdate(prevProps) {
    prevProps.whereabouts.path !== this.props.whereabouts.path &&
      this.clearError();
  }

  render() {
    const { children, Placeholder } = this.props;
    return this.state.error ? (
      <Placeholder error={this.state.error} clearError={this.clearError} />
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
