import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'theme';
import { Form, FormField, withForm } from 'view/components/form';
import { Grid, Column } from 'shared/components/grid';
import Box from '@rexlabs/box';
import { Select } from 'view/components/input/select';

import { PrimaryButton } from 'shared/components/button';
import Dialog from 'view/components/dialog';
import Option from 'view/components/input/select/options/core';
import TextButton from 'shared/components/button/text';
import ButtonBar from 'shell/src/view/components/button-bar/button-bar';
import { SubHeading, Body } from 'view/components/text/';

const formStyles = StyleSheet({
  info: {
    paddingBottom: PADDINGS.XL
  }
});

const rowStyles = StyleSheet({
  row: {
    paddingTop: PADDINGS.XS,
    paddingBottom: PADDINGS.XS
  },
  container: {
    backgroundColor: '#FFFFFF',
    // ...TEXTS.SETTINGS_NAVIGATION.MENU_ITEM,
    padding: '4px',
    paddingLeft: '10px',

    '&:hover': {
      backgroundColor: '#CAE8F8'
    }
  }
});

const fieldLabelStyles = StyleSheet({
  container: {
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    justifyContent: 'center'
  }
});

function getValue(options, expectedValue) {
  return options.find((option) => option === expectedValue) || '';
}

const columnMatchingForm = {
  name: 'pafColumnMatching',
  validate: (values) => {
    const valuesArray = Object.values(values).filter((value) => value !== '');
    const valuesSet = new Set(valuesArray);

    if (valuesArray.length !== valuesSet.size) {
      return {
        postcode: 'Please assign columns uniquely.'
      };
    }
  },
  mapPropsToValues: (props) => {
    const { sourceColumns, destinationColumns } = props;

    return destinationColumns.reduce((prev, next) => {
      prev[next.id] = getValue(sourceColumns.columns, next.label);
      return prev;
    }, {});
  },
  handleSubmit: (values, { props }) => {
    const { closeDialog, onSave, pafUpload } = props;

    return new Promise((resolve) => {
      const valueEntries = Object.entries(values);
      const returnValue = valueEntries
        .filter((entry) => entry[0] !== 'file' && entry[1] !== '')
        .map((entry) => {
          return {
            destination: entry[0],
            source: entry[1]
          };
        });

      pafUpload.setColumns({
        columnMappings: returnValue
      });

      onSave(returnValue);
      closeDialog();
      resolve();
    });
  },
  validateOnChange: false,
  validateOnBlur: false
};

@styled(rowStyles)
@autobind
class MappingRow extends PureComponent {
  getPreviews(index) {
    const { previews } = this.props;

    return previews.map((row) => row[index]).join(', ');
  }

  getOptions() {
    const { row } = this.props;

    // TODO: mutating the input object here is a baaad idea. no idea if anything consumes these attributes.
    //  https://app.clubhouse.io/rexlabs/story/52632
    row.value = row.id;
    row.name = row.id;
    row.label = row.id;

    return row.options.map((columnName, index) => ({
      label: columnName,
      text: columnName,
      value: columnName,
      preview: this.getPreviews(index)
    }));
  }

  render() {
    const { styles: s, row } = this.props;
    const { label, name } = row;

    return (
      <div {...s('row')}>
        <Grid>
          <Column styles={fieldLabelStyles} width={4}>
            <Box alignItems={'center'}>
              <Body>{label}</Body>
            </Box>
          </Column>
          <Column width={8}>
            <FormField
              name={name}
              Input={Select}
              inputProps={{
                options: this.getOptions(),
                Option: (props) => {
                  const { label, preview } = props.option;
                  return (
                    <Option {...props}>
                      <Box {...s('container')}>
                        <Body>{label}</Body>
                        <Body grey>{preview}</Body>
                      </Box>
                    </Option>
                  );
                }
              }}
            />
          </Column>
        </Grid>
      </div>
    );
  }
}

@withForm(columnMatchingForm)
@styled(formStyles)
@autobind
class ColumnMatchingForm extends PureComponent {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    sourceColumns: PropTypes.shape({
      columns: PropTypes.arrayOf(PropTypes.string).isRequired,
      previews: PropTypes.arrayOf(PropTypes.any).isRequired
    }).isRequired,
    destinationColumns: PropTypes.arrayOf(PropTypes.any).isRequired
  };

  render() {
    const {
      styles: s,
      sourceColumns,
      destinationColumns,
      onSave,
      closeDialog
    } = this.props;

    const rows = destinationColumns.map((dest) => {
      dest.options = sourceColumns.columns;

      // To satisfy formik
      dest.text = dest.label;
      dest.name = dest.label;

      return dest;
    });

    return (
      <Form>
        <Grid>
          <Column width={12}>
            <Body {...s('info')} grey>
              To ensure the PAF file data is uploaded correctly, please select
              which columns in the file match target columns.
            </Body>
          </Column>
          <Column width={4}>
            <SubHeading bold>Target column</SubHeading>
          </Column>
          <Column width={8}>
            <SubHeading bold>PAF file</SubHeading>
          </Column>
          <Column width={12}>
            {rows.map((row) => {
              return (
                <MappingRow
                  key={row.name}
                  row={row}
                  previews={sourceColumns.previews}
                />
              );
            })}
          </Column>
        </Grid>
        <ButtonBar>
          <span onClick={closeDialog}>
            <TextButton>cancel</TextButton>
          </span>
          <PrimaryButton blue onClick={onSave}>
            Start Upload
          </PrimaryButton>
        </ButtonBar>
      </Form>
    );
  }
}

class ColumnMatchingDialog extends PureComponent {
  render() {
    const { closeDialog } = this.props;
    return (
      <Dialog
        title={'Column Matching'}
        top={Math.max(window.innerHeight / 2 - 225, 0)}
        width={540}
        closeDialog={closeDialog}
      >
        <ColumnMatchingForm {...this.props} />
      </Dialog>
    );
  }
}

export default ColumnMatchingDialog;
