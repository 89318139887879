import React, { useCallback, useEffect } from 'react';
import Screen from 'view/components/screen';
import { Body, Heading } from 'view/components/text';
import { TabsStateful } from '@rexlabs/tabs';
import billingModel from 'data/models/custom/billing';
import Stat from 'view/components/stat';
import { Box } from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import RenderLoading from 'view/components/render-loading';
import { compose } from 'redux';
import { withModel } from '@rexlabs/model-generator';
import { renderUsers } from 'view/screens/billing/grid-users';
import { renderAccounts } from 'view/screens/billing/grid-accounts';
import { useInternalOnlyRedirect } from 'src/hooks/use-internal-only-redirect';
import SummaryTab from 'view/screens/billing/summary-tab';

function BillingScreen({
  billing: {
    users,
    accounts,
    status,
    summary,
    fetchBillingDetails,
    groupUsers,
    groupUsersUltimate
  }
}) {
  useEffect(() => {
    fetchBillingDetails();
  }, []);

  useInternalOnlyRedirect();

  const summaryTab = useCallback(() => {
    return (
      <SummaryTab
        accounts={accounts}
        summary={summary}
        groupUsers={groupUsers}
        groupUsersUltimate={groupUsersUltimate}
      />
    );
  }, [accounts, summary, groupUsers, groupUsersUltimate]);

  const accountsTab = useCallback(() => renderAccounts(accounts), [accounts]);
  const usersTab = useCallback(() => renderUsers(users), [users]);

  return (
    <Screen title={'Billing'}>
      <Heading>Billing</Heading>
      <Box mt={PADDINGS.M}>
        <Body>
          The following data is for reference purposes only; some figures may
          not be relevant depending on your Rex pricing model.
        </Body>
      </Box>
      <RenderLoading isLoading={status === 'loading'}>
        <TabsStateful
          alwaysRenderAll
          items={[
            {
              name: 'summary',
              label: 'Summary',
              Tab: summaryTab
            },
            {
              name: 'accounts',
              label: 'Accounts',
              Tab: accountsTab
            },
            {
              name: 'users',
              label: 'Users ',
              Tab: usersTab
            }
          ]}
        />
      </RenderLoading>
    </Screen>
  );
}

export default compose(withModel(billingModel))(BillingScreen);
