import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import List from '@rexlabs/list';
import _ from 'lodash';
import { withModel } from '@rexlabs/model-generator';
import searchModel from 'data/models/custom/search';
import SearchListItem from 'view/components/lists/search-list-item';

import EmptyList from 'view/components/empty-list';

const EmptyView = () => (
  <EmptyList message={'No listings, invoices or contacts found.'} />
);

@withModel(searchModel)
@autobind
class SearchList extends PureComponent {
  renderItem(item) {
    return <SearchListItem item={item} />;
  }

  componentDidUpdate(prevProps) {
    const { search } = this.props;
    if (prevProps.searchTerm !== this.props.searchTerm) {
      search.query(this.props.searchTerm);
    }
  }

  componentWillUnmount() {
    const { search } = this.props;
    search.clear();
  }

  render() {
    const { search, searchTerm } = this.props;
    const isLoading =
      _.get(search, 'status') === 'loading' && !_.get(search, 'items.length');

    return (
      <Fragment>
        <List
          isLoading={isLoading}
          items={_.get(search, 'items', [])}
          EmptyView={searchTerm && !isLoading ? EmptyView : () => null}
          renderItem={this.renderItem}
          LoadingView={() => null}
          ErrorView={() => <p>Something went wrong!</p>}
        />
      </Fragment>
    );
  }
}

export default SearchList;
