/* eslint-disable max-lines */

import React, { PureComponent, Fragment } from 'react';
import Dialog from 'view/components/dialog';
import { withModel } from '@rexlabs/model-generator';

import { Form, FormField } from 'view/components/form';

import { autobind } from 'core-decorators';
import _ from 'lodash';
import { TextInput, TextArea } from '@rexlabs/text-input';
import Checkbox from '@rexlabs/checkbox';
import { styled, StyleSheet } from '@rexlabs/styling';

import reportsModel from 'data/models/custom/reports';
import { FormRenderer } from '@rexlabs/form-renderer';
import Select from 'view/components/input/select/select';
import Label from 'view/components/text/label';
import accountUsersModel from 'data/models/entities/account-users';
import EntitySelect from 'view/components/input/select/entity-select';
import { PrimaryButton } from 'shared/components/button';
import DateRangeSelect from 'view/components/input/select/date-range-select';
import ButtonBar from 'view/components/button-bar';
import TextButton from 'shared/components/button/text';
import SubHeading from 'view/components/text/sub-heading';
import { Body } from 'view/components/text';
import { COLORS, PADDINGS } from 'theme';
import Box from '@rexlabs/box';
import PaddingBox from 'view/components/padding-box';
import { ICONS } from 'shared/components/icon';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import systemValuesModel from 'data/models/custom/value-list';
import contactsModel from 'data/models/entities/contacts';

const defaultStyles = StyleSheet({
  rightBar: {
    width: '290px',
    padding: PADDINGS.M,
    background: COLORS.BACKGROUND
  },
  buttonIcon: {
    position: 'relative',
    left: '-3px'
  },
  downloadIcon: {
    transform: 'rotate(180deg)'
  }
});

@withErrorDialog
@styled(defaultStyles)
@withModel(reportsModel)
@withModel(accountUsersModel)
@withModel(systemValuesModel)
@autobind
class ReportsGenerateDialog extends PureComponent {
  state = {
    accountUsersOptions: [],
    outputFormats: [],
    schema: {},
    report: {},
    isLoading: true
  };

  // TODO: Refactor the reports model so we don't need this
  async componentDidMount() {
    const { reports, match, accountUsers, errorDialog } = this.props;
    try {
      const outputFormatsResponse = await reports.describeReport({
        id: _.get(match, 'params.reportId'),
        viewId: _.get(match, 'params.viewId')
      });
      const outputFormats = outputFormatsResponse.data.result.output_formats;

      // TODO; find a cleaner way to conditionally request this based on the report dialog we are viewing
      const accountUsersResponse = await accountUsers.fetchList({
        id: 'account_users'
      });
      const accountUsersOptions = accountUsersResponse.data.map(({ item }) => {
        return {
          value: item.id,
          label: [item.first_name, item.last_name].filter(Boolean).join(' ')
        };
      });

      const report = reports.getReport(
        _.get(match, 'params.reportId'),
        _.get(match, 'params.viewId')
      );

      const schema = await this.convertParametersToFormSchema(
        report.parameters,
        outputFormats
      );

      this.setState({
        accountUsersOptions,
        outputFormats,
        schema,
        report,
        isLoading: false
      });
    } catch (err) {
      return errorDialog.open(err);
    }
  }

  handleSubmit({ output_format: outputFormat, ...values }) {
    const { reports, match } = this.props;
    const report = reports.getReport(
      _.get(match, 'params.reportId'),
      _.get(match, 'params.viewId')
    );

    this.setState({
      reportIsLoading: true
    });

    reports
      .generateReport({
        id: report.id,
        view_id: report.view_id === 'no_view_id' ? null : report.view_id,
        parameters: Object.entries(values).reduce((acc, [key, value]) => {
          acc[key] = value === '' ? null : value;
          return acc;
        }, {}),
        output_format: outputFormat
      })
      .then((response) => {
        this.setState({
          reportIsLoading: false,
          generatedReportURL: response.data.result
        });
      })
      .catch((e) => {
        this.setState({
          reportIsLoading: false,
          reportGenerationError: e.message
        });
      });
  }

  closeReportGenerationDialog() {
    this.setState({
      generatedReportURL: undefined,
      isReportGenerated: false,
      reportGenerationError: undefined
    });
  }

  async getValueListValues(field) {
    const { systemValues } = this.props;

    if (field.list_name) {
      try {
        const response = await systemValues.fetchList({
          listName: field.list_name
        });

        return response.data.result.map((option) => ({
          label: option.text,
          value: option.id
        }));
      } catch (err) {
        return [];
      }
    }

    return field.list_values.map((option) => ({
      label: option.text,
      value: option.id
    }));
  }

  async getFieldProperties(field) {
    switch (field.type) {
      case 'valuelist': {
        const values = await this.getValueListValues(field);
        return {
          type: 'select',
          validation: field.optional ? [] : ['required'],
          inputProps: {
            multi: field.multiple,
            searchOnMount: true
          },
          data: values
        };
      }
      case 'date_range':
        return {
          type: 'date',
          validation: (val) => {
            return val && val.length === 2 && _.every(val, _.identity);
          }
        };
      case 'entity_select': {
        const models = {
          contact: contactsModel
        };

        const model = models[field.service_name];
        if (!model) {
          throw new Error('Unsupported model: ' + field.service_name);
        }

        return {
          type: 'select',
          validation: field.optional ? [] : ['required'],
          inputProps: {
            models: [model],
            multi: field.multiple
          }
        };
      }
      case 'boolean':
        return {
          type: 'checkbox'
        };
      default:
        return {};
    }
  }

  async convertParametersToFormSchema(parameters, outputFormats) {
    const fields = await Promise.all(
      _.map(parameters.list, async (field, fieldKey) => {
        const fieldProperties = await this.getFieldProperties(field);
        return {
          name: fieldKey,
          label: field.label,
          ...fieldProperties
        };
      }).concat(this.buildOutputFormatField(outputFormats))
    );

    return {
      id: _.uniqueId(),
      definition: {
        name: 'reportGenerationForm',
        schema: 1,
        fields
      }
    };
  }

  buildOutputFormatField(outputFormats) {
    return {
      type: 'select',
      name: 'output_format',
      label: 'Output format',
      validation: ['required'],
      data: outputFormats
        .filter((format) =>
          [
            'pdf_file_download',
            'html_file_download',
            'csv_file_download',
            'xlsx_file_download'
          ].includes(format)
        )
        .map((format) => {
          let label = _.upperCase(format.split('_file_download')[0]);
          if (format === 'xlsx_file_download') {
            label = `Spreadsheet (${_.upperCase(
              format.split('_file_download')[0]
            )})`;
          }
          return {
            label,
            value: format
          };
        })
    };
  }

  render() {
    const { closeModal, styles: s } = this.props;
    const {
      generatedReportURL,

      reportIsLoading,
      reportGenerationError,
      report,
      schema
    } = this.state;

    if (this.state.isLoading === true) return null;

    return (
      <Dialog
        title={report.title}
        hasPadding={false}
        height={600}
        width={720}
        closeDialog={closeModal}
      >
        <Box flexDirection={'row'}>
          <Box flex={1}>
            <FormRenderer
              handleSubmit={this.handleSubmit}
              schema={schema}
              SubmitButton={({ submitForm }) => (
                <PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
              )}
              Form={Form}
              Field={FormField}
              Label={Label}
              TextInput={TextInput}
              TextAreaInput={TextArea}
              CheckboxInput={Checkbox}
              SelectInput={({ models, ...rest }) =>
                models ? (
                  <EntitySelect models={models} {...rest} />
                ) : (
                  <Select name='report-select' {...rest} />
                )
              }
              MultiSelectInput={({ models, ...rest }) =>
                models ? (
                  <EntitySelect models={models} {...rest} />
                ) : (
                  <Select name='report-multi-select' {...rest} />
                )
              }
              DateInput={DateRangeSelect}
            >
              {({ visibleFields, form: { submitForm } }) => {
                return (
                  <PaddingBox>
                    {visibleFields.map((field) => field)}
                    <ButtonBar isLoading={reportIsLoading}>
                      <TextButton blue onClick={closeModal}>
                        Cancel
                      </TextButton>
                      <PrimaryButton blue onClick={submitForm}>
                        Generate Report
                      </PrimaryButton>
                    </ButtonBar>
                  </PaddingBox>
                );
              }}
            </FormRenderer>
          </Box>

          <section {...s('rightBar')}>
            <SubHeading>General Instructions</SubHeading>
            <Body grey>{report.description}</Body>
          </section>
        </Box>
        {!!generatedReportURL && (
          <Dialog
            title={reportGenerationError ? 'Error' : 'Report Generated'}
            height={200}
            closeDialog={this.closeReportGenerationDialog}
          >
            {reportGenerationError ? (
              <Body>{reportGenerationError}</Body>
            ) : (
              <Fragment>
                <Body grey>Download or email the report as a spreadsheet.</Body>
                <Box mt={PADDINGS.S} alignItems={'center'}>
                  <Box mr={PADDINGS.XS}>
                    <PrimaryButton
                      blue
                      Container={'a'}
                      href={generatedReportURL}
                      IconLeft={() => (
                        <ICONS.GROUP_APP_DOWNLOAD
                          {...s('buttonIcon', 'downloadIcon')}
                        />
                      )}
                    >
                      Download Report
                    </PrimaryButton>
                  </Box>
                  <PrimaryButton
                    Container={'a'}
                    href={'mailto:?body=' + generatedReportURL}
                    IconLeft={() => (
                      <ICONS.GROUP_APP_EMAIL {...s('buttonIcon')} />
                    )}
                  >
                    Email Report
                  </PrimaryButton>
                </Box>
              </Fragment>
            )}
          </Dialog>
        )}
        {reportGenerationError ? (
          <Dialog
            title={'Error'}
            height={200}
            closeDialog={this.closeReportGenerationDialog}
          >
            <Body>{reportGenerationError}</Body>
          </Dialog>
        ) : null}
      </Dialog>
    );
  }
}

export default ReportsGenerateDialog;
