import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import { ListCell, ListRow } from 'view/components/list';
import { Link, push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';

import _ from 'lodash';
import accountUsersModel from 'data/models/entities/account-users';
import accountGroupUsersModel from 'data/models/entities/account-groups-users';
import sessionModel from 'data/models/custom/session';
import { styled, StyleSheet } from '@rexlabs/styling';
import Checkbox from '@rexlabs/checkbox';
import FilterableList from 'view/components/filterable-list';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { connect } from 'react-redux';
import filtersModel from 'data/models/custom/filters';
import { COLORS, PADDINGS, TEXTS } from 'theme';

const defaultStyles = StyleSheet({
  truncated: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  tag: {
    borderRadius: '4px',
    padding: `${PADDINGS.XXS} ${PADDINGS.S}`,
    margin: PADDINGS.S,
    marginTop: PADDINGS.L,
    ...TEXTS.BODY_SMALL,
    display: 'inline'
  },
  tagDisabled: {
    backgroundColor: COLORS.GREY_LIGHT
  },
  tagInvited: {
    backgroundColor: COLORS.NOTICE
  }
});

function getCriteriaAccountUsersList(props) {
  return [
    { name: 'id', type: '!=', value: '102' },
    _.get(props, 'filters.values.users.searchTerm')
      ? {
          name: 'first_name',
          type: 'like',
          value: `%${_.get(props, 'filters.values.users.searchTerm')}%`
        }
      : undefined,
    _.get(props, 'filters.values.users.officeGroups') &&
    props.filters.values.users.officeGroups > 0
      ? {
          name: 'office_groups',
          type: 'in',
          value: _.get(props, 'filters.values.users.officeGroups')
        }
      : undefined
  ].filter(Boolean);
}

export const accountUsersListQuery = query`{
  ${accountUsersModel} (criteria: ${getCriteriaAccountUsersList}) {
    id
    first_name
    email
    last_name
    reporting_categories
    system_created_user
    system_ctime
    user_account_status
    user_links
    user_registration_status
  }
}`;

export const accountGroupUsersListQuery = query`{	
  ${accountGroupUsersModel} (limit: "100") {
    id
    account_group
    user
  }
}`;

const stateToProps = (state) => ({
  selectedIds: state.entities.accountUsers.selectedIds || []
});

@styled(defaultStyles)
@withModel(filtersModel)
@withModel(sessionModel)
@withQuery(accountUsersListQuery)
@withQuery(accountGroupUsersListQuery)
@withErrorDialog
@connect(stateToProps) // TODO: Massive hack. Selectors seem to not be passing through correctly??
@autobind
class UsersList extends PureComponent {
  renderHeader() {
    return (
      <ListRow isHeader>
        <ListCell width={50} />
        <ListCell width={300}>Name</ListCell>
        <ListCell width={300}>Email</ListCell>
        <ListCell flex={1}>Office Groups</ListCell>
      </ListRow>
    );
  }

  renderItem(item, index, toggleSelectItem) {
    const { accountUsers, errorDialog, selectedIds, session } = this.props;
    const { styles: s } = this.props;
    let officeGroups = _.get(item, 'account_groups', []).map(
      ({ account_group: accountGroup }) => {
        return (
          <span key={_.get(accountGroup, 'id')}>
            {_.get(accountGroup, 'name')}
          </span>
        );
      }
    );
    if (officeGroups.length) {
      officeGroups = officeGroups.reduce((prev, curr, i) => [
        <span key={i}>
          {prev}, {curr}
        </span>
      ]);
    }

    const canManage = session.checkUserHasPermission('users.manage');
    const canDisable = session.checkUserHasPermission('users.disable');
    const canDelete = session.checkUserHasPermission('users.delete');
    const notRegistered =
      _.get(item, 'user_registration_status') === 'unregistered';

    const actionMenuItems = [
      ...(notRegistered
        ? [
            {
              label: 'Invite User',
              onClick: () =>
                accountUsers.invite({
                  email_address: item.email,
                  send_invite: true
                })
            }
          ]
        : []),
      ...(canManage && !notRegistered
        ? [
            {
              label: 'Reset User Password',
              onClick: () =>
                push(ROUTES.USERS.RESET_PASSWORD, {
                  params: { userId: item.id }
                })
            }
          ]
        : []),
      ...(canDisable
        ? [
            item.user_account_status === 'disabled'
              ? {
                  label: 'Enable User',
                  onClick: () =>
                    accountUsers
                      .enable({ user_id: item.id })
                      .catch(errorDialog.open)
                }
              : {
                  label: 'Disable User',
                  onClick: () =>
                    push(ROUTES.USERS.DISABLE, {
                      params: { userId: item.id }
                    })
                }
          ]
        : []),
      ...(canDelete
        ? [
            {
              label: 'Delete User',
              onClick: () =>
                push(ROUTES.USERS.DELETE, {
                  params: { userId: item.id }
                })
            }
          ]
        : [])
    ];

    return (
      <Link to={ROUTES.USERS_EDIT} params={{ userId: item.id }}>
        {({ onClick }) => (
          <ListRow
            key={item.id}
            odd={index % 2}
            onClick={onClick}
            actionMenuItems={
              actionMenuItems.length > 0 ? actionMenuItems : undefined
            }
          >
            <ListCell
              width={50}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleSelectItem(item.id);
              }}
            >
              <Checkbox id={item.id} value={selectedIds.includes(item.id)} />
            </ListCell>
            <ListCell ellipsis width={300}>
              {item.first_name} {item.last_name}{' '}
              {item.user_account_status === 'disabled' ? (
                <span {...s('tag', 'tagDisabled')}>Disabled</span>
              ) : item.user_account_status === 'invited' ? (
                <span {...s('tag', 'tagInvited')}>Invitation Sent</span>
              ) : null}
            </ListCell>
            <ListCell ellipsis width={300}>
              {item.email}
            </ListCell>
            <ListCell ellipsis flex={1} pr={PADDINGS.XXL}>
              <span {...s('truncated')}>{officeGroups}</span>
            </ListCell>
          </ListRow>
        )}
      </Link>
    );
  }

  render() {
    const { accountUsers, accountGroupUsers, selectedIds, session } =
      this.props;
    const canManage = session.checkUserHasPermission('users.manage');
    let items = [];
    if (
      accountUsers.list.status === 'loaded' &&
      accountGroupUsers.list.status === 'loaded'
    ) {
      const groupAccounts = _.get(accountGroupUsers, 'list.items', []);
      items = _.get(accountUsers, 'list.items', []).map((user) => {
        const officeGroups = groupAccounts.filter((groupAccount) => {
          return user.id === groupAccount.user.id;
        });
        return officeGroups ? { ...user, account_groups: officeGroups } : user;
      });
    }

    return (
      <FilterableList
        entityName={'users'}
        addLink={canManage ? ROUTES.USERS.INVITE : undefined}
        items={items}
        renderItem={this.renderItem}
        renderHeader={this.renderHeader}
        selectedIds={selectedIds}
        model={accountUsers}
        onFilterClear={_.noop}
        bulkActions={
          canManage
            ? [
                {
                  label: 'Assign office group',
                  onClick: () => push(ROUTES.USERS.ASSIGN_OFFICE_GROUP)
                }
              ]
            : []
        }
        getCriteria={getCriteriaAccountUsersList}
        filterConfig={undefined}
        loadingStatus={accountGroupUsers.list.status}
      />
    );
  }
}

export default UsersList;
