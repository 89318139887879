import React, { PureComponent, Fragment } from 'react';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';

import { RenderMatchedRoutes } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import DialogsPortalTarget from 'view/components/portal/dialogs';
import sessionModel from 'data/models/custom/session';
import { withModel } from '@rexlabs/model-generator';
import AgencySelector from 'shared/components/header/tooltips/agency-selector';
// TODO: style HelpCenter dropdown with non nav specific styles
// import HelpCenter from 'shared/components/header/tooltips/help-centre';
import UserProfile from 'shared/components/header/tooltips/user-profile';
import MainMenu from 'view/components/main-menu';
import Icon, { ICONS } from 'shared/components/icon';
import ErrorBoundary from 'view/components/error-boundary';
import { Heading, Body } from 'view/components/text';

const defaultStyles = StyleSheet({
  container: {
    height: '100vh',
    width: '100vw'
  },
  errorBoundaryContainer: {
    height: 'calc(100vh - 70px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.BACKGROUND
  },
  header: {
    width: '100%',
    height: '70px',
    flexShrink: '0',
    background: COLORS.BLUE,
    padding: '0 20px 0 15px',
    position: 'fixed',
    zIndex: 10
  },

  wrapContent: {
    marginLeft: '200px',
    marginTop: '70px',
    position: 'relative',
    overflow: 'scroll'
  },

  sidebar: {
    width: '201px',
    height: '100%',
    borderRight: `1px solid ${COLORS.GREY_LIGHT}`
  },

  content: {
    padding: '40px 65px 60px 65px',
    width: '100%',
    maxWidth: '1024px',

    '> div': {
      width: '100%'
    }
  },
  logoStyles: {
    color: COLORS.WHITE,
    paddingRight: PADDINGS.L
  },
  errorBoundary: {
    fontSize: '17px',
    lineHeight: '28px',

    '& a': {
      color: '#2aa2e3',
      marginLeft: PADDINGS.XXS
    }
  }
});

@withModel(sessionModel)
@styled(defaultStyles)
@autobind
class AppShell extends PureComponent {
  renderErrorBoundary() {
    const { styles: s } = this.props;
    return (
      <div {...s('container', 'errorBoundaryContainer')}>
        <Box
          {...s('errorBoundary')}
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Heading>Oops, something went wrong.</Heading>
          <Box mt={PADDINGS.S}>
            <Body grey>
              {"Don't worry, it's not your fault."}
              <a
                href={window.location.href}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload(true);
                }}
              >
                Reload Page
              </a>
            </Body>
          </Box>
        </Box>
      </div>
    );
  }

  render() {
    const { styles: s, session } = this.props;

    return (
      <Fragment>
        <ErrorBoundary Placeholder={this.renderErrorBoundary}>
          <DialogsPortalTarget />
          <Box flexDirection='column' flex={1} {...s('container')}>
            <Box
              {...s('header')}
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box flexDirection={'row'}>
                <Icon {...s('logoStyles')} type={ICONS.GROUP_APP_LOGO} />
                <AgencySelector />
              </Box>
              <Box flexDirection={'row'}>
                <UserProfile {...this.props} />
              </Box>
            </Box>
            <Box flexDirection='row' {...s('wrapContent')} flex={1}>
              <MainMenu />
              <ErrorBoundary Placeholder={this.renderErrorBoundary}>
                <Box {...s('content')} flex={1}>
                  {session.isSwitching || (
                    <RenderMatchedRoutes fallback={null} routes={ROUTES} />
                  )}
                </Box>
              </ErrorBoundary>
            </Box>
          </Box>
        </ErrorBoundary>
      </Fragment>
    );
  }
}

export default AppShell;
