import _ from 'lodash';
import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';

const TYPE = 'search';

const initialState = {
  items: [],
  fetched: null
};

const actionCreators = {
  query: {
    request: (payload) =>
      new Promise((resolve, reject) => {
        if (!payload) {
          return;
        }

        Promise.all([
          api
            .post('SubAccountListings::autocomplete', {
              search_string: payload,
              listing_states: ['current', 'sold', 'leased', 'withdrawn']
            })
            .then(({ data }) => {
              return _.get(data, 'result') || [];
            })
            .catch((e) => {
              reject(e);
            }),
          api
            .post('Invoices::autocomplete', {
              search_string: payload
            })
            .then(({ data }) => {
              return _.get(data, 'result') || [];
            })
            .catch((e) => {
              reject(e);
            }),
          api
            .post('SubAccountContacts::autocomplete', {
              search_string: payload
            })
            .then(({ data }) => {
              return _.get(data, 'result') || [];
            })
            .catch((e) => {
              reject(e);
            })
        ])
          .then((results) => {
            return [...results[0], ...results[1], ...results[2]];
          })
          .then(resolve)
          .catch(reject);
      }),
    reduce: {
      initial: (state) => {
        return {
          ...state,
          status: 'loading'
        };
      },
      success: (state, action) => {
        return {
          ...state,
          items: action.payload,
          fetched: new Date().getTime(),
          status: 'loaded'
        };
      },
      failure: (state) => {
        return { ...state, items: [], fetched: null, status: 'loaded' };
      }
    }
  },
  clear: {
    reduce: (state) => {
      return {
        ...state,
        items: [],
        fetched: null,
        status: 'loaded'
      };
    }
  }
};

const selectors = {
  items: (state) => state.search.items,
  status: (state) => state.search.status
};

export default new Generator(TYPE).createModel({
  initialState,
  actionCreators,
  selectors
});
