import React, { PureComponent } from 'react';
import { Heading } from 'view/components/text';

import UsersList from 'view/components/lists/users';
import filtersModel from 'data/models/custom/filters';

import { withModel } from '@rexlabs/model-generator';
import Screen from 'view/components/screen';
import { autobind } from 'core-decorators';

@withModel(filtersModel)
@autobind
class UsersListScreen extends PureComponent {
  render() {
    return (
      <Screen title={'Users'}>
        <Heading>Users</Heading>
        <UsersList />
      </Screen>
    );
  }
}

export default UsersListScreen;
