import { PureComponent } from 'react';
import { withModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import sessionModel from 'data/models/custom/session';
import ROUTES from 'routes';

@withModel(sessionModel)
class DefaultScreen extends PureComponent {
  render() {
    const { session } = this.props;
    if (session.checkUserHasPermission('reports.view_section')) {
      push(ROUTES.REPORTS);
    } else if (session.checkUserHasPermission('announcements.view_section')) {
      push(ROUTES.ANNOUNCEMENTS);
    } else {
      push(ROUTES.SEARCH);
    }
    return null;
  }
}

export default DefaultScreen;
