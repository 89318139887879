import React, { PureComponent } from 'react';
import { TextInput } from '@rexlabs/text-input';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, TEXTS } from 'src/theme';
import { ReactForms, Form, FormField } from 'view/components/form';
import StateToggle from 'shared/components/state-toggle';
import { autobind } from 'core-decorators';

import { Body } from 'view/components/text';

const defaultStyles = StyleSheet({
  pageSelector: {
    width: '64px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${COLORS.TABLE_BORDER.DARK}`,
    cursor: 'pointer'
  }
});

const inputStyleOverrides = StyleSheet({
  label: {
    display: 'none'
  },
  container: {
    borderRadius: '0',
    width: '64px',
    height: '36px',
    marginTop: ' -4px',
    border: `1px solid ${COLORS.BLUE}`
  },
  input: {
    minWidth: '10px',
    textAlign: 'center',
    ...TEXTS.BODY
  }
});

@styled(defaultStyles)
@autobind
class PageInput extends PureComponent {
  focusInput() {
    const element = window.document.getElementById('paginatorInput');
    if (element) {
      element.focus();
      // Set the cursor to the end of the input
      element.selectionStart = element.selectionEnd = element.value.length;
    }
  }

  blurInput() {
    const element = window.document.getElementById('paginatorInput');
    if (element) {
      element.blur();
      // When input is blurred we must force the StateToggle to toggle back to the view state.
      this.state.toggle();
    }
  }

  handleSubmit({ paginatorInput }) {
    const { changePage } = this.props;
    changePage(Number(paginatorInput));
    // Forces input to defocus if the user hits enter (submits form)
    this.blurInput();
  }

  renderInput({ toggle, data: { submitForm } }) {
    // Extracts toggle method and assigns to state for later use in blurInput
    this.setState({ toggle });

    return (
      <FormField
        name='paginatorInput'
        Input={TextInput}
        onBlur={() => submitForm()}
        inputProps={{
          ref: this.focusInput,
          styles: inputStyleOverrides
        }}
      />
    );
  }

  renderValue({ data: { page } }) {
    const { styles: s, totalPages } = this.props;
    return (
      <Box {...s('pageSelector')}>
        <Body>{`${page} of ${totalPages}`}</Body>
      </Box>
    );
  }

  render() {
    const { page } = this.props;
    return (
      <ReactForms
        handleSubmit={this.handleSubmit}
        initialValues={{ paginatorInput: page }}
      >
        {({ submitForm }) => (
          <Form name='paginator-input'>
            <StateToggle
              data={{ page, submitForm }}
              submitForm={submitForm}
              InitialRender={this.renderValue}
              ToggledRender={this.renderInput}
            />
          </Form>
        )}
      </ReactForms>
    );
  }
}

export default PageInput;
