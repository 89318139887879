import React, { Fragment, PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { withModel, withQuery } from '@rexlabs/model-generator';
import accountUsersModel from 'data/models/entities/account-users';
import { Form, FormField, HiddenField, ReactForms } from 'view/components/form';
import { TextInput } from '@rexlabs/text-input';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';
import { createValidationRules } from 'shared/utils/form';
import PaddingBox from 'view/components/padding-box';
import { Body, Label } from 'view/components/text';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'src/theme';
import Box from '@rexlabs/box';
import {
  accountGroupUsersListQuery,
  accountUsersListQuery
} from 'view/components/lists/users';

const defaultStyles = StyleSheet({
  emailValue: {
    paddingLeft: PADDINGS.S,
    display: 'inline'
  },
  buttonBar: {
    marginTop: PADDINGS.S
  }
});

@withErrorDialog
@withModel(accountUsersModel)
@withQuery(accountUsersListQuery)
@withQuery(accountGroupUsersListQuery)
@styled(defaultStyles)
@autobind
class UserInviteDialog extends PureComponent {
  state = {
    foundContact: null,
    email_address: null
  };

  handleSubmit(values) {
    const {
      accountUsers,
      accountGroupUsers,
      closeModal,
      errorDialog,
      callback
    } = this.props;
    const { foundContact, email_address: emailAddress } = this.state;

    if (foundContact === null) {
      return accountUsers
        .invite({
          email_address: values.email_address,
          send_invite: true
        })
        .then((res) => {
          accountUsers.refreshList();
          accountGroupUsers.refreshList();
          _.isFunction(callback) && callback(res);
          closeModal();
        })
        .catch((err) => {
          if (_.get(err, 'problem') === 'RecordNotFoundException') {
            this.setState({
              foundContact: false,
              email_address: values.email_address
            });
          } else {
            errorDialog.open(err);
          }
        });
    } else {
      return accountUsers
        .createItem({
          data: {
            email_address: values.email_address || emailAddress,
            first_name: values.first_name,
            last_name: values.last_name,
            send_invite: true
          }
        })
        .then(() => {
          accountGroupUsers.refreshList();
          accountUsers.refreshList();
          closeModal();
        })
        .catch(errorDialog.open);
    }
  }

  getInitialValues() {
    return {
      first_name: '',
      last_name: '',
      email_address: ''
    };
  }

  render() {
    const { closeModal, styles: s } = this.props;
    const { foundContact, email_address: emailAddress } = this.state;

    return (
      <Dialog
        title={'Invite User To Group App'}
        height={200}
        closeDialog={closeModal}
        hasPadding={false}
      >
        <ReactForms
          handleSubmit={this.handleSubmit}
          initialValues={this.getInitialValues()}
          validate={createValidationRules({
            email_address: 'required|email'
          })}
        >
          {(form) => (
            <Form style={{ width: '100%' }}>
              {foundContact === false ? (
                <Fragment>
                  <PaddingBox>
                    <Body grey>
                      The email address does not exist as a Rex user. They will
                      be invited by email to join your company, once the
                      invitation is accepted the user can choose their password.
                    </Body>
                    <br />
                    <Box>
                      <Label>email</Label>
                      <Body medium {...s('emailValue')}>
                        {emailAddress}
                      </Body>
                    </Box>
                  </PaddingBox>
                  <PaddingBox grey width='100%'>
                    <FormField
                      name='first_name'
                      label='First name'
                      Input={TextInput}
                    />
                    <FormField
                      name='last_name'
                      label='Last name'
                      Input={TextInput}
                    />
                  </PaddingBox>
                </Fragment>
              ) : (
                <Fragment>
                  <PaddingBox width='100%'>
                    <FormField
                      name='email_address'
                      label='Email address'
                      Input={TextInput}
                      sendImmediate
                    />
                    <HiddenField name='first_name' />
                    <HiddenField name='last_name' />
                  </PaddingBox>
                </Fragment>
              )}
              <PaddingBox
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                {...s('buttonBar')}
              >
                <ButtonBar isLoading={form.isSubmitting} width={'auto'}>
                  <TextButton blue onClick={closeModal}>
                    Cancel
                  </TextButton>
                  <PrimaryButton blue onClick={form.submitForm}>
                    Send Invite
                  </PrimaryButton>
                </ButtonBar>
              </PaddingBox>
            </Form>
          )}
        </ReactForms>
      </Dialog>
    );
  }
}

export default UserInviteDialog;
