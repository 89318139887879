import React, { PureComponent } from 'react';
import { autobind } from 'core-decorators';
import { withModel } from '@rexlabs/model-generator';
import invariant from 'invariant';
import _ from 'lodash';
import { COLORS } from 'src/theme';

import { filterOptionsByValue } from './utils';
import Select from './select';

import ChevronIcon from 'assets/icons/chevron.svg';

@autobind
class WithEntityModels extends PureComponent {
  static defaultProps = {
    models: [],
    options: [],
    debounce: 300,
    DropdownIndicator: () => (
      <ChevronIcon
        style={{
          height: '24px',
          width: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: COLORS.GREY_DARK
        }}
      />
    )
  };

  constructor(props) {
    super(props);

    const { options, value, valueAsObject } = props;

    invariant(props.models, 'You need to define models for EntitySelect!');

    let selected = [];
    if (props.value && props.options) {
      selected = filterOptionsByValue(options, value, valueAsObject);
    }

    this.state = {
      searchTerm: '',
      options,
      selected
    };
  }

  componentDidMount() {
    const { searchOnMount } = this.props;
    if (searchOnMount) {
      this.autocompleteModels('');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const changedValue = !_.isEqual(prevProps.value, this.props.value);
    const changedOptions = prevProps.options !== this.props.options;

    const changedSearchCriteria =
      prevProps.searchCriteria !== this.props.searchCriteria;

    if (changedValue || changedOptions) {
      const selectedOptions = filterOptionsByValue(
        _.uniqBy(
          [
            ...this.state.options,
            ...this.props.options,
            ...this.state.selected,
            ...prevState.selected
          ],
          'value',
          this.props.valueAsObject
        ),
        this.props.value,
        this.props.valueAsObject
      );

      this.setState({
        selected: selectedOptions,
        options: _.uniqBy(
          [...this.state.options, ...this.props.options, ...selectedOptions],
          'value'
        )
      });
    }
    if (changedSearchCriteria) {
      this.searchModels(this.props.searchCriteria);
    }
  }

  searchModels(criteria) {
    const { models } = this.props;

    const fetchAll = models
      .map((model) => {
        return model.select && model.select.search
          ? model.select.search(criteria, this.props)
          : undefined;
      })
      .filter(Boolean);

    this.setState({ isFetching: true });
    Promise.all(fetchAll).then((results) => {
      this.setState((state) => ({
        options: results.reduce(
          (all, options) => _.uniqBy([...all, ...options], 'value'),
          state.selected
        ),
        isFetching: false
      }));
    });
  }

  autocompleteModels(searchTerm) {
    const { models } = this.props;

    const fetchAll = models
      .map((model) => {
        return model.select && model.select.autocomplete
          ? model.select.autocomplete(searchTerm, this.props)
          : undefined;
      })
      .filter(Boolean);

    Promise.all(fetchAll).then((results) => {
      this.setState((state) => ({
        options: results.reduce(
          (all, options) => _.uniqBy([...all, ...options], 'value'),
          state.selected
        ),
        isFetching: false,
        searchTerm
      }));
    });
  }

  debouncedInputChange(event) {
    const { onInputChange } = this.props;

    if (onInputChange) {
      onInputChange(event);
    }

    const searchTerm = _.get(event, 'target.value', '');

    this.autocompleteModels(searchTerm);
  }

  inputChangeTimeout = null;
  handleInputChange(event) {
    if (_.get(event, 'target.value', '').length >= 2) {
      event.persist && event.persist();
      clearTimeout(this.inputChangeTimeout);
      this.setState({ isFetching: true }, () => {
        this.inputChangeTimeout = setTimeout(
          () => this.debouncedInputChange(event),
          this.props.debounce
        );
      });
    }
  }

  handleChange(e) {
    const { options } = this.state;
    const { multi, valueAsObject, onChange } = this.props;

    this.setState({
      selected: multi
        ? options.filter((option) =>
            e.target.value.includes(valueAsObject ? option : option.value)
          )
        : []
    });

    onChange(e);
  }

  render() {
    const { searchTerm } = this.state;
    const { hasFixtures, models } = this.props;

    // Only show autocomplete fixtures once the user types
    // something in
    const fixtures = searchTerm
      ? models
          .map((model, index) => ({
            model,
            value: index,
            hasFixture: !!_.get(model, 'select.Fixture')
          }))
          .filter((fixture) => fixture.hasFixture)
      : [];

    return (
      <Select
        isLoading={this.state.isLoading || this.state.isFetching}
        onInputChange={this.handleInputChange}
        shouldSelectResetInput={true}
        fixtures={hasFixtures ? fixtures : undefined}
        {...this.props}
        onChange={this.handleChange}
        options={this.state.options}
      />
    );
  }
}

class EntitySelect extends PureComponent {
  constructor(props) {
    super(props);

    // HACK: until we have a `@withModels` in model generator, we loop over all
    // models here once per component
    this.Component = WithEntityModels;
    props.models.forEach((model) => {
      this.Component = withModel(model)(this.Component);
    });
  }

  render() {
    const { Component } = this;
    return <Component {...this.props} />;
  }
}

export default EntitySelect;
