import React, { Component } from 'react';

import Accordion from 'view/components/accordion';
import { autobind } from 'core-decorators';
import Checkbox from '@rexlabs/checkbox';
import { Body } from 'view/components/text';
import _ from 'lodash';
import FormField from 'view/components/form/field';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { PADDINGS, COLORS } from 'theme';
import RenderLoading from 'view/components/render-loading';
import accountUsersModel from 'data/models/entities/account-users';

class CheckboxField extends Component {
  render() {
    const { id, label, description, onChange, value, noBorder } = this.props;

    return (
      <Box
        flexDirection={'row'}
        pl={PADDINGS.M}
        alignItems={'center'}
        justifyContent={'flex-start'}
        key={id}
        pb={PADDINGS.M}
        style={{
          borderBottom: noBorder ? undefined : `1px solid ${COLORS.GREY_LIGHT}`
        }}
      >
        <Box>
          <Checkbox
            id={`privilege-${id}`}
            value={!!value}
            onChange={onChange}
          />
        </Box>
        <Box pt={PADDINGS.M} pl={PADDINGS.XS}>
          <Body onClick={() => onChange({ target: { value: !value } })}>
            {label}
          </Body>
          <Body small grey>
            {description}
          </Body>
        </Box>
      </Box>
    );
  }
}

@withModel(accountUsersModel)
@autobind
export default class UserGroupPrivileges extends React.PureComponent {
  tabName = 'groupPrivileges';

  getDependencyPrivileges(privilegeGroup, privilege) {
    const dependencyPrivileges = [];
    do {
      // Get the ID of the (dependency) privilege (if any) that the current
      // (dependent) privilege depends upon.
      const dependencyId = _.get(privilege, 'depends_on.id');
      if (dependencyId) {
        // Look up the privilege in the group. The value of the depends_on
        // property isn't used, as it won't contain depends_on member even if
        // it has a dependency. The depends_on properties are essentially
        // stubs; there is more information in the privileges that are in the
        // group array.
        const dependencyPrivilege = privilegeGroup.privileges.find(
          (p) => p.id === dependencyId
        );
        dependencyPrivileges.push(dependencyPrivilege);
        // Continue to loop, looking for a dependency of the dependency
        // privilege.
        privilege = dependencyPrivilege;
      } else {
        // If there is no dependency privilege, we're done.
        privilege = undefined;
      }
    } while (privilege);
    return dependencyPrivileges;
  }

  getDependentPrivileges(privilegeGroup, privilege) {
    const dependentPrivileges = privilegeGroup.privileges.filter(
      (p) => _.get(p, 'depends_on.id') === privilege.id
    );
    return _.uniq([
      ...dependentPrivileges,
      ..._.flatten(
        dependentPrivileges.map((p) =>
          this.getDependentPrivileges(privilegeGroup, p)
        )
      )
    ]);
  }

  handleFieldChange(privilegeGroup, privilege, { onChange, value }) {
    const checked = !value.includes(privilege.id);
    const ids = [privilege.id];

    if (checked) {
      // Any privileges that the now-checked privilege depends upon must also
      // be added to the value.
      const dependencyIds = this.getDependencyPrivileges(
        privilegeGroup,
        privilege
      ).map((p) => p.id);
      ids.push(...dependencyIds.filter((id) => !value.includes(id)));
    } else {
      // Any privileges that depend upon the now-unchecked privilege must also
      // be removed from the value.
      const dependentIds = this.getDependentPrivileges(
        privilegeGroup,
        privilege
      ).map((p) => p.id);
      ids.push(...dependentIds.filter((id) => value.includes(id)));
    }

    onChange({
      target: {
        value: checked ? value.concat(...ids) : _.without(value, ...ids)
      }
    });
  }

  renderField(privilegeGroup, privilege, { onChange, value }) {
    return this.renderCheckboxField({
      id: privilege.id,
      label: privilege.name,
      onChange: () =>
        this.handleFieldChange(privilegeGroup, privilege, { onChange, value }),
      value: value.includes(privilege.id),
      description: privilege.description
    });
  }

  renderCheckboxField(props) {
    return <CheckboxField {...props} />;
  }

  getActivePrivilegesForGroup(value, group) {
    return group.privileges
      .map((privilege) =>
        value.includes(privilege.id) ? privilege.id : undefined
      )
      .filter(Boolean);
  }

  renderContent({ onChange, value }) {
    const { privilegeGroups, privileges } = this.props;
    return (
      <RenderLoading isLoading={!privileges}>
        {privilegeGroups.list
          // The privilege groups model now uses the force_current_app_only flag
          // to ensure that only group-app privileges are retrieved. However,
          // the `base` group needs to be filtered out. Once upon a time, that
          // was the only group that was shown here, but it has been repurposed
          // and now contains a single privilege (`base.login_to_sub_accounts`)
          // that should not be shown in the UI.
          .filter((id) => id !== 'base')
          .map((id) => {
            const group = privilegeGroups.items[id];
            const activePrivilegesInThisGroup =
              this.getActivePrivilegesForGroup(value, group);

            return (
              <Accordion
                key={group.friendly_id}
                isOpenInitially
                label={group.name}
                subLabel={
                  activePrivilegesInThisGroup.length > 0
                    ? `${activePrivilegesInThisGroup.length} privilege${
                        activePrivilegesInThisGroup.length === 1 ? '' : 's'
                      }`
                    : undefined
                }
              >
                {group.privileges.map((privilege) =>
                  this.renderField(group, privilege, { onChange, value })
                )}
              </Accordion>
            );
          })}
      </RenderLoading>
    );
  }

  render() {
    const { activeTab } = this.props;

    return activeTab === this.tabName ? (
      <div>
        {/* TODO Add back in once this is a thing */}
        {/* <FormField */}
        {/* name={'can_access_group_app'} */}
        {/* Input={this.renderCheckboxField} */}
        {/* inputProps={{ */}
        {/* id: 'can_access_group_app', */}
        {/* label: 'Access to Group App', */}
        {/* noBorder: true, */}
        {/* description: */}
        {/* 'Allows the user to log into Group App and view Reports, Announcements, Users and Office Groups.' */}
        {/* }} */}
        {/* /> */}
        <FormField
          name={'group_privileges'}
          privilegeGroups={this.props.privilegeGroups}
          privileges={this.props.privileges}
          Input={this.renderContent}
        />
      </div>
    ) : null;
  }
}
