import React, { PureComponent } from 'react';
import { Heading } from 'view/components/text';
import AnnouncementsList from 'view/components/lists/announcements';

import Screen from 'view/components/screen';

class AnnouncementsListScreen extends PureComponent {
  render() {
    return (
      <Screen title={'Announcements'}>
        <Heading>Announcements</Heading>
        <AnnouncementsList />
      </Screen>
    );
  }
}

export default AnnouncementsListScreen;
