/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { configureStore, apiClientMiddleware } from 'shared/utils/redux';
import {
  whereaboutsReducer as whereabouts,
  whereaboutsMiddleware
} from '@rexlabs/whereabouts';
import { persistStore, persistReducer } from 'redux-persist';
import { combineModels } from '@rexlabs/model-generator';
import { setAuthToken } from 'shared/utils/api-client';
import localForage from 'localforage';
import { resetEntities, resetValueLists } from 'shared/utils/models';

/*
| Core Models
|------------------------
*/
import connection, {
  middleware as connectionMiddleware
} from 'shared/data/models/custom/connection';
import session from 'data/models/custom/session';
import reports from 'data/models/custom/reports';
import search from 'data/models/custom/search';
import privilegeSets from 'data/models/custom/privilege-sets';
import billing from 'data/models/custom/billing';
import filters from 'data/models/custom/filters';
import pafUpload from 'data/models/custom/paf-upload';
import privilegeGroups from 'data/models/custom/privilege-groups';
import valueList from 'data/models/custom/value-list';
import customFields from 'data/models/custom/custom-fields';

import announcements from 'data/models/entities/announcements';
import accountGroups from 'data/models/entities/account-groups';
import accountUsers from 'data/models/entities/account-users';
import accountGroupUsers from 'data/models/entities/account-groups-users';

import subAccounts from 'data/models/system-lists/sub-accounts';
import assignedAccountGroups from 'data/models/system-lists/assigned-account-groups';
import accountGroup from 'data/models/system-lists/account-group';

/*
 | Entity Models (Model Generator)
 |------------------------
 */
const entities = combineModels('entities', {
  announcements,
  accountUsers,
  accountGroups,
  accountGroupUsers
});

const valueLists = combineModels('valueLists', {
  subAccounts,
  assignedAccountGroups,
  accountGroup
});

/*
 | Persisted models
 |------------------------
 */

const persistSession = persistReducer(
  {
    key: 'rex.group-app.session',
    storage: localForage,
    whitelist: ['apiToken', 'apiRegion', 'regions', 'userProfile', 'loginInfo']
  },
  session
);

const persistPafUpload = persistReducer(
  {
    key: 'rex.group-app.pafUpload',
    storage: localForage
  },
  pafUpload
);

/*
| Setup for Stores
|------------------------
*/
let reducers = combineReducers({
  session: persistSession,
  whereabouts,
  connection,
  reports,
  privilegeSets,
  billing,
  search,
  filters,
  entities,
  valueList,
  privilegeGroups,
  valueLists,
  pafUpload: persistPafUpload,
  customFields
});

const combinedReducers = reducers;
reducers = (state, action) => {
  state = combinedReducers(state, action);
  if (action.type === 'session/SWITCH_TO_ACCOUNT') {
    state = {
      ...state,
      entities: resetEntities(state.entities),
      valueLists: resetValueLists(state.valueLists)
    };
  }
  return state;
};

const store = configureStore(reducers, [
  apiClientMiddleware(setAuthToken),
  whereaboutsMiddleware,
  connectionMiddleware
]);

const persistedStore = persistStore(store, null, () => {
  store.dispatch(session.actionCreators.init()).catch(console.warn);
});

window.purgePersistedStore = persistedStore.purge;

export { store };
