import React, { PureComponent } from 'react';
import { Heading } from 'view/components/text';

import OfficeGroupsList from 'view/components/lists/office-groups';
import Screen from 'view/components/screen';

class OfficeGroupsListScreen extends PureComponent {
  render() {
    return (
      <Screen title={'Office Groups'}>
        <Heading>Office Groups</Heading>
        <OfficeGroupsList />
      </Screen>
    );
  }
}

export default OfficeGroupsListScreen;
