import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const contactsModel = new Generator('contacts').createEntityModel();

contactsModel.select = {
  autocomplete: (searchTerm) => {
    return api
      .post('Contacts::autocomplete', { search_string: searchTerm })
      .then(({ data }) =>
        (_.get(data, 'result') || []).map((contact) => ({
          value: contact.id,
          label: contact.name,
          data: contact,
          model: contactsModel
        }))
      );
  }
};

export default contactsModel;
