import { Generator } from 'shared/utils/models';
import { api } from 'shared/utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      createItem: (type, args) => {
        return api
          .post(`${_.upperFirst(type)}::create`, {
            data: { ...args }
          })
          .then((response) => ({ ...response, data: response.data.result }));
      }
    }
  }
};

const actionCreators = {
  purgeItem: {
    request: ({ id, ...args }) =>
      api
        .post('AccountGroups::purge', { ...args, id: id })
        .then((response) => ({ ...response, data: response.data.result })),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const accountGroupsModel = new Generator(
  'accountGroups',
  config
).createEntityModel({
  actionCreators
});

accountGroupsModel.select = {
  autocomplete: () => {
    return api
      .fetchAll('AccountGroups::search', {
        limit: 100,
        extra_options: { fields: ['related.account_group_accounts'] }
      })
      .then((data) =>
        (_.get(data, 'rows') || []).map((group) => ({
          value: group.id,
          label: group.name,
          data: group,
          model: accountGroupsModel
        }))
      );
  }
};
export default accountGroupsModel;
