import React, { PureComponent } from 'react';
import Dialog from 'view/components/dialog';
import { autobind } from 'core-decorators';
import Box from '@rexlabs/box';
import ButtonBar from 'view/components/button-bar';
import { PrimaryButton, TextButton } from 'shared/components/button';
import { Body } from 'view/components/text';
import { PADDINGS } from 'src/theme';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

@withErrorDialog
@autobind
class RemovePrivilegeSetsDialog extends PureComponent {
  state = {
    isLoading: false
  };

  removePrivileges() {
    const { callback, closeDialog, errorDialog } = this.props;
    this.setState({ isLoading: true });

    Promise.all([callback()])
      .then(closeDialog)
      .catch((e) => {
        this.setState({ isLoading: false });
        errorDialog.open(e);
      });
  }

  render() {
    const { closeDialog } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        title={'Remove Privilege Set(s)'}
        height={200}
        closeDialog={closeDialog}
      >
        <Box mt={PADDINGS.XS} mb={PADDINGS.M}>
          <Body>
            Are you sure you want to remove the selected Privilege Set for this
            user?
          </Body>
        </Box>
        <ButtonBar width={'auto'} isLoading={isLoading}>
          <TextButton blue onClick={closeDialog}>
            Cancel
          </TextButton>
          <PrimaryButton red onClick={this.removePrivileges}>
            Remove
          </PrimaryButton>
        </ButtonBar>
      </Dialog>
    );
  }
}

export default RemovePrivilegeSetsDialog;
