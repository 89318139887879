import React, { PureComponent, Fragment } from 'react';
import { PADDINGS } from 'src/theme';
import FormField from 'view/components/form/field';
import { TextInput } from '@rexlabs/text-input';
import { Column } from 'shared/components/grid';
import RecordFieldGrid from 'view/components/record-field-grid';
import { toArray, getCustomFieldInput } from './utils';

export default class UserOverview extends PureComponent {
  tabName = 'overview';
  render() {
    const { activeTab, customGroups } = this.props;

    return activeTab === this.tabName ? (
      <Fragment>
        <RecordFieldGrid heading={'User Details'}>
          <Column width={6}>
            <FormField name='first_name' label='First name' Input={TextInput} />
          </Column>
          <Column width={6}>
            <FormField name='last_name' label='Last name' Input={TextInput} />
          </Column>
        </RecordFieldGrid>

        <RecordFieldGrid heading={'Contact'}>
          <Column width={6}>
            <FormField name='email' label='Email' Input={TextInput} />
          </Column>
        </RecordFieldGrid>

        {customGroups
          .filter((group) => toArray(group.fields).length > 0)
          .map((group) => (
            <RecordFieldGrid key={group.id} heading={group.label}>
              {toArray(group.fields).map((field) => (
                <Column p={PADDINGS.XS} key={field.id} width={6}>
                  <FormField
                    name={`custom-field-${field.id}`}
                    label={field.label}
                    {...getCustomFieldInput(field)}
                  />
                </Column>
              ))}
            </RecordFieldGrid>
          ))}
      </Fragment>
    ) : null;
  }
}
